import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface IConfirmModalProps {
  isShow: boolean;
  onHide: any;
  onConfirm: any;
}

const Confirmation = (props: IConfirmModalProps) => {
  const { t } = useTranslation();
  const { isShow, onConfirm, onHide } = props;

  return (
    <Modal show={isShow} onHide={onHide}>
      <Modal.Body>
        <p>{t('shared.areYouSure')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" onClick={onHide}>
          {t('shared.form.button.close')}
        </Button>
        <Button onClick={onConfirm} variant="primary">
          {t('shared.form.button.confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Confirmation;
