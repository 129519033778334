import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import type { RootState } from '../../app/store';
import type {
  IEventModel,
  IFetchEventsResponse,
} from '../../interfaces/interfaces';
import {
  fetchRequest,
  readByDeviceIdAndEventTypeIdRequest,
  updateRequest,
} from './eventsApi';

export const fetch = createAsyncThunk(
  'events/fetch',
  async (props: any, thunkAPI) => {
    try {
      const response = await fetchRequest(props);
      const data: IFetchEventsResponse = await response.data;
      return data.events;
    } catch {
      return thunkAPI.rejectWithValue('Sorry. something wrong');
    }
  },
);

export const fetchAndMerge = createAsyncThunk(
  'events/fetchAndMerge',
  async (props: any, thunkAPI) => {
    try {
      const response = await fetchRequest(props);
      const data: IFetchEventsResponse = await response.data;
      return data.events;
    } catch {
      return thunkAPI.rejectWithValue('Sorry. something wrong');
    }
  },
);

export const update = createAsyncThunk(
  'events/update',
  async (event: any, thunkAPI) => {
    try {
      const response = await updateRequest(event);
      const data: any = await response.data;
      return data.event;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errors);
    }
  },
);

export const readByDeviceIdAndEventTypeId = createAsyncThunk(
  'events/readByDeviceIdAndEventTypeId',
  async (params: any, thunkAPI) => {
    try {
      const response = await readByDeviceIdAndEventTypeIdRequest(params);
      const data: any = await response.data;
      return data.event;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errors);
    }
  },
);

// eslint-disable-next-line @typescript-eslint/unbound-method
const {
  addMany,
  getInitialState,
  getSelectors,
  setAll,
  updateOne,
  updateMany,
} = createEntityAdapter<IEventModel>();

export const { selectAll } = getSelectors<RootState>(
  (state: RootState) => state.events,
);

export const eventsSlice = createSlice({
  name: 'events',
  initialState: getInitialState({
    errors: [],
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetch.pending, (_: any) => {
        // setAll(state, []);
      })
      .addCase(fetch.fulfilled, (state: any, { payload }: any) => {
        setAll(state, payload);
      })
      .addCase(fetch.rejected, (_: any) => {
        // setAll(state, []);
      })
      .addCase(fetchAndMerge.pending, (_: any) => {
        // setAll(state, []);
      })
      .addCase(fetchAndMerge.fulfilled, (state: any, { payload }: any) => {
        addMany(state, payload);
      })
      .addCase(fetchAndMerge.rejected, (_: any) => {
        // setAll(state, []);
      })
      .addCase(update.pending, (_: any) => {
        // setAll(state, []);
      })
      .addCase(update.fulfilled, (state: any, { payload }: any) => {
        updateOne(state, { id: payload.id, changes: payload });
      })
      .addCase(update.rejected, (_: any) => {
        // setAll(state, []);
      })
      .addCase(readByDeviceIdAndEventTypeId.pending, (_: any) => {
        // setAll(state, []);
      })
      .addCase(readByDeviceIdAndEventTypeId.fulfilled, (state: any) => {
        const selectors = getSelectors((selectorsState: any) => selectorsState);
        const events = selectors.selectAll(state);
        updateMany(
          state,
          events.map((item: any) => ({
            id: item.id,
            changes: { ...item, read: true },
          })),
        );
      })
      .addCase(readByDeviceIdAndEventTypeId.rejected, (_: any) => {
        // setAll(state, []);
      });
  },
});

export const eventsSelector = (state: RootState) => state.events;

export default eventsSlice.reducer;
