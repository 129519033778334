import React, { useEffect, useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';

import { useAppDispatch } from '../../app/hooks';
import { isCan } from '../../helpers/permission';
import type { IEventTypeModel } from '../../interfaces/interfaces';
import type { IGeozoneModel } from '../../interfaces/models.interfaces';
import {
  destroy as destroyDevice,
  devicesSelector,
} from '../../slices/devices/devicesSlice';
import {
  fetch as fetchEventTypes,
  selectAll as selectAllEventTypes,
} from '../../slices/eventTypes/eventTypesSlice';
import {
  fetch as fetchGeozones,
  selectAll as selectAllGeozones,
} from '../../slices/geozones/geozonesSlice';
import { setText } from '../../slices/notification/notificationSlice';
import { settingsSelector } from '../../slices/settings/settingsSlice';
import { errorFor } from '../../utils/formError';
import Checkbox from '../checkbox/Checkbox';
import Confirmation from '../confirmation/Confirmation';
import Input from '../input/Input';
import Selectbox from '../selectbox/Selectbox';

interface IDeviceFormProps {
  initialValues: any;
  onSubmit: any;
  handleSubmit?: any;
  closeHandle: any;
  id: number | null;
}

let DeviceForm: any = (props: IDeviceFormProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    void dispatch(fetchGeozones());
    void dispatch(fetchEventTypes({}));
  }, []);
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

  const { initialValues, onSubmit, closeHandle, handleSubmit, id } = props;
  const { errors } = useSelector(devicesSelector);
  const geozones = useSelector(selectAllGeozones);
  const eventTypes = useSelector(selectAllEventTypes);
  const { currentUser } = useSelector(settingsSelector);
  const geozonesOptions = geozones.map((geozone: IGeozoneModel) => ({
    value: geozone.id,
    label: geozone.name,
  }));

  const EventsAndNotifications = () => (
    <>
      <h2 className="mt-5">{t('deviceForm.events')}</h2>
      {eventTypes &&
        eventTypes.length > 0 &&
        eventTypes.map((eventType: IEventTypeModel) => (
          <Form.Group key={eventType.id} as={Row}>
            <div className="col-sm-6">
              <Checkbox
                name={`eventConfig[types][${eventType.key}]`}
                label={eventType.name}
              />
            </div>
            {eventType.key === 'geozone_escape' && (
              <div className="col-sm-6">
                <Selectbox
                  isMulti
                  size="sm"
                  options={geozonesOptions}
                  name="deviceGeozones"
                />
              </div>
            )}
          </Form.Group>
        ))}

      <h2 className="mt-5">{t('deviceForm.notificationMethods')}</h2>

      <Form.Group as={Row} className="mb-4">
        <div className="col-sm-6 mb-2">
          <Checkbox
            name="eventConfig[notifications][system][active]"
            label={t('models.device.systemNotification')}
          />
        </div>
      </Form.Group>
      <Form.Group as={Row}>
        <div className="col-sm-6">
          <Checkbox
            name="eventConfig[notifications][email][active]"
            label={t('models.device.emailNotification')}
          />
        </div>
        <div className="col-sm-6">
          <Input
            component="input"
            name="eventConfig[notifications][email][address]"
            type="text"
            className="form-control-sm"
          />
        </div>
      </Form.Group>
      <Form.Group as={Row} className="mb-4">
        <div className="col-sm-6">
          <Checkbox
            name="eventConfig[notifications][sms][active]"
            label={t('models.device.smsNotification')}
          />
        </div>
        <div className="col-sm-6">
          <Input
            component="input"
            name="eventConfig[notifications][sms][phone]"
            type="text"
            className="form-control-sm"
          />
        </div>
      </Form.Group>
    </>
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Row}>
          <label htmlFor="number" className="col-sm-4 col-form-label">
            {t('models.device.number')}:
          </label>
          <div className="col-sm-8">
            <Input
              component="input"
              name="number"
              type="text"
              readOnly={!!id}
              className="form-control-sm"
              id="number"
              error={errorFor(errors, 'device.number')}
            />
          </div>
        </Form.Group>
        <Form.Group as={Row}>
          <label htmlFor="name" className="col-sm-4 col-form-label">
            {t('models.device.name')}:
          </label>
          <div className="col-sm-8">
            <Input
              component="input"
              name="name"
              type="text"
              className="form-control-sm"
              id="name"
              error={errorFor(errors, 'device.name')}
            />
          </div>
        </Form.Group>
        <Form.Group as={Row}>
          <label htmlFor="port" className="col-sm-4 col-form-label">
            {t('models.device.port')}:
          </label>
          <div className="col-sm-8">
            <Input
              component="input"
              name="port"
              type="number"
              className="form-control-sm"
              id="port"
              error={errorFor(errors, 'device.port')}
            />
          </div>
        </Form.Group>
        <Form.Group as={Row}>
          <label htmlFor="version" className="col-sm-4 col-form-label">
            {t('models.device.version')}:
          </label>
          <div className="col-sm-8">
            <Input
              component="input"
              name="version"
              type="number"
              className="form-control-sm"
              id="version"
              error={errorFor(errors, 'device.version')}
            />
          </div>
        </Form.Group>
        <Checkbox
          name="active"
          label={t('models.device.active')}
          className="mb-4"
        />

        {initialValues.type !== 'card' && <EventsAndNotifications />}

        <Button size="sm" variant="success" type="submit" className="me-3">
          {t('shared.form.button.save')}
        </Button>
        <Button size="sm" variant="warning" onClick={closeHandle}>
          {t('shared.form.button.close')}
        </Button>
        {initialValues.type !== 'card' &&
          initialValues.id &&
          isCan('destroy', 'Device', currentUser) && (
            <a
              className="text-danger ms-4"
              href="#"
              onClick={() => setIsShowConfirmModal(true)}
            >
              {t('shared.remove')}
            </a>
          )}
      </form>
      {!!id && (
        <Confirmation
          isShow={isShowConfirmModal}
          onConfirm={async () => {
            const result: any = await dispatch(destroyDevice(id));
            if (!result.errors) {
              dispatch(setText(t('shared.notification.removed')));
            }
            setIsShowConfirmModal(false);
            closeHandle();
          }}
          onHide={() => {
            setIsShowConfirmModal(false);
          }}
        />
      )}
    </>
  );
};

DeviceForm = reduxForm<Record<string, boolean>, IDeviceFormProps>({
  form: 'device',
  enableReinitialize: true,
})(DeviceForm);

const selector = formValueSelector('device');
DeviceForm = connect((state: any) => {
  const id = selector(state, 'id');
  return { id };
})(DeviceForm);

export default DeviceForm;
