import {
  faArrowLeft,
  faArrowRight,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
// import { get } from 'lodash';
import React, { useCallback } from 'react';
import { Button, Dropdown, Table } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../app/hooks';
import {
  // fetch,
  fetchAsFile,
  selectAllDataPoints,
  submittedFormSelector,
} from '../../slices/messages/messagesSlice';
import { withSecondsTimeFormat } from '../../utils/date';
import { coordsAsString } from '../../utils/messages';
import MessagesTrigger from '../messages_trigger/MessagesTrigger';

interface IMessagesMapProps {
  active: string;
  className?: string;
  handleChangeType: any;
  page: number;
  setPage: any;
  position: any;
  dataPointSize: number;
  selectedDataPointId: number | null;
}

const MessagesTable = (props: IMessagesMapProps) => {
  const {
    active,
    handleChangeType,
    page,
    setPage,
    position,
    selectedDataPointId,
    dataPointSize,
  } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // selectors
  // const messagesCount = useSelector(countSelector);
  const messagesForm: any = useSelector(submittedFormSelector);
  const messages = useSelector(selectAllDataPoints);
  const pageMessages = messages.slice((page - 1) * 30, (page - 1) * 30 + 30);

  const setPageAndFetch = useCallback(
    (newPage: number) => {
      setPage(newPage);
    },
    [messagesForm],
  );

  return (
    <div className="messages-bottom-table">
      <div className="messages-tables">
        <Scrollbars autoHide>
          <div className="messages-tables-wrapper">
            <Table
              size="sm"
              className="message-table message-table-header mb-0"
            >
              <thead>
                <tr>
                  <th>
                    <div className="message-createdAt">
                      {t('shared.models.createdAt')}
                    </div>
                  </th>
                  <th>
                    <div className="message-timestamp">
                      {t('messagesTable.deviceDataTimestamp')}
                    </div>
                  </th>
                  <th>
                    <div className="message-position">
                      {t('messagesTable.position')}
                    </div>
                  </th>
                  <th>
                    <div className="message-battery">
                      {t('messagesTable.battery')}
                    </div>
                  </th>
                  <th>
                    <div className="message-temperature">
                      {t('messagesTable.temperature')}
                    </div>
                  </th>
                  <th>
                    <div className="message-hwVersion">
                      {t('messagesTable.hwVersion')}
                    </div>
                  </th>
                  <th>
                    <div className="message-fwVersion">
                      {t('messagesTable.fwVersion')}
                    </div>
                  </th>
                  <th>
                    <div className="message-packageId">
                      {t('messagesTable.packageId')}
                    </div>
                  </th>
                  <th>
                    <div className="message-freq">
                      {t('messagesTable.freq')}
                    </div>
                  </th>
                  <th>
                    <div className="message-rfch">
                      {t('messagesTable.rfch')}
                    </div>
                  </th>
                  <th>
                    <div className="message-datr">
                      {t('messagesTable.datr')}
                    </div>
                  </th>
                  <th>
                    <div className="message-codr">
                      {t('messagesTable.codr')}
                    </div>
                  </th>
                  <th>
                    <div className="message-rssi">
                      {t('messagesTable.rssi')}
                    </div>
                  </th>
                  <th>
                    <div className="message-snr">{t('messagesTable.snr')}</div>
                  </th>
                  <th>
                    <div className="message-mac">{t('messagesTable.mac')}</div>
                  </th>
                  <th>
                    <div className="message-sc">{t('messagesTable.sc')}</div>
                  </th>
                  <th>
                    <div className="message-snrMin">
                      {t('messagesTable.snr_min')}
                    </div>
                  </th>
                  <th>
                    <div className="message-snrMax">
                      {t('messagesTable.snr_max')}
                    </div>
                  </th>
                  <th>
                    <div className="message-activityLow">
                      {t('messagesTable.activity_low')}
                    </div>
                  </th>
                  <th>
                    <div className="message-activityHigh">
                      {t('messagesTable.activity_high')}
                    </div>
                  </th>
                  <th>
                    <div className="message-activityMax">
                      {t('messagesTable.activity_max')}
                    </div>
                  </th>
                  <th>
                    <div className="message-activityAvg">
                      {t('messagesTable.activity_avg')}
                    </div>
                  </th>
                </tr>
              </thead>
            </Table>
            <div className="messages-table-body">
              <Scrollbars autoHide>
                <Table className="message-table" size="sm">
                  <tbody>
                    {!!pageMessages &&
                      pageMessages.length > 0 &&
                      pageMessages.map((item: any, index: number) => {
                        const classNames = cn(
                          `messages-table-tr messages-table-tr-${item.id}`,
                          { active: item.id === selectedDataPointId },
                        );

                        return (
                          <tr key={index} className={classNames}>
                            <td>
                              <div className="message-createdAt">
                                {withSecondsTimeFormat(item.createdAt)}
                              </div>
                            </td>
                            <td>
                              <div className="message-timestamp">
                                {!!item.tmsp &&
                                  withSecondsTimeFormat(item.tmsp)}
                              </div>
                            </td>
                            <td>
                              <div className="message-position">
                                {coordsAsString(item)}
                              </div>
                            </td>
                            <td>
                              <div className="message-battery">{item.bat}</div>
                            </td>
                            <td>
                              <div className="message-temperature">
                                {item.tmp}
                              </div>
                            </td>
                            <td>
                              <div className="message-hwVersion">
                                {item.hwv}
                              </div>
                            </td>
                            <td>
                              <div className="message-fwVersion">
                                {item.fwv}
                              </div>
                            </td>
                            <td>
                              <div className="message-packageId">
                                {item.pid}
                              </div>
                            </td>
                            <td>
                              <div className="message-freq">{item.frq}</div>
                            </td>
                            <td>
                              <div className="message-rfch">{item.rfch}</div>
                            </td>
                            <td>
                              <div className="message-datr">{item.dtr}</div>
                            </td>
                            <td>
                              <div className="message-codr">{item.cdr}</div>
                            </td>
                            <td>
                              <div className="message-rssi">{item.rssi}</div>
                            </td>
                            <td>
                              <div className="message-snr">{item.snr}</div>
                            </td>
                            <td>
                              <div className="message-mac">{item.mac}</div>
                            </td>
                            <td>
                              <div className="message-sc">{item.sc}</div>
                            </td>
                            <td>
                              <div className="message-snrMin">{item.snrmn}</div>
                            </td>
                            <td>
                              <div className="message-snrMax">{item.snrmx}</div>
                            </td>
                            <td>
                              <div className="message-activityLow">
                                {item.acl}
                              </div>
                            </td>
                            <td>
                              <div className="message-activityHigh">
                                {item.ach}
                              </div>
                            </td>
                            <td>
                              <div className="message-activityMax">
                                {item.acm}
                              </div>
                            </td>
                            <td>
                              <div className="message-activityAvg">
                                {item.aca}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    {(!pageMessages || pageMessages.length === 0) && (
                      <tr>
                        <td colSpan={21}>{t('shared.empty')}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Scrollbars>
            </div>
          </div>
        </Scrollbars>
      </div>
      <div className="messages-tools row">
        <div className="col-3 text-start">
          {dataPointSize > 0 && (
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                className="btn-icon btn-messages"
                id="dropdown-basic"
              >
                <FontAwesomeIcon className="d-inline-block" icon={faUpload} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => dispatch(fetchAsFile(messagesForm))}
                >
                  CSV
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
        <div className="col-6 text-center">
          <span className="me-2">Страница: {page}</span>
          <Button
            className="btn-icon"
            variant="success"
            onClick={() => setPageAndFetch(page - 1 || 1)}
            disabled={page <= 1}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <Button
            variant="success"
            onClick={() => setPageAndFetch(page + 1)}
            className="btn-icon ms-2"
            disabled={pageMessages.length < 30}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
          <span className="ms-2">Всего записей: {messages.length}</span>
        </div>
        <div className="col-3 text-end">
          <MessagesTrigger
            active={active}
            handleChangeType={handleChangeType}
            position={position}
          />
        </div>
      </div>
    </div>
  );
};

export default MessagesTable;
