import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import type { IPrivateRouteProps } from '../../interfaces/interfaces';
import { settingsSelector } from '../../slices/settings/settingsSlice';

export const PrivateRoute: React.FC<IPrivateRouteProps> = ({
  path: path,
  component: Component,
  layout: Layout,
}) => {
  const { token } = useSelector(settingsSelector);

  return (
    <Route
      path={path}
      exact={true}
      render={(props: any) =>
        token ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
