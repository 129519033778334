import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import type { RootState } from '../../app/store';
import type {
  IFetchGeozonesResponse,
  IGeozoneModel,
} from '../../interfaces/interfaces';
import { formattedErrors } from '../../utils/formError';
import {
  createRequest,
  destroyAllRequest,
  destroyRequest,
  fetchRequest,
  updateRequest,
} from './geozonesApi';

export const fetch = createAsyncThunk('geozones/fetch', async (_, thunkAPI) => {
  try {
    const response = await fetchRequest();
    const data: IFetchGeozonesResponse = await response.data;
    return data.geozones;
  } catch {
    return thunkAPI.rejectWithValue('Sorry. something wrong');
  }
});

export const create = createAsyncThunk(
  'geozones/create',
  async (formData: any, thunkAPI) => {
    try {
      const response = await createRequest(formData);
      const data: any = await response.data;
      return data.geozone;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errors);
    }
  },
);

export const update = createAsyncThunk(
  'geozones/update',
  async (formData: any, thunkAPI) => {
    try {
      const response = await updateRequest(formData);
      const data: any = await response.data;
      return data.geozone;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errors);
    }
  },
);

export const destroy = createAsyncThunk(
  'geozones/destroy',
  async (geozone: IGeozoneModel, thunkAPI) => {
    try {
      await destroyRequest(geozone.id);
      return geozone;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errors);
    }
  },
);

export const destroyAll = createAsyncThunk(
  'geozones/destroyAll',
  async (_, thunkAPI) => {
    try {
      await destroyAllRequest();
      return [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errors);
    }
  },
);

// eslint-disable-next-line @typescript-eslint/unbound-method
const {
  getInitialState,
  getSelectors,
  setAll,
  updateOne,
  addOne,
  removeOne,
  removeAll,
} = createEntityAdapter<IGeozoneModel>();

export const geozonesSlice = createSlice({
  name: 'geozones',
  initialState: getInitialState({
    errors: [],
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetch.pending, (_: any) => {
        // setAll(state, []);
      })
      .addCase(fetch.fulfilled, (state: any, { payload }: any) => {
        setAll(state, payload);
      })
      .addCase(fetch.rejected, (_: any) => {
        // setAll(state, []);
      })
      .addCase(create.pending, (state: any) => {
        state.errors = [];
      })
      .addCase(create.fulfilled, (state: any, { payload }: any) => {
        addOne(state, payload);
        state.errors = [];
      })
      .addCase(create.rejected, (state: any, { payload }) => {
        state.errors = formattedErrors(payload);
      })
      .addCase(update.pending, (state: any) => {
        state.errors = [];
      })
      .addCase(update.fulfilled, (state: any, { payload }: any) => {
        updateOne(state, { id: payload.id, changes: payload });
        state.errors = [];
      })
      .addCase(update.rejected, (state: any, { payload }) => {
        state.errors = formattedErrors(payload);
      })
      .addCase(destroyAll.fulfilled, (state: any) => {
        removeAll(state);
      })
      .addCase(destroy.fulfilled, (state: any, { payload }: any) => {
        removeOne(state, payload.id);
      });
  },
});

export const geozonesSelector = (state: RootState) => state.geozones;
export const { selectAll } = getSelectors<RootState>(
  (state: RootState) => state.geozones,
);

export default geozonesSlice.reducer;
