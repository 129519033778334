import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import type { RootState } from '../../app/store';
import type {
  IEventTypeModel,
  IFetchEventTypesResponse,
} from '../../interfaces/interfaces';
import { fetchRequest } from './eventTypesApi';

export const fetch = createAsyncThunk(
  'eventTypes/fetch',
  async (props: any, thunkAPI) => {
    try {
      const response = await fetchRequest(props);
      const data: IFetchEventTypesResponse = await response.data;
      return data.eventTypes;
    } catch {
      return thunkAPI.rejectWithValue('Sorry. something wrong');
    }
  },
);

// eslint-disable-next-line @typescript-eslint/unbound-method
const { getInitialState, getSelectors, setAll, updateOne } =
  createEntityAdapter<IEventTypeModel>();

export const eventTypesSlice = createSlice({
  name: 'eventTypes',
  initialState: getInitialState({
    errors: [],
  }),
  reducers: {
    readAll: (state: any, { payload }) => {
      updateOne(state, { id: payload.id, changes: { unreadEventsCount: 0 } });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetch.pending, (_: any) => {
        // setAll(state, []);
      })
      .addCase(fetch.fulfilled, (state: any, { payload }: any) => {
        setAll(state, payload);
      })
      .addCase(fetch.rejected, (_: any) => {
        // setAll(state, []);
      });
  },
});

export const { readAll } = eventTypesSlice.actions;
export const eventTypesSelector = (state: RootState) => state.eventTypes;
export const { selectAll, selectById } = getSelectors<RootState>(
  (state: RootState) => state.eventTypes,
);

export default eventTypesSlice.reducer;
