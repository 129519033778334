import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import type { RootState } from '../../app/store';
import type {
  IFetchUsersResponse,
  IUserModel,
} from '../../interfaces/interfaces';
import { formattedErrors } from '../../utils/formError';
import {
  createRequest,
  destroyRequest,
  fetchRequest,
  updateRequest,
} from './usersApi';

export const fetch = createAsyncThunk('users/fetch', async (_, thunkAPI) => {
  try {
    const response = await fetchRequest();
    const data: IFetchUsersResponse = await response.data;
    return data.users;
  } catch {
    return thunkAPI.rejectWithValue('Sorry. something wrong');
  }
});

export const create = createAsyncThunk(
  'users/create',
  async (formData: any, thunkAPI) => {
    try {
      const response = await createRequest(formData);
      const data: any = await response.data;
      return data.user;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errors);
    }
  },
);

export const update = createAsyncThunk(
  'users/update',
  async (formData: any, thunkAPI) => {
    if (
      (formData.password || formData.confirmationPassword) &&
      formData.password !== formData.confirmationPassword
    ) {
      return thunkAPI.rejectWithValue([
        { field: 'user.password', message: 'Неверный пароль' },
      ]);
    }

    try {
      const response = await updateRequest(formData);
      const data: any = await response.data;
      return data.user;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errors);
    }
  },
);

export const destroy = createAsyncThunk(
  'users/destroy',
  async (id: number, thunkAPI) => {
    try {
      await destroyRequest(id);
      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errors);
    }
  },
);

// eslint-disable-next-line @typescript-eslint/unbound-method
const { getInitialState, getSelectors, setAll, updateOne, addOne, removeOne } =
  createEntityAdapter<IUserModel>();

export const usersSlice = createSlice({
  name: 'users',
  initialState: getInitialState({
    errors: [],
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetch.fulfilled, (state: any, { payload }: any) => {
        setAll(state, payload);
      })
      .addCase(update.pending, (state: any) => {
        state.errors = [];
      })
      .addCase(update.fulfilled, (state: any, { payload }: any) => {
        updateOne(state, { id: payload.id, changes: payload });
        state.errors = [];
      })
      .addCase(update.rejected, (state: any, { payload }) => {
        state.errors = formattedErrors(payload);
      })
      .addCase(create.pending, (state: any) => {
        state.errors = [];
      })
      .addCase(create.fulfilled, (state: any, { payload }: any) => {
        addOne(state, payload);
        state.errors = [];
      })
      .addCase(create.rejected, (state: any, { payload }) => {
        state.errors = formattedErrors(payload);
      })
      .addCase(destroy.fulfilled, (state: any, { payload }: any) => {
        removeOne(state, payload);
      });
  },
});

export default usersSlice.reducer;
export const usersSelector = (state: RootState) => state.users;
export const { selectAll } = getSelectors<RootState>(
  (state: RootState) => state.users,
);
