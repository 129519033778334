import React, { useCallback } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reduxForm } from 'redux-form';

import { useAppDispatch } from '../../../app/hooks';
import logo from '../../../assets/images/logo.png';
import type { IRegistrationForm } from '../../../interfaces/interfaces';
import {
  create,
  currentUserSelector,
  fetch,
} from '../../../slices/currentUser/currentUserSlice';
import { errorFor } from '../../../utils/formError';
import Input from '../../input/Input';
import styles from './Registration.module.scss';

const Registration = (props: any) => {
  const { t } = useTranslation();
  const { handleSubmit } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { errors } = useSelector(currentUserSelector);

  const onSubmit = useCallback(async (formData: any) => {
    try {
      const loginData: any = await dispatch(create(formData));
      if (!loginData.error) {
        const currentUserData: any = await dispatch(fetch());

        if (!currentUserData.error) {
          history.push('/');
        }
      }
    } catch {
      // nothing todo yet
    }
  }, []);

  return (
    <div className="d-flex justify-content-center">
      <div className={styles.registration}>
        <img className={styles.logo} src={logo} />
        <h1 className={styles.title}>{t('auth.signUp.signUp')}</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            className="form-control-lg"
            component="input"
            placeholder={t('auth.signUp.name')}
            type="text"
            name="name"
            error={errorFor(errors, 'name')}
          />
          <Input
            className="form-control-lg"
            component="input"
            placeholder={t('auth.signUp.email')}
            type="email"
            name="email"
            error={errorFor(errors, 'email')}
          />
          <Input
            className="form-control-lg"
            component="input"
            placeholder={t('auth.signUp.accountName')}
            type="text"
            name="accountName"
            error={errorFor(errors, 'accountName')}
          />
          <Input
            className="form-control-lg"
            component="input"
            placeholder={t('auth.signUp.password')}
            type="password"
            name="password"
            error={errorFor(errors, 'password')}
          />
          <Input
            className="form-control-lg"
            component="input"
            placeholder={t('auth.signUp.confirmationPassword')}
            type="password"
            name="confirmationPassword"
          />
          <Row className="mt-5">
            <Col>
              <a href="/login" className="text-left">
                {t('auth.signUp.signIn')}
              </a>
            </Col>
            <Col className="text-end">
              <Button type="submit" size="lg" variant="success">
                {t('auth.signUp.signUp')}
              </Button>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
};

export default reduxForm<IRegistrationForm>({
  form: 'registration',
  enableReinitialize: true,
})(Registration);
