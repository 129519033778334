import type { AxiosResponse } from 'axios';

import { destroy, get, patch, post } from '../../helpers/axios';

export async function fetchRequest(): Promise<AxiosResponse<any>> {
  return get('baseStations');
}

export async function createRequest(
  formData: any,
): Promise<AxiosResponse<any>> {
  return post('baseStations', { baseStation: formData });
}

export async function updateRequest(
  formData: any,
): Promise<AxiosResponse<any>> {
  let updatedFormData = formData;
  if (updatedFormData.type) {
    updatedFormData = { ...formData, type: formData.type.value };
  }

  return patch(`baseStations/${formData.id}`, { baseStation: updatedFormData });
}

export async function destroyRequest(id: number): Promise<AxiosResponse<any>> {
  return destroy(`baseStations/${id}`);
}
