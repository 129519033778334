import type { AxiosResponse } from 'axios';

import { destroy, get, patch, post } from '../../helpers/axios';

export async function fetchRequest(): Promise<AxiosResponse<any>> {
  return get('geozones');
}

export async function createRequest(
  formData: any,
): Promise<AxiosResponse<any>> {
  let createdFormData = formData;
  if (
    createdFormData.deviceGeozones &&
    createdFormData.deviceGeozones.length > 0
  ) {
    createdFormData = {
      ...formData,
      deviceGeozones: formData.deviceGeozones.map((item: any) => item.value),
    };
  }

  return post('geozones', {
    geozone: createdFormData,
  });
}

export async function updateRequest(
  formData: any,
): Promise<AxiosResponse<any>> {
  let updatedFormData = formData;
  if (
    updatedFormData.deviceGeozones &&
    updatedFormData.deviceGeozones.length > 0
  ) {
    updatedFormData = {
      ...formData,
      deviceGeozones: formData.deviceGeozones.map((item: any) => item.value),
    };
  }

  return patch(`geozones/${formData.id}`, {
    geozone: updatedFormData,
  });
}

export async function destroyRequest(id: number): Promise<AxiosResponse<any>> {
  return destroy(`geozones/${id}`);
}

export async function destroyAllRequest(): Promise<AxiosResponse<any>> {
  return destroy('geozones');
}
