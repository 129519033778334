// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';

import styles from './BaseStation.module.scss';
import BaseStationForm from './BaseStationForm';

interface IDeviceProps {
  openedModel?: Record<string, any> | null;
  closeHandle: any;
  onSubmit: any;
}

const BaseStation = ({ openedModel, onSubmit, closeHandle }: IDeviceProps) => {
  const { t } = useTranslation();
  let initialValues: any = {
    type: 'standart',
  };

  if (openedModel) {
    initialValues = {
      id: openedModel.id,
      accountId: openedModel.accountId,
      name: openedModel.name,
      number: openedModel.number,
      type: openedModel.type,
      lat: openedModel.lat,
      lon: openedModel.lon,
    };
  }

  const title = t(
    `baseStation.${openedModel && openedModel.id ? 'editTitle' : 'newTitle'}`,
  );

  return (
    <div className={styles.device}>
      <Scrollbars autoHide>
        <h1>{title}</h1>
        <BaseStationForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          closeHandle={closeHandle}
        />
      </Scrollbars>
    </div>
  );
};

export default BaseStation;
