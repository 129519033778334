import {
  faSortAmountUpAlt,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames/bind';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../app/hooks';
import styles from './DevicesSort.module.scss';

interface IDevicesSort {
  sortMethodsList: string[];
  sortMethod: 'abc' | 'status';
  sortDirection: 'up' | 'down';
  setSortMethod: any;
  setSortDirection: any;
}

const useSort = (props: any) => {
  props.setSortMethod(props.newSortMethod);
  props.setSortDirection(
    props.newSortMethod === props.currentSortMethod
      ? props.sortDirection
      : 'up',
  );
};

const DevicesSort = (props: IDevicesSort) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    sortMethodsList,
    sortMethod,
    sortDirection,
    setSortMethod,
    setSortDirection,
  } = props;
  const cx = classNames.bind(styles);

  return (
    <div className={styles.sort}>
      {sortMethodsList.includes('abc') && (
        <>
          <FontAwesomeIcon icon={faSortAmountUpAlt} className="me-3" />
          <span
            className={cx('me-3', { active: sortMethod === 'abc' })}
            onClick={useSort.bind(undefined, {
              currentSortMethod: sortMethod,
              newSortMethod: 'abc',
              setSortMethod,
              sortDirection: sortDirection === 'up' ? 'down' : 'up',
              setSortDirection,
              dispatch,
            })}
          >
            {t('devicesSort.abc')}
            {sortMethod === 'abc' && (
              <FontAwesomeIcon
                className="ms-1"
                icon={sortDirection === 'up' ? faSortUp : faSortDown}
              />
            )}
          </span>
        </>
      )}
      {sortMethodsList.includes('status') && (
        <>
          <span
            className={cx('me-3', { active: sortMethod === 'status' })}
            onClick={useSort.bind(undefined, {
              currentSortMethod: sortMethod,
              newSortMethod: 'status',
              setSortMethod,
              sortDirection: sortDirection === 'up' ? 'down' : 'up',
              setSortDirection,
              dispatch,
            })}
          >
            {t('devicesSort.status')}
            {sortMethod === 'status' && (
              <FontAwesomeIcon
                className="ms-1"
                icon={sortDirection === 'up' ? faSortUp : faSortDown}
              />
            )}
          </span>
        </>
      )}
    </div>
  );
};

export default DevicesSort;
