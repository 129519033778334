import './App.scss';

// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import ForgetPassword from './components/auth/forget_password/ForgetPassword';
import Login from './components/auth/login/Login';
import Registrations from './components/auth/registration/Registration';
import AuthRoute from './components/auth_route/AuthRoute';
import Employees from './components/employees/Employees';
import Events from './components/events/Events';
import Geozones from './components/geozones/Geozones';
import Messages from './components/messages/Messages';
import Monitoring from './components/monitoring/Monitoring';
import PrivateRoute from './components/private_route/PrivateRoute';
import SettingsGeneral from './components/settings_general/SettingsGeneral';
import SettingsUsers from './components/settings_users/SettingsUsers';
import Tracks from './components/tracks/Tracks';
import MapLayout from './layouts/map_layout/MapLayout';
import SimplePageLayout from './layouts/simple_page_layout/SimplePageLayout';

const App = () => (
  <BrowserRouter>
    <Switch>
      <AuthRoute path="/login" component={Login} />
      <AuthRoute path="/registration" component={Registrations} />
      <AuthRoute path="/reset_password" component={ForgetPassword} />
      <PrivateRoute path="/tracks" component={Tracks} layout={MapLayout} />
      <PrivateRoute path="/geozones" component={Geozones} layout={MapLayout} />
      <PrivateRoute path="/messages" component={Messages} layout={MapLayout} />
      <PrivateRoute
        path="/events"
        component={Events}
        layout={SimplePageLayout}
      />
      <PrivateRoute
        path="/employees"
        component={Employees}
        layout={SimplePageLayout}
      />
      <PrivateRoute
        path="/settings/general"
        component={SettingsGeneral}
        layout={SimplePageLayout}
      />
      <PrivateRoute
        path="/settings/users"
        component={SettingsUsers}
        layout={SimplePageLayout}
      />
      <PrivateRoute path="/" component={Monitoring} layout={MapLayout} />
    </Switch>
  </BrowserRouter>
);

export default App;
