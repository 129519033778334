import cn from 'classnames';
// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';
import { Field } from 'redux-form';

import styles from './Input.module.scss';

interface IInputProps {
  id?: string;
  label?: string;
  className?: string;
  component: string;
  type: string;
  readOnly?: boolean;
  onChange?: (a: any) => void;
  placeholder?: string;
  name?: string;
  error?: string;
  required?: boolean;
}

const Input = ({ label, error, className, ...rest }: IInputProps) => {
  const classNames = cn(styles.input, className, 'form-control', {
    'is-invalid': error,
  });
  return (
    <div className={styles.inputWrapper}>
      {!!label && <label>{label}</label>}
      <Field className={classNames} {...rest} />
      {!!error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default Input;
