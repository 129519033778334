import 'leaflet-arrowheads';

import React, { useEffect } from 'react';
import { Polyline } from 'react-leaflet';

interface IArrowHeadsPolylineProps {
  ref: any;
  color: string;
  weight: any;
  arrowheads: any;
  positions: any;
}

// eslint-disable-next-line react/display-name
const ArrowHeadsPolyline = React.forwardRef(
  (props: IArrowHeadsPolylineProps, ref: any) => {
    const { arrowheads } = props;
    ref = React.createRef();

    useEffect(() => {
      const polyline = ref.current;
      if (polyline && arrowheads) {
        polyline.arrowheads(arrowheads);
        polyline._update();
      }
    }, []);

    return <Polyline {...props} ref={ref} />;
  },
);

export default ArrowHeadsPolyline;
