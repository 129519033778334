import { faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../app/hooks';
import type {
  IDeviceDataModel,
  IDeviceModel,
} from '../../interfaces/models.interfaces';
import { setText } from '../../slices/notification/notificationSlice';
import { defaultTimeFormat } from '../../utils/date';
import Confirmation from '../confirmation/Confirmation';

interface IEmployeesListItemProps {
  employee: IDeviceModel;
  onEdit: any;
  onDestroy: any;
  canUpdate: boolean;
  canDestroy: boolean;
}

const EmployeesListItem = (props: IEmployeesListItemProps) => {
  const { employee, onEdit, onDestroy, canUpdate, canDestroy } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

  return (
    <tr>
      <td>{employee.number}</td>
      <td>{employee.name}</td>
      <td>
        {!!employee.deviceData &&
          employee.deviceData.map((deviceDataItem: IDeviceDataModel) => (
            <div key={deviceDataItem.id}>
              {defaultTimeFormat(deviceDataItem.createdAt)}
            </div>
          ))}
      </td>
      <td className="text-end text-nowrap">
        {canUpdate && (
          <Button variant="primary" onClick={onEdit.bind(undefined, employee)}>
            <FontAwesomeIcon icon={faPen} />
          </Button>
        )}
        {canDestroy && (
          <Button
            variant="danger"
            className="ms-2"
            onClick={() => setIsShowConfirmModal(true)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        )}
        <Confirmation
          isShow={isShowConfirmModal}
          onConfirm={async () => {
            await onDestroy(employee);
            dispatch(setText(t('shared.notification.removed')));
            setIsShowConfirmModal(false);
          }}
          onHide={() => {
            setIsShowConfirmModal(false);
          }}
        />
      </td>
    </tr>
  );
};

export default EmployeesListItem;
