import moment from 'moment';

function getTime(date: Date | null, format: string) {
  if (!date) {
    return null;
  }
  return moment(date).format(format);
}

export function defaultTimeFormat(date: Date | null): string | null {
  return getTime(date, 'DD.MM.YYYY HH:mm');
}

export function withSecondsTimeFormat(date: Date | null): string | null {
  return getTime(date, 'DD.MM.YYYY HH:mm:ss');
}

export function timestamp(date: Date) {
  return moment(date).unix();
}
