import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../app/store';

export interface INotificationState {
  text: string | null;
}

const initialState: INotificationState = {
  text: null,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setText: (state, action: PayloadAction<string>) => {
      state.text = action.payload;
    },
    clearText: (state) => {
      state.text = null;
    },
  },
});

export const { setText, clearText } = notificationSlice.actions;
export const selectText = (state: RootState) => state.notification.text;
export default notificationSlice.reducer;
