import cn from 'classnames';
// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';
import { Field } from 'redux-form';

import styles from './Checkbox.module.scss';

interface ICheckboxProps {
  id?: string | undefined;
  checked?: boolean | undefined;
  label?: string | undefined;
  onChange?: (a: any) => void;
  name?: string;
  className?: string;
}

const Checkbox = ({ id, label, className, ...rest }: ICheckboxProps) => {
  const classNames = cn(styles.checkbox, className);
  return (
    <label className={classNames}>
      {!!label && <div className={styles.label}>{label}</div>}
      <Field component="input" type="checkbox" {...rest} />
      <div className={styles.checkboxMark} />
    </label>
  );
};

export default Checkbox;
