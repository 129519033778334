import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const SelectInput = ({
  input,
  options,
  name,
  id,
  className,
  isMulti,
  isClearable,
}: any) => {
  const { t } = useTranslation();
  let selectedValueObject = null;

  if (input.value) {
    if (isMulti) {
      selectedValueObject = options.filter((item: any) =>
        input.value.map((i: any) => i.value).includes(item.value),
      );
    } else {
      selectedValueObject = input.value;

      if (!selectedValueObject.value) {
        selectedValueObject = options.find(
          (item: any) => item.value === input.value,
        );
      }
    }
  }

  return (
    <Select
      {...input}
      isClearable={isClearable}
      isMulti={isMulti}
      className={className}
      id={id}
      name={name}
      options={options}
      value={selectedValueObject}
      // defaultValue={defaultValue}
      placeholder={t('selectInput.placeholder')}
      onChange={(valueObject: any) => {
        input.onChange(valueObject);
      }}
      onBlur={() => input.onBlur()}
    />
  );
};

export default SelectInput;
