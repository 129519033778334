import type { NumberSize } from 're-resizable';
import type { Direction } from 're-resizable/lib/resizer';

import { saveLeftColumnSize } from './localStorage';

export const onResizeStop = (
  leftColumnSize: Record<string, number>,
  map: any,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
  e: Event,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
  direction: Direction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
  el: HTMLElement,
  size: NumberSize,
) => {
  saveLeftColumnSize({
    width: size.width + leftColumnSize.width,
    height: '100%',
  });

  // refresh map size
  if (map) {
    map.invalidateSize();
  }
};
