import {
  faChevronDown,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import type { IDeviceModel } from '../../interfaces/interfaces';
import { withSecondsTimeFormat } from '../../utils/date';
import { coordsAsString, getGeozones } from '../../utils/device';
import DeviceGeotag from '../device_geotag/DeviceGeotag';
import DeviceOnline from '../device_online/DeviceOnline';
import PropertyItem from '../property_item/PropertyItem';
import Radiobox from '../radiobox/Radiobox';

interface IEventsDevicesItemProps {
  device: IDeviceModel;
  isChecked: boolean;
}

const EventsDevicesItem = (props: IEventsDevicesItemProps) => {
  const { t } = useTranslation();
  const { device, isChecked } = props;

  return (
    <div className="list-item">
      <div className="list-item-header">
        <Radiobox
          name="id"
          value={device.id}
          checked={isChecked}
          label={device.name}
          className="me-2"
        />
        <DeviceOnline className="me-2" deviceData={device.deviceData[0]} />
        <DeviceGeotag className="me-2" deviceData={device.deviceData[0]} />
        {device.events.length > 0 && (
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="text-warning me-2"
          />
        )}
        <Accordion.Toggle
          className="btn-icon btn-tool ms-auto"
          as={Button}
          variant="primary"
          eventKey={device.id.toString()}
        >
          <FontAwesomeIcon icon={faChevronDown} />
        </Accordion.Toggle>
      </div>
      <Accordion.Collapse
        className="list-item-body"
        eventKey={device.id.toString()}
      >
        <>
          <PropertyItem
            title={`${t('models.device.number')}:`}
            value={device.number}
          />
          <PropertyItem
            title={`${t('models.deviceData.coordinates')}:`}
            value={coordsAsString(device.deviceData[0])}
          />
          <PropertyItem
            title={`${t('shared.models.createdAt')}:`}
            value={withSecondsTimeFormat(
              device.deviceData[0] ? device.deviceData[0].createdAt : null,
            )}
          />
          <PropertyItem
            title={`${t('models.device.geozones')}:`}
            value={getGeozones(device.geozones, t)}
          />
        </>
      </Accordion.Collapse>
    </div>
  );
};

export default EventsDevicesItem;
