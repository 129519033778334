import cn from 'classnames';
// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';
import { Field } from 'redux-form';

import SelectInput from '../select_input/SelectInput';
import styles from './Selectbox.module.scss';

interface ISelectboxProps {
  id?: string;
  defaultValue?: any;
  options: any;
  name: string;
  isMulti?: boolean;
  isClearable?: boolean;
  error?: string;
  size?: string;
}

const Selectbox = (props: ISelectboxProps) => {
  const { size, error } = props;
  const classNames = cn(styles.selectbox, {
    [styles.selectboxSm]: size === 'sm',
  });
  return (
    <>
      <Field className={classNames} {...props} component={SelectInput} />
      {!!error && <div className="invalid-feedback">{error}</div>}
    </>
  );
};

export default Selectbox;
