import React from 'react';
import { Marker } from 'react-leaflet';
import { useSelector } from 'react-redux';

import { settingsSelector } from '../../slices/settings/settingsSlice';
import {
  CheckedBaseStationMarker,
  CheckedCowMarker,
  DefaultBaseStationMarker,
  DefaultCowMarker,
  LightBaseStationMarker,
  LightCowMarker,
  SelectedCowMarker,
} from '../marker/Marker';

interface ICustomMarkerProps {
  type: 'device' | 'baseStation';
  position: number[];
  children?: any;
  isSelected?: boolean;
  isChecked?: boolean;
  isOffline?: boolean;
}

const CustomMarker = (props: ICustomMarkerProps) => {
  const { type, isSelected, isChecked, position, children } = props;
  const { mapTileName }: any = useSelector(settingsSelector);
  let icon;

  if (type === 'device') {
    icon =
      mapTileName === 'OpenStreetMap.Mapnik'
        ? DefaultCowMarker
        : LightCowMarker;

    if (isChecked) {
      icon = CheckedCowMarker;
    }

    if (isSelected) {
      icon = SelectedCowMarker;
    }
  } else {
    icon =
      mapTileName === 'OpenStreetMap.Mapnik'
        ? DefaultBaseStationMarker
        : LightBaseStationMarker;

    if (isChecked) {
      icon = CheckedBaseStationMarker;
    }
  }

  return (
    <Marker icon={icon} position={[position[0], position[1]]}>
      {children}
    </Marker>
  );
};

export default CustomMarker;
