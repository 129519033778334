import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';

import type { ILoginForm } from '../../../interfaces/forms.interfaces';
import { currentUserSelector } from '../../../slices/currentUser/currentUserSlice';
import { errorFor } from '../../../utils/formError';
import Input from '../../input/Input';
import styles from './ForgetPassword.module.scss';

let ForgetPasswordForm: any = (props: any) => {
  const { t } = useTranslation();
  const { password, confirmationPassword, handleSubmit, onSubmit } = props;
  const { errors } = useSelector(currentUserSelector);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        className="form-control-lg"
        component="input"
        type="password"
        name="password"
        placeholder={t('auth.forgetPasswordForm.password')}
        error={errorFor(errors, 'password')}
      />
      <Input
        className="form-control-lg"
        component="input"
        type="password"
        name="confirmationPassword"
        placeholder={t('auth.forgetPasswordForm.confirmationPassword')}
        error={errorFor(errors, 'confirmationPassword')}
      />
      <Row className="mt-5">
        <Col className={styles.links}>
          <div>
            <a href="/login" className="text-left">
              {t('auth.forgetPasswordForm.signIn')}
            </a>
          </div>
          <div>
            <a href="/registration">{t('auth.forgetPasswordForm.signUp')}</a>
          </div>
        </Col>
        <Col className="text-end">
          <Button
            type="submit"
            size="lg"
            variant="success"
            disabled={!password || !confirmationPassword}
          >
            {t('auth.forgetPasswordForm.resetPassword')}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

ForgetPasswordForm = reduxForm<ILoginForm>({
  form: 'forgetPassword',
  enableReinitialize: true,
})(ForgetPasswordForm);

const selector = formValueSelector('forgetPassword');
ForgetPasswordForm = connect((state: any) => {
  const { password, confirmationPassword } = selector(
    state,
    'password',
    'confirmationPassword',
  );
  return { password, confirmationPassword };
})(ForgetPasswordForm);

export default ForgetPasswordForm;
