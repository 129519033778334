// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { formValueSelector, reduxForm } from 'redux-form';

import { useAppDispatch } from '../../../app/hooks';
import logo from '../../../assets/images/logo.png';
import type { ILoginForm } from '../../../interfaces/interfaces';
import {
  resetPassword,
  sendResetPassword,
} from '../../../slices/currentUser/currentUserSlice';
import styles from './ForgetPassword.module.scss';
import ForgetPasswordForm from './ForgetPasswordForm';
import SendForgetPasswordForm from './SendForgetPasswordForm';

let ForgetPassword: any = () => {
  const { t } = useTranslation();
  const [urlParams] = useState(new URLSearchParams(location.search));
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handleSendForgetPasswordSubmit = useCallback(async (formData: any) => {
    const resetPasswordData: any = await dispatch(
      sendResetPassword(formData.email),
    );
    if (!resetPasswordData.error) {
      setIsSuccess(true);
    }
  }, []);

  const handleForgetPasswordSubmit = useCallback(async (formData: any) => {
    const resetPasswordData: any = await dispatch(
      resetPassword({
        ...formData,
        token: urlParams.get('token'),
      }),
    );
    if (!resetPasswordData.error) {
      history.push('/login');
    }
  }, []);

  return (
    <div className="d-flex justify-content-center">
      <div className={styles.login}>
        <img className={styles.logo} src={logo} />
        <h1 className={styles.title}>{t('auth.forgetPassword.title')}</h1>
        {urlParams.get('token') && !isSuccess && (
          <ForgetPasswordForm onSubmit={handleForgetPasswordSubmit} />
        )}
        {(!urlParams || !urlParams.get('token')) && !isSuccess && (
          <SendForgetPasswordForm onSubmit={handleSendForgetPasswordSubmit} />
        )}
        {!!isSuccess && (
          <h3 className="text-center">
            {t('auth.forgetPassword.sendEmailSuccess')}
          </h3>
        )}
      </div>
    </div>
  );
};

ForgetPassword = reduxForm<ILoginForm>({
  form: 'sendForgetPassword',
  enableReinitialize: true,
})(ForgetPassword);

const selector = formValueSelector('sendForgetPassword');
ForgetPassword = connect((state: any) => {
  const email = selector(state, 'email');
  return { email };
})(ForgetPassword);

export default ForgetPassword;
