import React, { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';

import type { IDeviceModel } from '../../interfaces/interfaces';
import { sortedEntities } from '../../utils/device';
import DevicesSort from '../devices_sort/DevicesSort';
import MonitoringUnactiveListItem from '../monitoring_unactive_list_item/MonitoringUnactiveListItem';
import styles from './MonitoringUnactiveDevice.module.scss';

interface IMonitoringUnactiveDevices {
  devices: IDeviceModel[];
}

const MonitoringUnactiveDevices = (props: IMonitoringUnactiveDevices) => {
  const { devices } = props;

  const [sortMethod, setSortMethod] = useState<'abc'>('abc');
  const [sortDirection, setSortDirection] = useState<'up' | 'down'>('up');
  const sortedDevices = sortedEntities(devices, sortMethod, sortDirection);

  return (
    <>
      {sortedDevices.length > 0 ? (
        <DevicesSort
          sortMethodsList={['abc']}
          sortMethod={sortMethod}
          sortDirection={sortDirection}
          setSortMethod={setSortMethod}
          setSortDirection={setSortDirection}
        />
      ) : (
        <p className="text-center">Нет устройств</p>
      )}
      <div className={styles.devicesList}>
        <Scrollbars autoHide>
          {sortedDevices.length > 0 &&
            sortedDevices.map((device: IDeviceModel) => (
              <MonitoringUnactiveListItem key={device.id} device={device} />
            ))}
        </Scrollbars>
      </div>
    </>
  );
};

export default MonitoringUnactiveDevices;
