import { get, patch, post } from '../../helpers/axios';

export async function loginRequest(username: string, password: string) {
  return post('login', { username, password }, false);
}

export async function createRequest(formData: any) {
  return post('users/registration', formData, false);
}

export async function fetchRequest() {
  return get('user');
}

export async function updateRequest(formData: any) {
  return patch('users/current', { user: formData });
}

export async function sendResetPasswordRequest(email: string) {
  return post('reset_password', { email });
}

export async function resetPasswordRequest(password: string, token: string) {
  return post(`reset_password/${token}`, { password });
}
