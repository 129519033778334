import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import store from 'store';

import en from './localizations/en.json';
import ru from './localizations/ru.json';

const locale = store.get('locale') || 'ru';

const resources = {
  ru: {
    translation: ru,
  },
  en: {
    translation: en,
  },
};

void i18n.use(initReactI18next).init({
  resources,
  lng: locale,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
