import type { AxiosResponse } from 'axios';

import { get } from '../../helpers/axios';

export async function fetchRequest(props: any): Promise<AxiosResponse<any>> {
  let path = '/eventTypes';
  if (props.deviceId) {
    path = `/devices/${props.deviceId}${path}`;
  }

  return get(`${path}${props.withEventsOnly ? '?with_events_only=true' : ''}`);
}
