import cn from 'classnames';
// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';
import { Field } from 'redux-form';

import styles from './Radiobox.module.scss';

interface IRadioboxProps {
  id?: string | undefined;
  checked?: boolean;
  label?: string | undefined;
  onChange?: (a: any) => void;
  name?: string;
  className?: string;
  value: any;
}

const Radiobox = ({
  id,
  label,
  className,
  checked,
  ...rest
}: IRadioboxProps) => {
  const classNames = cn(styles.checkbox, className);
  return (
    <label className={classNames}>
      {!!label && <div className={styles.label}>{label}</div>}
      <Field component="input" type="radio" {...rest} checked={checked} />
      <div className={styles.checkboxMark} />
    </label>
  );
};

export default Radiobox;
