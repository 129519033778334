import type { AxiosResponse } from 'axios';
import moment from 'moment';

import { get } from '../../helpers/axios';

export async function fetchRequest({
  deviceId,
  fromTime,
  toTime,
  page,
}: any): Promise<AxiosResponse<any>> {
  const params: any = {
    from_time: moment(fromTime).unix().toString(),
    to_time: moment(toTime).unix().toString(),
  };

  if (page) {
    params.page = page;
  }

  const urlParams = new URLSearchParams(params).toString();
  return get(`devices/${deviceId.value}/deviceData?${urlParams}`);
}

export async function fetchAsFileRequest({
  deviceId,
  fromTime,
  toTime,
}: any): Promise<AxiosResponse<any>> {
  const params: any = {
    from_time: moment(fromTime).unix().toString(),
    to_time: moment(toTime).unix().toString(),
    download: true,
  };
  const urlParams = new URLSearchParams(params).toString();
  return get(`devices/${deviceId.value}/deviceData?${urlParams}`);
}

export async function fetchDataPointsRequest({
  deviceId,
  fromTime,
  toTime,
}: any): Promise<AxiosResponse<any>> {
  const urlParams = new URLSearchParams({
    from_time: moment(fromTime).unix().toString(),
    to_time: moment(toTime).unix().toString(),
  }).toString();
  return get(`devices/${deviceId.value}/dataPoints?${urlParams}`);
}

export async function findRequest(id: number): Promise<AxiosResponse<any>> {
  return get(`deviceData/${id}`);
}
