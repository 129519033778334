import cn from 'classnames';
// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React, { useState } from 'react';
import { Field } from 'redux-form';

import styles from './ColorInput.module.scss';

interface IColorInputProps {
  color: string;
  name: string;
}

const ColorInput = (props: IColorInputProps) => {
  const { color, name } = props;
  const buttons: Record<string, string> = {
    danger: '#fd397a',
    info: '#5578eb',
    success: '#0abb87',
    warning: '#ffb822',
    dark: '#282a3c',
  };

  return (
    <div className={styles.colorInput}>
      {Object.keys(buttons).map((key, index: number) => {
        let className = `btn btn-${key} btn-colored btn-sm`;
        if (key !== 'dark') {
          className = `${className} me-2`;
        }
        const btnClassName = cn(className);
        const btnContainerClassName = cn(styles.colorInputButton, {
          [styles.active]: color === buttons[key],
        });

        return (
          <>
            {!!index && <div className={styles.buttonPadding}></div>}
            <div className={btnContainerClassName} key={index}>
              <button type="button" className={btnClassName}>
                &nbsp;
              </button>
              <Field
                name={name}
                component="input"
                type="radio"
                value={buttons[key]}
              />
            </div>
          </>
        );
      })}
    </div>
  );
};

export default ColorInput;
