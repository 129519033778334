// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React, { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';

import type { IDeviceForm, IGeozoneModel } from '../../interfaces/interfaces';
import styles from './Device.module.scss';
import DeviceForm from './DeviceForm';

interface IDeviceProps {
  openedModel?: Record<string, any> | null;
  closeHandle: any;
  onSubmit: any;
}

const Device = ({ openedModel, onSubmit, closeHandle }: IDeviceProps) => {
  const { t } = useTranslation();
  let initialValues: IDeviceForm = {};

  if (openedModel) {
    initialValues = {
      id: openedModel.id,
      accountId: openedModel.accountId,
      name: openedModel.name,
      number: openedModel.number,
      port: openedModel.port,
      version: openedModel.version,
      eventConfig: openedModel.eventConfig,
      active: openedModel.active,
      events: openedModel.events,
    };

    if (openedModel.geozones && openedModel.geozones.length > 0) {
      initialValues.deviceGeozones = openedModel.geozones.map(
        (geozone: IGeozoneModel) => ({
          value: geozone.id,
          label: geozone.name,
        }),
      );
    }
  }

  const title = t(
    `device.${openedModel && openedModel.id ? 'editTitle' : 'newTitle'}`,
  );

  return (
    <div className={styles.device}>
      <Scrollbars autoHide>
        <h1>{title}</h1>
        <div className="row">
          <div className="col-8">
            <DeviceForm
              initialValues={initialValues}
              onSubmit={onSubmit}
              closeHandle={closeHandle}
            />
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

export default Device;
