import 'react-datepicker/dist/react-datepicker.css';

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ru from 'date-fns/locale/ru';
// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import styles from './DateTimeInput.module.scss';
registerLocale('ru', ru);

// interface IDateTimeInputProps {
//   input: any;
//   id?: string;
//   label?: string;
//   className?: string;
//   component: string;
//   type: string;
//   readOnly?: boolean;
//   onChange?: (a: any) => void;
//   placeholder?: string;
//   name?: string;
//   error?: string;
// }

const DateTimeInput = (props: any) => {
  const { input, meta, className, minDate, setDate, ...rest } = props;
  return (
    <div className={styles.wrapper}>
      <DatePicker
        {...rest}
        autoComplete="off"
        minDate={minDate}
        locale="ru"
        dateFormat="dd MMMM yyyy, HH:mm"
        showTimeInput
        selected={input.value}
        onChange={(valueObject: Record<string, any> | any[] | null) => {
          input.onChange(valueObject);
          setDate(valueObject);
        }}
        className={className}
      />
      <FontAwesomeIcon className={styles.icon} icon={faCalendarAlt} />
    </div>
  );
};

export default DateTimeInput;
