import React, { useMemo } from 'react';
import { CircleMarker } from 'react-leaflet';

import type { IDataPointModel } from '../../interfaces/models.interfaces';
import { Map } from '../map/Map';
import MessagesTrigger from '../messages_trigger/MessagesTrigger';
import Websockets from '../websockets/Websockets';

interface IMessagesMapProps {
  active: string;
  className?: string;
  dataPoints: IDataPointModel[];
  handleChangeType: any;
  height: any;
  position: 'top' | 'bottom';
  onMarkerClick: any;
  ref: any;
}

const MARKER_COLOR = '#ffa800';

// const handleClickCircleMarker = (deviceDataId: number) => {
//   document
//     .getElementsByClassName(`messages-table-tr-${deviceDataId}`)[0]
//     .scrollIntoView();
//   document.getElementsByClassName('messages-table-body')[0].scrollTop -= 20;
// };

export const MessagesMap: React.FunctionComponent<IMessagesMapProps> =
  // eslint-disable-next-line react/display-name
  React.forwardRef((props: IMessagesMapProps, ref: any) => {
    const {
      active,
      handleChangeType,
      position,
      className,
      height,
      dataPoints,
      onMarkerClick,
    } = props;

    const markers = useMemo(
      () => (
        <>
          {dataPoints.length > 0 &&
            dataPoints.map((dataPoint: IDataPointModel, index: number) => (
              <CircleMarker
                key={index}
                center={[dataPoint.x, dataPoint.y]}
                fillOpacity={1}
                opacity={1}
                color={MARKER_COLOR}
                radius={5}
                weight={0}
                eventHandlers={{
                  click: onMarkerClick.bind(undefined, dataPoint.id),
                  mouseover: (e) => e.target.setStyle({ radius: 9 }),
                  mouseout: (e) => e.target.setStyle({ radius: 5 }),
                }}
              />
            ))}
        </>
      ),
      [dataPoints],
    );

    return (
      <Websockets>
        <div className={className} style={{ height }}>
          <Map ref={ref} zoomControlPosition="topright">
            {markers}
          </Map>
          <div className="messages-tools row">
            <div className="col-6 text-start" />
            <div className="col-6 text-end">
              <MessagesTrigger
                active={active}
                handleChangeType={handleChangeType}
                position={position}
              />
            </div>
          </div>
        </div>
      </Websockets>
    );
  });

export default MessagesMap;
