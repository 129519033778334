import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { get } from 'lodash';
import React from 'react';

import type { IDeviceDataModel } from '../../interfaces/models.interfaces';

interface IDeviceGeotag {
  deviceData: IDeviceDataModel;
  className?: string;
}

const DeviceGeotag = (props: IDeviceGeotag) => {
  const { deviceData, className } = props;
  const lat = get(deviceData, 'data.position.lat');
  const lon = get(deviceData, 'data.position.lon');
  const classNames = cn('text-success', className);

  return lat && lon ? (
    <FontAwesomeIcon icon={faMapMarkerAlt} className={classNames} />
  ) : (
    <></>
  );
};

export default DeviceGeotag;
