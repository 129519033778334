// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../app/hooks';
import { isCan } from '../../helpers/permission';
import type { IDeviceModel } from '../../interfaces/interfaces';
import {
  create,
  destroy,
  devicesSelector,
  fetch,
  selectAll,
  update,
} from '../../slices/devices/devicesSlice';
import { setText } from '../../slices/notification/notificationSlice';
import { settingsSelector } from '../../slices/settings/settingsSlice';
import DeviceForm from '../device/DeviceForm';
import EmployeesListItem from '../employees_list_item/EmployeesListItem';
import styles from './Employees.module.scss';

const Employees = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<Record<string, any>>({
    type: 'card',
    active: true,
  });
  // const [isCanRead, setIsCanRead] = useState<boolean>(false);
  const [isCanCreate, setIsCanCreate] = useState<boolean>(false);
  const [isCanUpdate, setIsCanUpdate] = useState<boolean>(false);
  const [isCanDestroy, setIsCanDestroy] = useState<boolean>(false);

  const employees = useSelector(selectAll);
  const { errors } = useSelector(devicesSelector);
  const { currentUser } = useSelector(settingsSelector);

  const handleCloseModal = useCallback(() => {
    setInitialValues({ type: 'card', active: true });
    setIsShowModal(false);
  }, []);

  const handleUpdate = useCallback((formData: any) => {
    void dispatch(update(formData)).then((data: any) => {
      if (!data.error) {
        handleCloseModal();
        dispatch(setText(t('shared.notification.updated')));
      }
    });
  }, []);

  const handleCreate = useCallback((formData: any) => {
    void dispatch(create(formData)).then((data: any) => {
      if (!data.error) {
        handleCloseModal();
        dispatch(setText(t('shared.notification.created')));
      }
    });
  }, []);

  const onDestroy = useCallback((employee: IDeviceModel) => {
    void dispatch(destroy(employee.id)).then((data: any) => {
      if (!data.error) {
        dispatch(setText(t('shared.notification.removed')));
      }
    });
  }, []);

  const onEdit = useCallback((employee: IDeviceModel) => {
    setInitialValues({
      id: employee.id,
      name: employee.name,
      number: employee.number,
      port: employee.port,
      version: employee.version,
      active: employee.active,
      type: 'card',
    });
    setIsShowModal(true);
  }, []);

  useEffect(() => {
    // setIsCanRead(isCan('read', 'Device', currentUser));
    setIsCanCreate(isCan('create', 'Device', currentUser));
    setIsCanUpdate(isCan('update', 'Device', currentUser));
    setIsCanDestroy(isCan('destroy', 'Device', currentUser));
  }, [currentUser]);

  useEffect(() => {
    void dispatch(fetch({ type: 'card' }));
  }, []);

  return (
    <div className={styles.content}>
      <Scrollbars autoHide>
        <Row className="mb-3">
          <Col sm>
            <h1>{t('employees.title')}</h1>
          </Col>
          <Col sm className="text-end">
            {isCanCreate && (
              <Button
                variant="outline-success"
                onClick={() => setIsShowModal(true)}
              >
                <FontAwesomeIcon className="me-2" icon={faPlus} />
                {t('employees.new')}
              </Button>
            )}
          </Col>
        </Row>
        <Table>
          <thead>
            <th>{t('models.device.number')}</th>
            <th>{t('employees.name')}</th>
            <th>{t('employees.timePoints')}</th>
            <th></th>
          </thead>
          <tbody>
            {employees.length > 0 &&
              employees.map((employee: IDeviceModel) => (
                <EmployeesListItem
                  key={employee.id}
                  employee={employee}
                  onEdit={onEdit}
                  onDestroy={onDestroy}
                  canUpdate={isCanUpdate}
                  canDestroy={isCanDestroy}
                />
              ))}
            {employees.length === 0 && (
              <tr>
                <td colSpan={3} className="text-center">
                  {t('shared.empty')}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Modal show={isShowModal} onHide={handleCloseModal}>
          <Modal.Header>
            <Modal.Title as="h2">{t('employees.new')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DeviceForm
              initialValues={initialValues}
              onSubmit={initialValues.id ? handleUpdate : handleCreate}
              closeHandle={handleCloseModal}
              errors={errors}
            />
          </Modal.Body>
        </Modal>
      </Scrollbars>
    </div>
  );
};

export default Employees;
