import React from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';

import ColorInput from '../color_input/ColorInput';
import Selectbox from '../selectbox/Selectbox';

interface IMessagesChartFormProps {
  handleFormSubmit: any;
  handleClose: any;
  initialValues: any;
  className?: string;
}

// eslint-disable-next-line max-len
let MessagesChartForm: any = (props: any) => {
  const { t } = useTranslation();
  const { handleSubmit, handleClose, handleFormSubmit, color } = props;
  const lineThicknessOptions = [
    { value: 0, label: 'Без линии' },
    { value: 1, label: '1px' },
    { value: 2, label: '2px' },
    { value: 3, label: '3px' },
    { value: 4, label: '4px' },
    { value: 5, label: '5px' },
  ];
  const lineStyleOptions = [
    { value: 'solid', label: 'Сплошная' },
    { value: 'dashed', label: 'Пунктирная' },
  ];

  return (
    <form className="kt-form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Form.Group as={Row}>
        <label htmlFor="thickness" className="col-sm-5 col-form-label">
          {t('shared.form.lineThickness')}:
        </label>
        <div className="col-sm-7">
          <Selectbox
            id="lineThickness"
            options={lineThicknessOptions}
            name="lineThickness"
          />
        </div>
      </Form.Group>
      <Form.Group as={Row}>
        <label htmlFor="lineStyle" className="col-sm-5 col-form-label">
          {t('shared.form.lineStyle')}:
        </label>
        <div className="col-sm-7">
          <Selectbox
            id="lineStyle"
            options={lineStyleOptions}
            name="lineStyle"
          />
        </div>
      </Form.Group>
      <Form.Group as={Row} className="mb-4">
        <label htmlFor="thickness" className="col-sm-5 col-form-label">
          {t('shared.form.lineColor')}:
        </label>
        <div className="col-sm-7">
          <ColorInput name="color" color={color} />
        </div>
      </Form.Group>
      <Button variant="primary" className="me-2" type="submit">
        {t('shared.form.button.save')}
      </Button>
      <Button variant="warning" onClick={handleClose}>
        {t('shared.form.button.close')}
      </Button>
    </form>
  );
};

MessagesChartForm = reduxForm<Record<string, boolean>, IMessagesChartFormProps>(
  {
    form: 'messageChart',
    enableReinitialize: true,
  },
)(MessagesChartForm);

const selector = formValueSelector('messageChart');
MessagesChartForm = connect((state: any) => {
  const color = selector(state, 'color');
  return { color };
})(MessagesChartForm);

export default MessagesChartForm;
