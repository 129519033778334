import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';

import type { IDeviceModel } from '../../interfaces/interfaces';
import { sortedEntities } from '../../utils/device';
import DevicesSort from '../devices_sort/DevicesSort';
import EventsDevicesItem from '../events_devices_item/EventsDevicesItem';

interface IEventsDevices {
  devices: IDeviceModel[];
  setSelectedDeviceId: any;
  initialValues: any;
  id: number | undefined;
}

let EventsDevices: any = (props: IEventsDevices) => {
  const { devices, id, setSelectedDeviceId } = props;

  const [sortMethod, setSortMethod] = useState<'abc' | 'status'>('abc');
  const [sortDirection, setSortDirection] = useState<'up' | 'down'>('up');
  const sortedDevices = sortedEntities(devices, sortMethod, sortDirection);

  useEffect(() => {
    if (id) {
      setSelectedDeviceId(id);
    }
  }, [id]);

  return (
    <>
      <DevicesSort
        sortMethodsList={['abc', 'status']}
        sortMethod={sortMethod}
        sortDirection={sortDirection}
        setSortMethod={setSortMethod}
        setSortDirection={setSortDirection}
      />
      <form>
        <Accordion defaultActiveKey="1">
          {sortedDevices.length > 0 &&
            sortedDevices.map((device: IDeviceModel) => (
              <EventsDevicesItem
                key={device.id}
                isChecked={device.id === id}
                device={device}
              />
            ))}
        </Accordion>
      </form>
    </>
  );
};

EventsDevices = reduxForm<Record<string, boolean>, IEventsDevices>({
  form: 'events',
  enableReinitialize: true,
})(EventsDevices);

const selector = formValueSelector('events');
EventsDevices = connect((state: any) => {
  // Redux convert number to string automatically. We convert string to number for radio box.
  let id = selector(state, 'id');
  id = Number.parseInt(id, 10);
  return { id };
})(EventsDevices);

export default EventsDevices;
