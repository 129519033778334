import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import type { RootState } from '../../app/store';
import type {
  IDeviceModel,
  IFetchDevicesResponse,
} from '../../interfaces/interfaces';
import { formattedErrors } from '../../utils/formError';
import {
  createRequest,
  destroyRequest,
  fetchMonitoringRequest,
  fetchRequest,
  updateRequest,
} from './devicesApi';

export const fetch = createAsyncThunk(
  'devices/fetch',
  async (props: any, thunkAPI) => {
    try {
      const response = await fetchRequest(props);
      const data: IFetchDevicesResponse = await response.data;
      return data.devices;
    } catch {
      return thunkAPI.rejectWithValue('Sorry. something wrong');
    }
  },
);

export const monitoring = createAsyncThunk(
  'devices/monitoring',
  async (_, thunkAPI) => {
    try {
      const response = await fetchMonitoringRequest();
      const data: IFetchDevicesResponse = await response.data;
      return data.devices;
    } catch {
      return thunkAPI.rejectWithValue('Sorry. something wrong');
    }
  },
);

export const create = createAsyncThunk(
  'devices/create',
  async (formData: any, thunkAPI) => {
    try {
      const response = await createRequest(formData);
      const data: any = await response.data;
      return data.device;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errors);
    }
  },
);

export const update = createAsyncThunk(
  'devices/update',
  async (formData: any, thunkAPI) => {
    try {
      const response = await updateRequest(formData);
      const data: any = await response.data;
      return data.device;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errors);
    }
  },
);

export const destroy = createAsyncThunk(
  'devices/destroy',
  async (id: number, thunkAPI) => {
    try {
      await destroyRequest(id);
      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errors);
    }
  },
);

// eslint-disable-next-line @typescript-eslint/unbound-method
const { getInitialState, getSelectors, setAll, updateOne, addOne, removeOne } =
  createEntityAdapter<IDeviceModel>();

export const devicesSlice = createSlice({
  name: 'devices',
  initialState: getInitialState({
    errors: [],
  }),
  reducers: {
    updateStore: (state: any, { payload }) => {
      updateOne(state, { id: payload.id, changes: payload });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetch.pending, (_: any) => {
        // setAll(state, []);
      })
      .addCase(fetch.fulfilled, (state: any, { payload }: any) => {
        setAll(state, payload);
      })
      .addCase(fetch.rejected, (_: any) => {
        // setAll(state, []);
      })
      .addCase(monitoring.pending, (_) => {
        // setAll(state, []);
      })
      .addCase(monitoring.fulfilled, (state, { payload }: any) => {
        setAll(state, payload);
      })
      .addCase(monitoring.rejected, (_) => {
        // setAll(state, []);
      })
      .addCase(update.pending, (state: any) => {
        state.errors = [];
      })
      .addCase(update.fulfilled, (state: any, { payload }: any) => {
        updateOne(state, { id: payload.id, changes: payload });
        state.errors = [];
      })
      .addCase(update.rejected, (state: any, { payload }) => {
        state.errors = formattedErrors(payload);
      })
      .addCase(create.pending, (state: any) => {
        state.errors = [];
      })
      .addCase(create.fulfilled, (state: any, { payload }: any) => {
        addOne(state, payload);
        state.errors = [];
      })
      .addCase(create.rejected, (state: any, { payload }) => {
        state.errors = formattedErrors(payload);
      })
      .addCase(destroy.pending, (state: any) => {
        state.errors = [];
      })
      .addCase(destroy.fulfilled, (state: any, { payload }: any) => {
        removeOne(state, payload);
      })
      .addCase(destroy.rejected, (state: any, { payload }) => {
        state.errors = formattedErrors(payload);
      });
  },
});

export const { updateStore } = devicesSlice.actions;
export const devicesSelector = (state: RootState) => state.devices;
export const { selectAll, selectById } = getSelectors<RootState>(
  (state: RootState) => state.devices,
);

export default devicesSlice.reducer;
