import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { isCan } from '../../helpers/permission';
import type { IBaseStationModel } from '../../interfaces/interfaces';
import { settingsSelector } from '../../slices/settings/settingsSlice';
import { sortedEntities } from '../../utils/baseStation';
import DevicesSort from '../devices_sort/DevicesSort';
import styles from '../monitoring_base_stations/MonitoringBaseStations.module.scss';
import MonitoringBaseStationsForm from './MonitoringBaseStationsForm';

interface IMonitoringBaseStations {
  baseStations: IBaseStationModel[];
  openModelFormHandle: any;
  openedModelId: number | null;
  checkedBaseStationsIds: number[];
  setCheckedBaseStationsIds: any;
}

const baseStationsIds: any = (ids: number[]) => {
  const result: Record<string, any> = {};
  for (const id of ids) {
    result[`baseStationId${id}`] = true;
  }
  return result;
};

const onSubmit = (setCheckedBaseStationsIds: any, formData: any): void => {
  const result = [];
  const keys = Object.keys(formData);
  for (const key of keys) {
    if (formData[key]) {
      result.push(Number.parseInt(key.replace('baseStationId', ''), 10));
    }
  }

  setCheckedBaseStationsIds(result);
};

const MonitoringBaseStations = (props: IMonitoringBaseStations) => {
  const { t } = useTranslation();
  const {
    baseStations,
    openModelFormHandle,
    openedModelId,
    checkedBaseStationsIds,
    setCheckedBaseStationsIds,
  } = props;

  const [sortMethod, setSortMethod] = useState<'abc' | 'status'>('abc');
  const [isCanUpdate, setIsCanUpdate] = useState<boolean>(false);
  const [isCanCreate, setIsCanCreate] = useState<boolean>(false);
  const [sortDirection, setSortDirection] = useState<'up' | 'down'>('up');
  const sortedBaseStations = sortedEntities(baseStations, sortDirection);
  const { currentUser } = useSelector(settingsSelector);

  useEffect(() => {
    setIsCanUpdate(isCan('update', 'BaseStation', currentUser));
    setIsCanCreate(isCan('create', 'BaseStation', currentUser));
  }, [currentUser]);

  return (
    <>
      {sortedBaseStations.length > 0 ? (
        <>
          <DevicesSort
            sortMethodsList={['abc']}
            sortMethod={sortMethod}
            sortDirection={sortDirection}
            setSortMethod={setSortMethod}
            setSortDirection={setSortDirection}
          />
          <div className={styles.baseStationsList}>
            <Scrollbars autoHide>
              <MonitoringBaseStationsForm
                className={styles.monitoringBaseStations}
                initialValues={baseStationsIds(checkedBaseStationsIds)}
                onSubmit={onSubmit.bind(undefined, setCheckedBaseStationsIds)}
                openedModelId={openedModelId}
                openModelFormHandle={openModelFormHandle}
                baseStations={sortedBaseStations}
                canUpdate={isCanUpdate}
              />
            </Scrollbars>
          </div>
        </>
      ) : (
        <p className="text-center">
          {t('monitoringBaseStations.noBaseStations')}
        </p>
      )}
      {isCanCreate && (
        <div className={`${styles.addBaseStation} container-fluid`}>
          <Button
            size="sm"
            variant="outline-success w-100"
            onClick={openModelFormHandle.bind(undefined, 'baseStation', null)}
          >
            <FontAwesomeIcon className="me-2" icon={faPlus} />
            {t('monitoringBaseStations.new')}
          </Button>
        </div>
      )}
    </>
  );
};

export default MonitoringBaseStations;
