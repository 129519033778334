import React from 'react';
import { Accordion } from 'react-bootstrap';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';

import type { IBaseStationModel } from '../../interfaces/interfaces';
import MonitoringBaseStationListItem from '../monitoring_base_station_list_item/MonitoringBaseStationListItem';

interface IMonitoringBaseStationsFormProps {
  baseStations: IBaseStationModel[];
  onSubmit: any;
  openModelFormHandle: any;
  openedModelId: number | null;
  initialValues: Record<string, boolean>;
  className: string;
  canUpdate: boolean;
}

const MonitoringBaseStationsForm: React.FC<
  InjectedFormProps<Record<string, boolean>, IMonitoringBaseStationsFormProps> &
    IMonitoringBaseStationsFormProps
> = ({
  baseStations,
  onSubmit,
  initialValues,
  className,
  openModelFormHandle,
  openedModelId,
  canUpdate,
}: IMonitoringBaseStationsFormProps) => (
  <form onSubmit={onSubmit} className={className}>
    <Accordion>
      {baseStations.length > 0 &&
        baseStations.map((baseStation: IBaseStationModel) => (
          <MonitoringBaseStationListItem
            isChecked={initialValues[`baseStationId${baseStation.id}`]}
            openModelFormHandle={openModelFormHandle}
            key={baseStation.id}
            baseStation={baseStation}
            openedModelId={openedModelId}
            canUpdate={canUpdate}
          />
        ))}
    </Accordion>
  </form>
);

export default reduxForm<
  Record<string, boolean>,
  IMonitoringBaseStationsFormProps
>({
  form: 'monitoringBaseStations',
  enableReinitialize: true,
})(MonitoringBaseStationsForm);
