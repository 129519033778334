import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Modal } from 'react-bootstrap';

import type { IDeviceDataModel } from '../../interfaces/models.interfaces';
import { withSecondsTimeFormat } from '../../utils/date';
import { coordsAsString, getGeozones } from '../../utils/device';
import DeviceGeotag from '../device_geotag/DeviceGeotag';
import DeviceOnline from '../device_online/DeviceOnline';
import PropertyItem from '../property_item/PropertyItem';

interface IMonitoringPopoverProps {
  name: string;
  number: string;
  eventsCount: number;
  deviceData: IDeviceDataModel | null;
  geozones: any[];
  t: any;
}

const MonitoringPopover = (props: IMonitoringPopoverProps) => {
  const { name, number, eventsCount, deviceData, geozones, t } = props;

  return (
    <Modal.Dialog>
      <Modal.Body>
        <h1 className="mb-3">
          <span className="me-1">{name}</span>
          {deviceData && (
            <>
              <DeviceOnline className="me-2" deviceData={deviceData} />
              <DeviceGeotag className="me-2" deviceData={deviceData} />
              {eventsCount > 0 && (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="text-warning me-2"
                />
              )}
            </>
          )}
        </h1>
        <PropertyItem title={`${t('models.device.number')}:`} value={number} />
        {deviceData && (
          <>
            <PropertyItem
              title={`${t('models.deviceData.coordinates')}:`}
              value={coordsAsString(deviceData)}
            />
            <PropertyItem
              title={`${t('shared.models.createdAt')}:`}
              value={withSecondsTimeFormat(deviceData.createdAt)}
            />
          </>
        )}
        <PropertyItem
          title={`${t('models.device.geozones')}:`}
          value={getGeozones(geozones, t)}
        />
      </Modal.Body>
    </Modal.Dialog>
  );
};

export default MonitoringPopover;
