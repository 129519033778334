import type { IUserModel } from '../interfaces/models.interfaces';

export const defaultRules = {
  Device: {
    read: true,
    create: false,
    update: false,
    destroy: false,
  },
  BaseStation: {
    read: true,
    create: false,
    update: false,
    destroy: false,
  },
  DeviceData: {
    read: true,
  },
  Geozone: {
    read: true,
    create: true,
    update: true,
    destroy: true,
  },
  Event: {
    read: true,
    update: true,
  },
  User: {
    read: false,
    create: false,
    update: false,
    destroy: false,
  },
};

export function isCan(
  action: string,
  model: string,
  user: IUserModel | null,
): boolean {
  return !!(
    user?.role === 'owner' || user?.permission?.rules?.[model]?.[action]
  );
}
