import L from 'leaflet';
import { get } from 'lodash';
import moment from 'moment';

import type {
  IDeviceDataModel,
  IDeviceModel,
  IGeozoneModel,
} from '../interfaces/models.interfaces';

// const DEFAULT_LAT = 60;
// const DEFAULT_LON = 60;

export function coordsAsString(deviceData: IDeviceDataModel): string | null {
  const lat = get(deviceData, 'data.position.lat');
  const lon = get(deviceData, 'data.position.lon');

  return lat && lon ? `${lat}/ ${lon}` : null;
}

export function coordsAsArray(device: IDeviceModel): number[] | null {
  const lat = get(device, 'deviceData[0].data.position.lat');
  const lon = get(device, 'deviceData[0].data.position.lon');

  return lat && lon ? [lat, lon] : null;
}

export function getFullTime(
  deviceData: IDeviceDataModel | null,
): string | null {
  if (!deviceData) {
    return null;
  }
  return moment(deviceData.createdAt).format('DD.MM.YYYY HH:mm:ss');
}

export function getTime(deviceData: IDeviceDataModel): string {
  return moment(deviceData.createdAt).format('DD.MM.YYYY HH:mm');
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
export function getGeozones(geozones: IGeozoneModel[], t: any): string {
  return geozones && geozones.length > 0
    ? geozones.map((geozone: IGeozoneModel) => geozone.name).join(', ')
    : t('shared.empty');
}

export function isOnline(time: Date | null): boolean {
  if (!time) {
    return false;
  }
  return moment().subtract('1', 'day') < moment(time);
}

export function coordinatesFromDeviceData(deviceData: any[]): any[] {
  return deviceData
    .filter(
      (item: any) =>
        item.data &&
        item.data.position &&
        item.data.position.lat &&
        item.data.position.lon,
    )
    .map((item) => ({
      x: item.data.position.lat,
      y: item.data.position.lon,
    }));
}

export function uniqueBounds(points: any) {
  let result = null;
  if (points.length >= 2) {
    // get unique coordinates coz bounds function doesn't like doublicates
    points = points.filter(
      (obj: any, pos: any, arr: any[]) =>
        obj.x &&
        obj.y &&
        arr.map((mapObj) => mapObj.x).indexOf(obj.x) === pos &&
        arr.map((mapObj) => mapObj.y).indexOf(obj.y) === pos,
    );
  }

  if (points.length === 1) {
    const point = points[0];
    const firstPos = [point.x - 0.1, point.y - 0.1];
    const secondPos = point.x;
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const thirdPos = [point.x + 0.1, point.y + 0.1];
    result = L.latLngBounds([firstPos, secondPos, thirdPos]);
  } else if (points.length >= 2) {
    result = L.latLngBounds(points.map((item: any) => [item.x, item.y]));
  }

  return result;
}

export function flyTo(center: any, zoom: any, map: any) {
  map.flyTo(center, zoom, {
    animate: false,
    duration: 0.1,
    easeLinearity: 1,
  });
}

export function setBounds(coordinates: any[], map: any): void {
  let center = null;
  let zoom = null;
  if (coordinates.length === 0) {
    // center = [DEFAULT_LAT, DEFAULT_LON];
    // zoom = 15;
  } else if (coordinates.length === 1) {
    center = [coordinates[0].x, coordinates[0].y];
    zoom = 15;
    flyTo(center, zoom, map);
  } else {
    const bounds: any = uniqueBounds(coordinates);
    const centerBounds = bounds.getCenter();
    const zoomBounds = map.getBoundsZoom(bounds);
    // TODO: -1 because sometimes one point can be placed in the border of the map. when you click to this point
    // for showing popup - exception
    // zoom -= 1
    center = [centerBounds.lat, centerBounds.lng];
    zoom = zoomBounds;
    flyTo(center, zoom, map);
  }
}

export const sortedEntities = (
  devices: IDeviceModel[],
  sortMethod: string,
  sortDirection: string,
) => {
  if (sortMethod === 'status') {
    return sortDirection === 'up'
      ? devices.sort((a: any, b: any) => {
          const aActiveNum = isOnline(get(a, 'deviceData[0].createdAt'))
            ? '1'
            : '0';
          const bActiveNum = isOnline(get(b, 'deviceData[0].createdAt'))
            ? '1'
            : '0';
          return bActiveNum.localeCompare(aActiveNum);
        })
      : devices.sort((a: any, b: any) => {
          const aActiveNum = isOnline(get(a, 'deviceData[0].createdAt'))
            ? '1'
            : '0';
          const bActiveNum = isOnline(get(b, 'deviceData[0].createdAt'))
            ? '1'
            : '0';
          return aActiveNum.localeCompare(bActiveNum);
        });
  } else {
    return sortDirection === 'up'
      ? devices.sort((a: IDeviceModel, b: IDeviceModel) =>
          a.name > b.name ? 1 : -1,
        )
      : devices.sort((a: IDeviceModel, b: IDeviceModel) =>
          b.name > a.name ? 1 : -1,
        );
  }
};

export const newDeviceTemplate = {
  active: true,
};
