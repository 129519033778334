// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';

import styles from './PropertyItem.module.scss';
interface IPropertyProps {
  title: string | number;
  value: string | number | null;
}

const PropertyItem = ({ title, value }: IPropertyProps) => (
  <div className={styles.propertyItem}>
    <div className={styles.propertyItemTitle}>{title}</div>
    <div>{value}</div>
  </div>
);

export default PropertyItem;
