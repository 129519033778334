import { SubmissionError } from 'redux-form';

export function errorFor(errors: any, attribute: any) {
  if (errors === undefined || Object.keys(errors).length === 0) {
    return null;
  }

  return errors[attribute];
}

export function formattedErrors(arrayErrors: any) {
  // eslint-disable-next-line unicorn/no-array-reduce
  return arrayErrors.reduce(
    (object: any, item: any) =>
      Object.assign(object, { [item.field]: item.message }),
    {},
  );
}

export function hasErrors(component: any) {
  return component.props.errors && component.props.errors.length > 0;
}

export function errorsFor(component: any, attribute: any) {
  if (!hasErrors(component)) {
    return null;
  }

  const errorObject = component.props.errors.find(
    (error: any) => error.attribute === attribute,
  );
  if (errorObject === undefined) {
    return null;
  }

  return errorObject.errors.map((error: any) => error.description);
}

export function formErrors(errorsPayload: any) {
  const errorsObject = {
    _error: errorsPayload.message,
  };

  if (errorsPayload.errors) {
    for (const error of errorsPayload.errors) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      errorsObject[error.field] = error.message;
    }
  }

  return errorsObject;
}

export function handleApiValidationErrors(errors: any) {
  const errorsObject = formErrors(errors);
  throw new SubmissionError(errorsObject);
}
