import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import React from 'react';

import styles from './Dot.module.scss';

interface IDot {
  className?: string;
  size?: string;
}

const Dot = (props: IDot) => {
  let sizeClass = styles.dotXs;
  if (props.size === 'sm') {
    sizeClass = styles.dotSm;
  } else if (props.size === 'md') {
    sizeClass = styles.dotMd;
  } else {
    sizeClass = styles.dotLg;
  }

  const classNames = cn(styles.dot, sizeClass, props.className);
  return <FontAwesomeIcon icon={faCircle} className={classNames} />;
};

export default Dot;
