import L from 'leaflet';
import { get } from 'lodash';
import { Resizable } from 're-resizable';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { Circle, FeatureGroup, Marker, Polygon, Popup } from 'react-leaflet';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../app/hooks';
import { isCan } from '../../helpers/permission';
import type {
  IDeviceModel,
  IGeozoneModel,
} from '../../interfaces/models.interfaces';
import type { IGeneralComponentProps } from '../../interfaces/props.interfaces';
import {
  fetch as fetchDevices,
  selectAll as selectAllDevices,
} from '../../slices/devices/devicesSlice';
import {
  destroy as destroyGeozone,
  destroyAll,
  fetch as fetchGeozones,
  selectAll as selectAllGeozones,
  update,
} from '../../slices/geozones/geozonesSlice';
import { setText } from '../../slices/notification/notificationSlice';
import { settingsSelector } from '../../slices/settings/settingsSlice';
import { coordsAsArray } from '../../utils/device';
import { onResizeStop } from '../../utils/resizable';
import Confirmation from '../confirmation/Confirmation';
import GeozonesListForm from '../geozones_list/GeozonesListForm';
import { Map } from '../map/Map';
import { DefaultCowMarker as CowMarker } from '../marker/Marker';
import MonitoringPopover from '../monitoring_popover/MonitoringPopover';
import Websockets from '../websockets/Websockets';
import styles from './Geozones.module.scss';
import GeozonesForm from './GeozonesForm';

interface IGeozoneFormProps {
  id: number | undefined;
  deviceGeozones: any[];
  name: string | undefined;
  type: 'circle' | 'polygon' | undefined;
  color: string | undefined;
}

const onGeozonesListSubmit = (
  setSelectedGeozonesIds: any,
  formData: any,
): void => {
  const result = [];
  const keys = Object.keys(formData);
  for (const key of keys) {
    if (formData[key]) {
      result.push(Number.parseInt(key.replace('geozoneId', ''), 10));
    }
  }

  setSelectedGeozonesIds(result);
};

const handleSelect = (setOpenedModel: any, model: IGeozoneModel | null) => {
  setOpenedModel(model);
};

const handleSubmitUpdate = (
  setOpenedModel: any,
  formValues: any,
  dispatch: any,
) => {
  dispatch(update(formValues)).then((data: any) => {
    if (!data.error) {
      setOpenedModel(null);
    }
  });
};

const handleRemove = (
  openedModel: any,
  setOpenedModel: any,
  dispatch: any,
  model: IGeozoneModel,
) => {
  dispatch(destroyGeozone(model)).then((data: any) => {
    if (!data.error && openedModel && openedModel.id === model.id) {
      setOpenedModel(false);
    }
  });
};

const drawGeozone = (geozone: IGeozoneModel) => {
  const color = geozone.color;

  if (geozone.type === 'circle') {
    const lat = get(geozone, 'data.center.lat');
    const lon = get(geozone, 'data.center.lon');
    const radius = get(geozone, 'data.radius');
    if (lat && lon && radius) {
      return (
        <Circle
          key={geozone.id}
          center={[lat, lon]}
          radius={radius}
          pathOptions={{
            color,
            opacity: 0.6,
            fillOpacity: 0.6,
          }}
          weight={0}
        />
      );
    }
  } else {
    return (
      <Polygon
        key={geozone.id}
        positions={geozone.data.coordinates}
        pathOptions={{
          color,
          opacity: 0.6,
          fillOpacity: 0.6,
        }}
        weight={0}
      />
    );
  }
};

const geozonesIds: any = (ids: number[]) => {
  const result: Record<string, any> = {};

  for (const id of ids) {
    result[`geozoneId${id}`] = true;
  }

  return result;
};

const Geozones: React.FC<IGeneralComponentProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const refMap = useRef<HTMLInputElement>(null);

  const [openedModel, setOpenedModel] = useState<IGeozoneModel | null>(null);
  const [selectedGeozonesIds, setSelectedGeozonesIds] = useState<number[]>([]);
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const [isCanRead, setIsCanRead] = useState<boolean>(false);
  const [isCanCreate, setIsCanCreate] = useState<boolean>(false);
  const [isCanUpdate, setIsCanUpdate] = useState<boolean>(false);
  const [isCanDestroy, setIsCanDestroy] = useState<boolean>(false);

  const { leftColumnSize }: any = useSelector(settingsSelector);
  const geozones = useSelector(selectAllGeozones);
  const { currentUser } = useSelector(settingsSelector);
  const selectedGeozones = geozones.filter((geozone: IGeozoneModel) =>
    selectedGeozonesIds.includes(geozone.id),
  );
  const devices = useSelector(selectAllDevices);

  useEffect(() => {
    setIsCanRead(isCan('read', 'Geozone', currentUser));
    setIsCanCreate(isCan('create', 'Geozone', currentUser));
    setIsCanUpdate(isCan('update', 'Geozone', currentUser));
    setIsCanDestroy(isCan('destroy', 'Geozone', currentUser));
  }, [currentUser]);

  useEffect(() => {
    void dispatch(fetchDevices({ type: 'tracker' }));
    void dispatch(fetchGeozones());

    L.drawLocal.draw.handlers.polygon.tooltip.start = t(
      'geozones.polygonStartDraw',
    );
    L.drawLocal.draw.handlers.polygon.tooltip.cont = t(
      'geozones.polygonContinueDraw',
    );
    L.drawLocal.draw.handlers.polygon.tooltip.end = t(
      'geozones.polygonStopDraw',
    );
    L.drawLocal.draw.handlers.circle.tooltip.start = t(
      'geozones.circleStartDraw',
    );
    L.drawLocal.draw.handlers.simpleshape.tooltip.end = t(
      'geozones.circleStopDraw',
    );
    L.drawLocal.draw.handlers.circle.radius = t('geozones.circleRadius');
  }, []);

  // set default all selected geozones
  useEffect(() => {
    setSelectedGeozonesIds(
      geozones.map((geozone: IGeozoneModel) => geozone.id),
    );
  }, [geozones]);

  let initialValues: IGeozoneFormProps = {
    id: undefined,
    deviceGeozones: [],
    name: undefined,
    type: 'polygon',
    color: '#fd397a',
  };
  if (openedModel && openedModel.id) {
    initialValues = {
      id: openedModel.id,
      deviceGeozones: openedModel.devices.map((item: any) => ({
        value: item.id,
        label: item.name,
      })),
      name: openedModel.name,
      type: openedModel.type,
      color: openedModel.color,
    };
  }

  return (
    <Websockets>
      <div className="resizable-wrapper">
        <Resizable
          enable={{
            top: false,
            right: true,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          defaultSize={leftColumnSize}
          onResizeStop={onResizeStop.bind(
            undefined,
            leftColumnSize,
            refMap.current,
          )}
          maxWidth="50%"
          minWidth="280"
          className={`${styles.leftColumn} left-column`}
        >
          <Scrollbars autoHide>
            {((isCanCreate && !initialValues.id) ||
              (isCanUpdate && initialValues.id)) && (
              <div className={styles.form}>
                {refMap.current && (
                  <>
                    <h2>
                      {t(`geozones.${initialValues.id ? 'edit' : 'new'}Title`)}
                    </h2>
                    <GeozonesForm
                      refMap={refMap}
                      devices={devices}
                      handleUpdateCancel={handleSelect.bind(
                        undefined,
                        setOpenedModel,
                      )}
                      handleSubmitUpdate={handleSubmitUpdate.bind(
                        undefined,
                        setOpenedModel,
                      )}
                      initialValues={initialValues}
                      canCreate={isCanCreate}
                    />
                    <Confirmation
                      isShow={isShowConfirmModal}
                      onConfirm={async () => {
                        try {
                          await dispatch(destroyAll());
                          dispatch(setText(t('shared.notification.removed')));
                          setIsShowConfirmModal(false);
                        } catch {
                          // nothing to do
                        }
                      }}
                      onHide={() => {
                        setIsShowConfirmModal(false);
                      }}
                    />
                  </>
                )}
              </div>
            )}
            {isCanRead && (
              <div className={styles.tracksList}>
                <div className="clearfix">
                  <div className="float-start">
                    <h2 className={styles.tracksListTitle}>
                      {t('geozones.title')}
                    </h2>
                  </div>
                  <div className="float-right">
                    <div className={styles.tracksListButton}>
                      {geozones.length > 0 &&
                        isCan('destroy', 'Geozone', currentUser) && (
                          <Button
                            variant="danger"
                            onClick={() => setIsShowConfirmModal(true)}
                          >
                            {t('geozones.removeAll')}
                          </Button>
                        )}
                    </div>
                  </div>
                </div>
                <GeozonesListForm
                  initialValues={geozonesIds(selectedGeozonesIds)}
                  onSubmit={onGeozonesListSubmit.bind(
                    undefined,
                    setSelectedGeozonesIds,
                  )}
                  openedModel={openedModel}
                  handleSelect={handleSelect.bind(undefined, setOpenedModel)}
                  handleRemove={handleRemove.bind(
                    undefined,
                    openedModel,
                    setOpenedModel,
                    dispatch,
                  )}
                  geozones={geozones}
                  canUpdate={isCanUpdate}
                  canDestroy={isCanDestroy}
                />
              </div>
            )}
          </Scrollbars>
        </Resizable>
        <div className="right-column">
          <Map ref={refMap}>
            <>
              <FeatureGroup>
                {selectedGeozones.length > 0 &&
                  selectedGeozones.map((geozone: IGeozoneModel) =>
                    drawGeozone(geozone),
                  )}
              </FeatureGroup>
              {devices.length > 0 &&
                devices.map((device: IDeviceModel) => {
                  const coords = coordsAsArray(device);
                  if (coords) {
                    return (
                      <Marker
                        key={device.id}
                        icon={CowMarker}
                        position={[coords[0], coords[1]]}
                      >
                        <Popup closeButton={false}>
                          {MonitoringPopover({
                            name: device.name,
                            number: device.number,
                            geozones: device.geozones,
                            eventsCount: device.events
                              ? device.events.length
                              : 0,
                            deviceData: device.deviceData[0],
                            t,
                          })}
                        </Popup>
                      </Marker>
                    );
                  }
                })}
            </>
          </Map>
        </div>
      </div>
    </Websockets>
  );
};

export default Geozones;
