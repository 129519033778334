// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, submit } from 'redux-form';

import MessagesChartConfigItem from '../messages_chart_config_item/MessagesChartConfigItem';

interface IMessagesChartConfigProps {
  initialValues: Record<string, any>;
  onSubmit: any;
}

const MessagesChartConfig: React.FC<
  InjectedFormProps<Record<string, boolean>, IMessagesChartConfigProps> &
    IMessagesChartConfigProps
> = (props: IMessagesChartConfigProps) => {
  const { t } = useTranslation();
  const { initialValues, onSubmit } = props;
  return (
    <div>
      <p>
        <b>{t('messagesChartConfig.title')}</b>
      </p>
      <Accordion>
        <form onSubmit={onSubmit}>
          {Object.keys(initialValues).map((key: string, index: number) => (
            <MessagesChartConfigItem
              key={key}
              index={index}
              initialValues={initialValues[key]}
              namePrefix={key}
            />
          ))}
        </form>
      </Accordion>
    </div>
  );
};

export default reduxForm<Record<string, boolean>, IMessagesChartConfigProps>({
  form: 'messageChartForm',
  enableReinitialize: true,
  onChange: (_, dispatch: any) => {
    dispatch(submit('messageChartForm'));
  },
})(MessagesChartConfig);
