import type { Size } from 're-resizable';
import store from 'store';

import type { IAuthTokens, IUserModel } from '../interfaces/interfaces';

export function getAuthToken(): string | null {
  return store.get('authToken');
}

export function getAuthRefreshToken(): string | null {
  return store.get('authRefreshToken');
}

export function saveAuthTokens(authToken: IAuthTokens): void {
  store.set('authToken', authToken.token);
  store.set('authRefreshToken', authToken.refreshToken);
}

export function clearAuthTokens(): void {
  store.remove('authToken');
  store.remove('authRefreshToken');
}

export function getCurrentUser(): IUserModel | null {
  return store.get('currentUser');
}

export function saveCurrentUser(user: IUserModel): void {
  store.set('currentUser', user);
}

export function getLeftColumnSize(): Record<string, number> {
  return store.get('leftColumnSize') || { width: 350, height: '100%' };
}

export function saveLeftColumnSize(size: Size): void {
  store.set('leftColumnSize', size);
}

export function getMapCoordinates(): number[] {
  return store.get('mapCoordinates') || [60, 60];
}

export function saveMapCoordinates(coordinates: number[]): void {
  store.set('mapCoordinates', coordinates);
}

export function getMapZoom(): number {
  return store.get('mapZoom') || 13;
}

export function saveMapZoom(zoom: number): void {
  store.set('mapZoom', zoom);
}

export function getMapTileName(): string {
  return store.get('mapTileName') || 'Google.Gybr';
}

export function saveMapTileName(name: string): void {
  store.set('mapTileName', name);
}
