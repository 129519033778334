// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import type { IAuthRouteProps } from '../../interfaces/interfaces';
import AuthLayout from '../../layouts/auth_layout/AuthLayout';
import { settingsSelector } from '../../slices/settings/settingsSlice';

export const AuthRoute: React.FC<IAuthRouteProps> = ({
  path: path,
  component: Component,
}) => {
  const { token } = useSelector(settingsSelector);
  return (
    <Route
      path={path}
      exact={true}
      render={(props: any) =>
        token ? (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        ) : (
          <AuthLayout>
            <Component {...props} />
          </AuthLayout>
        )
      }
    />
  );
};

export default AuthRoute;
