import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';

import { errorFor } from '../../utils/formError';
import Checkbox from '../checkbox/Checkbox';
import Input from '../input/Input';

interface IUserFormProps {
  onSubmit: any;
  initialValues: Record<string, any>;
  onCancel?: any;
  handleSubmit?: any;
  errors?: any[];
  isShowPermission?: boolean;
}

const UserForm: React.FC<
  InjectedFormProps<Record<string, any>, IUserFormProps> & IUserFormProps
> = (props: IUserFormProps) => {
  const { t } = useTranslation();
  const { onCancel, onSubmit, handleSubmit, errors, isShowPermission } = props;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Row}>
          <label htmlFor="name" className="col-sm-4 col-form-label">
            {t('models.user.name')}:
          </label>
          <div className="col-sm-8">
            <Input
              component="input"
              name="name"
              type="text"
              className="form-control-sm"
              id="name"
              error={errorFor(errors, 'user.name')}
              required
            />
          </div>
        </Form.Group>
        <Form.Group as={Row}>
          <label htmlFor="email" className="col-sm-4 col-form-label">
            {t('models.user.email')}:
          </label>
          <div className="col-sm-8">
            <Input
              component="input"
              name="email"
              type="text"
              className="form-control-sm"
              id="email"
              error={errorFor(errors, 'user.email')}
              required
            />
          </div>
        </Form.Group>
        <Form.Group as={Row}>
          <label htmlFor="password" className="col-sm-4 col-form-label">
            {t('models.user.password')}:
          </label>
          <div className="col-sm-8">
            <Input
              component="input"
              name="password"
              type="password"
              className="form-control-sm"
              id="password"
              error={errorFor(errors, 'user.password')}
            />
          </div>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <label
            htmlFor="confirmationPassword"
            className="col-sm-4 col-form-label"
          >
            {t('models.user.confirmationPassword')}:
          </label>
          <div className="col-sm-8">
            <Input
              component="input"
              name="confirmationPassword"
              type="password"
              className="form-control-sm"
              id="confirmationPassword"
            />
          </div>
        </Form.Group>
        {isShowPermission && (
          <div>
            <h2>{t('permissions.title')}</h2>
            <h4>{t('permissions.devices.title')}</h4>
            <Row>
              <Col>
                <Checkbox
                  className="mb-4"
                  name="permission[rules][Device][read]"
                  label={t('permissions.devices.read')}
                />
              </Col>
              <Col>
                <Checkbox
                  className="mb-4"
                  name="permission[rules][Device][create]"
                  label={t('permissions.devices.create')}
                />
              </Col>
              <Col>
                <Checkbox
                  className="mb-4"
                  name="permission[rules][Device][update]"
                  label={t('permissions.devices.update')}
                />
              </Col>
              <Col>
                <Checkbox
                  className="mb-4"
                  name="permission[rules][Device][destroy]"
                  label={t('permissions.devices.destroy')}
                />
              </Col>
            </Row>
            <h4>{t('permissions.baseStations.title')}</h4>
            <Row>
              <Col>
                <Checkbox
                  className="mb-4"
                  name="permission[rules][BaseStation][read]"
                  label={t('permissions.baseStations.read')}
                />
              </Col>
              <Col>
                <Checkbox
                  className="mb-4"
                  name="permission[rules][BaseStation][create]"
                  label={t('permissions.baseStations.create')}
                />
              </Col>
              <Col>
                <Checkbox
                  className="mb-4"
                  name="permission[rules][BaseStation][update]"
                  label={t('permissions.baseStations.update')}
                />
              </Col>
              <Col>
                <Checkbox
                  className="mb-4"
                  name="permission[rules][BaseStation][destroy]"
                  label={t('permissions.baseStations.destroy')}
                />
              </Col>
            </Row>
            <h4>{t('permissions.deviceData.title')}</h4>
            <Row>
              <Col>
                <Checkbox
                  className="mb-4"
                  name="permission[rules][DeviceData][read]"
                  label={t('permissions.deviceData.read')}
                />
              </Col>
            </Row>
            <h4>{t('permissions.geozones.title')}</h4>
            <Row>
              <Col>
                <Checkbox
                  className="mb-4"
                  name="permission[rules][Geozone][read]"
                  label={t('permissions.geozones.read')}
                />
              </Col>
              <Col>
                <Checkbox
                  className="mb-4"
                  name="permission[rules][Geozone][create]"
                  label={t('permissions.geozones.create')}
                />
              </Col>
              <Col>
                <Checkbox
                  className="mb-4"
                  name="permission[rules][Geozone][update]"
                  label={t('permissions.geozones.update')}
                />
              </Col>
              <Col>
                <Checkbox
                  className="mb-4"
                  name="permission[rules][Geozone][destroy]"
                  label={t('permissions.geozones.destroy')}
                />
              </Col>
            </Row>
            <h4>{t('permissions.events.title')}</h4>
            <Row>
              <Col>
                <Checkbox
                  className="mb-4"
                  name="permission[rules][Event][read]"
                  label={t('permissions.events.read')}
                />
              </Col>
              <Col>
                <Checkbox
                  className="mb-4"
                  name="permission[rules][Event][update]"
                  label={t('permissions.events.update')}
                />
              </Col>
            </Row>
            <h4>{t('permissions.users.title')}</h4>
            <Row>
              <Col>
                <Checkbox
                  className="mb-4"
                  name="permission[rules][User][read]"
                  label={t('permissions.users.read')}
                />
              </Col>
              <Col>
                <Checkbox
                  className="mb-4"
                  name="permission[rules][User][create]"
                  label={t('permissions.users.create')}
                />
              </Col>
              <Col>
                <Checkbox
                  className="mb-4"
                  name="permission[rules][User][update]"
                  label={t('permissions.users.update')}
                />
              </Col>
              <Col>
                <Checkbox
                  className="mb-4"
                  name="permission[rules][User][destroy]"
                  label={t('permissions.users.destroy')}
                />
              </Col>
            </Row>
          </div>
        )}

        <Button size="sm" type="submit" variant="primary">
          {t('shared.form.button.save')}
        </Button>
        {onCancel && (
          <Button
            size="sm"
            type="button"
            variant="warning"
            onClick={onCancel}
            className="ms-2"
          >
            {t('shared.form.button.cancel')}
          </Button>
        )}
      </form>
    </>
  );
};

export default reduxForm<Record<string, any>, IUserFormProps>({
  form: 'user',
  enableReinitialize: true,
})(UserForm);
