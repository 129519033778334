import type { IBaseStationModel } from '../interfaces/models.interfaces';

export const typesOptions = [{ value: 'standart', label: 'Стандартный' }];

export const sortedEntities = (
  baseStations: IBaseStationModel[],
  sortDirection: string,
) =>
  sortDirection === 'up'
    ? baseStations.sort((a: IBaseStationModel, b: IBaseStationModel) =>
        a.name > b.name ? 1 : -1,
      )
    : baseStations.sort((a: IBaseStationModel, b: IBaseStationModel) =>
        b.name > a.name ? 1 : -1,
      );
