import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import type { RootState } from '../../app/store';
import type {
  IDeviceDataModel,
  IDeviceDataResponseData,
} from '../../interfaces/interfaces';
import { fetchRequest, findRequest } from './deviceDataApi';

export const fetch = createAsyncThunk(
  'deviceData/fetch',
  async (data: any, thunkAPI) => {
    try {
      const response = await fetchRequest({
        deviceId: data.deviceId,
        fromTime: data.fromTime,
        toTime: data.toTime,
        page: data.page,
        download: data.download,
      });
      const result: IDeviceDataResponseData = await response.data;
      return result.deviceData;
    } catch {
      return thunkAPI.rejectWithValue('Sorry. something wrong1');
    }
  },
);

export const findAndMerge = createAsyncThunk(
  'deviceData/findAndMerge',
  async (id: number, thunkAPI) => {
    try {
      const response = await findRequest(id);
      const data: IDeviceDataResponseData = await response.data;
      return data.deviceData;
    } catch {
      return thunkAPI.rejectWithValue('Sorry. something wrong1');
    }
  },
);

// eslint-disable-next-line @typescript-eslint/unbound-method
const { addOne, getInitialState, getSelectors, setAll } =
  createEntityAdapter<IDeviceDataModel>();

export const deviceDataSlice = createSlice({
  name: 'deviceData',
  initialState: getInitialState({
    errors: [],
  }),
  reducers: {
    addOneItem: (state: any, { payload }) => {
      addOne(state, payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetch.fulfilled, (state: any, { payload }: any) => {
        setAll(state, payload);
      })
      .addCase(findAndMerge.fulfilled, (state: any, { payload }: any) => {
        addOne(state, payload);
      });
  },
});

export const { addOneItem } = deviceDataSlice.actions;
export const { selectAll, selectById } = getSelectors(
  (state: RootState) => state.deviceData,
);

export const select = (id: number | null) =>
  createSelector(
    (state: RootState) => state,
    (state: any) => (id ? selectById(state, id) : null),
  );

export default deviceDataSlice.reducer;
