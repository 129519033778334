// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import 'chartjs-adapter-moment';

import { faCog, faTh } from '@fortawesome/free-solid-svg-icons';
// import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
// import { get } from 'lodash';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import type { IDeviceDataModel } from '../../interfaces/models.interfaces';
import { selectAllDataPoints } from '../../slices/messages/messagesSlice';
import MessagesTrigger from '../messages_trigger/MessagesTrigger';
import MessagesChartForm from './MessagesChartForm';

interface IMessagesChartProps {
  active: string;
  className?: string;
  chartConfig: any;
  isEnabledChartConfig: any;
  handleChangeType: any;
  handleToggleConfig: any;
  position: any;
}

const handleToggleGridConfig = (
  isEnabledChartGridConfig: boolean,
  setIsEnabledChartGridConfig: any,
) => {
  setIsEnabledChartGridConfig(!isEnabledChartGridConfig);
};

const handleClose = (setIsEnabledChartGridConfig: any) => {
  setIsEnabledChartGridConfig(false);
};

const handleFormSubmit = (
  setChartGridConfig: any,
  setIsEnabledChartGridConfig: any,
  formData: any,
) => {
  setChartGridConfig(formData);
  handleClose(setIsEnabledChartGridConfig);
};

const MessagesChart = (props: IMessagesChartProps) => {
  const { t } = useTranslation();
  const {
    chartConfig,
    position,
    active,
    handleChangeType,
    className,
    isEnabledChartConfig,
    handleToggleConfig,
  } = props;

  const messages = useSelector(selectAllDataPoints);
  const [chartGridConfig, setChartGridConfig] = useState<Record<string, any>>({
    lineThickness: { value: 1, label: '1px' },
    lineStyle: { value: 'solid', label: 'Сплошная' },
    color: '#fd397a',
    step: 'day',
  });
  const [isEnabledChartGridConfig, setIsEnabledChartGridConfig] =
    useState<boolean>(false);

  interface IChartData {
    labels: Date[];
    datasets: any[];
  }

  const data: IChartData = {
    labels: messages.map(
      (deviceData: IDeviceDataModel) => new Date(deviceData.createdAt),
    ),
    datasets: [],
  };

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
        },
        grid: {
          lineWidth: chartGridConfig.lineThickness.value,
          color: chartGridConfig.color,
          borderDash: [
            10,
            chartGridConfig.lineStyle.value === 'dashed' ? 7 : 0,
          ],
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    animation: {
      duration: 0,
    },
  };

  for (const key of Object.keys(chartConfig)) {
    const index = Object.keys(chartConfig).indexOf(key);
    if (chartConfig[key].active) {
      let datasetsData = [];

      switch (key) {
        case 'battery':
          datasetsData = messages.map((item: any) => item.bat);
          break;
        case 'temperature':
          datasetsData = messages.map((item: any) => item.tmp);
          break;
        case 'packageId':
          datasetsData = messages.map((item: any) => item.pid);
          break;
        case 'freq':
          datasetsData = messages.map((item: any) => item.frq);
          break;
        case 'rssi':
          datasetsData = messages.map((item: any) => item.rssi);
          break;
        case 'snr':
          datasetsData = messages.map((item: any) => item.snr);
          break;
        case 'sc':
          datasetsData = messages.map((item: any) => item.sc);
          break;
        case 'snr_min':
          datasetsData = messages.map((item: any) => item.snrmn);
          break;
        case 'snr_max':
          datasetsData = messages.map((item: any) => item.snrmx);
          break;
        case 'activity_low':
          datasetsData = messages.map((item: any) => item.acl);
          break;
        case 'activity_high':
          datasetsData = messages.map((item: any) => item.ach);
          break;
        case 'activity_max':
          datasetsData = messages.map((item: any) => item.acm);
          break;
        case 'activity_avg':
          datasetsData = messages.map((item: any) => item.aca);
          break;
        default:
          datasetsData = [];
          break;
      }

      data.datasets.push({
        label: key,
        data: datasetsData,
        fill: false,
        borderWidth: chartConfig[key].lineThickness,
        backgroundColor: chartConfig[key].color,
        borderColor: chartConfig[key].color,
        yAxisID: `y${index}`,
      });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options.scales[`y${index}`] = {
        type: 'linear',
        display: true,
        position: index % 2 === 0 ? 'left' : 'right',
        grid: {
          lineWidth: 0,
        },
      };

      if (index === 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        options.scales[`y${index}`].grid = {
          lineWidth: chartGridConfig.lineThickness.value,
          color: chartGridConfig.color,
          borderDash: [
            10,
            chartGridConfig.lineStyle.value === 'dashed' ? 7 : 0,
          ],
        };
      }
    }
  }

  return (
    <div className={className} style={{ maxWidth: '100%' }}>
      <div className="messages-chart-graph">
        <Line
          className="messages-line"
          height="100%"
          width="100%"
          data={data}
          options={options}
        />
      </div>
      <div className="messages-tools row">
        <div className="col-6 text-start">
          <Button
            className={cn('btn btn-messages btn-icon me-2', {
              'btn-primary': isEnabledChartConfig,
            })}
            onClick={handleToggleConfig}
          >
            <FontAwesomeIcon icon={faCog} />
          </Button>
          <Button
            className={cn('btn btn-messages btn-icon', {
              'btn-primary': isEnabledChartGridConfig,
            })}
            onClick={handleToggleGridConfig.bind(
              undefined,
              isEnabledChartGridConfig,
              setIsEnabledChartGridConfig,
            )}
          >
            <FontAwesomeIcon icon={faTh} />
          </Button>
          <Modal
            show={isEnabledChartGridConfig}
            onHide={handleClose.bind(undefined, setIsEnabledChartGridConfig)}
          >
            <Modal.Header>
              <Modal.Title as="h2">{t('messagesChart.params')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {chartGridConfig && (
                <MessagesChartForm
                  handleFormSubmit={handleFormSubmit.bind(
                    undefined,
                    setChartGridConfig,
                    setIsEnabledChartGridConfig,
                  )}
                  handleClose={handleClose.bind(
                    undefined,
                    setIsEnabledChartGridConfig,
                  )}
                  initialValues={chartGridConfig}
                />
              )}
            </Modal.Body>
          </Modal>
        </div>
        <div className="col-6 text-end">
          <MessagesTrigger
            active={active}
            handleChangeType={handleChangeType}
            position={position}
          />
        </div>
      </div>
    </div>
  );
};

export default MessagesChart;
