import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface IDatePeriodButtonProps {
  onTodayDateRangeHandle: any;
  onYesterdayDateRangeHandle: any;
  onLast24DateRangeHandle: any;
  onLast3DaysDateRangeHandle: any;
  onLastWeekDateRangeHandle: any;
}

const DatePeriodButtons = (props: IDatePeriodButtonProps) => {
  const { t } = useTranslation();

  return (
    <Form.Group>
      <Button
        className="me-2 mb-2"
        variant="warning"
        onClick={props.onTodayDateRangeHandle}
      >
        {t('datePeriodButtons.todayDate')}
      </Button>
      <Button
        className="me-2 mb-2"
        variant="warning"
        onClick={props.onYesterdayDateRangeHandle}
      >
        {t('datePeriodButtons.yesterdayDate')}
      </Button>
      <Button
        className="me-2 mb-2"
        variant="warning"
        onClick={props.onLast24DateRangeHandle}
      >
        {t('datePeriodButtons.last24Date')}
      </Button>
      <Button
        className="me-2 mb-2"
        variant="warning"
        onClick={props.onLast3DaysDateRangeHandle}
      >
        {t('datePeriodButtons.last3Days')}
      </Button>
      <Button
        className="mb-2"
        variant="warning"
        onClick={props.onLastWeekDateRangeHandle}
      >
        {t('datePeriodButtons.lastWeek')}
      </Button>
    </Form.Group>
  );
};

export default DatePeriodButtons;
