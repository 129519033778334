import type { AxiosResponse } from 'axios';

import { destroy, get, patch, post } from '../../helpers/axios';

export async function fetchRequest(): Promise<AxiosResponse<any>> {
  return get('users');
}

export async function createRequest(
  formData: any,
): Promise<AxiosResponse<any>> {
  return post('users', { user: formData });
}

export async function updateRequest(
  formData: any,
): Promise<AxiosResponse<any>> {
  return patch(`users/${formData.id}`, { user: formData });
}

export async function destroyRequest(id: number): Promise<AxiosResponse<any>> {
  return destroy(`users/${id}`);
}
