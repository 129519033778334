import React from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../app/hooks';
import { Header } from '../../components/header/Header';
import Notificatation from '../../components/notification/Notificatation';
import type { IMapLayoutProps } from '../../interfaces/interfaces';
import {
  clearText,
  selectText,
} from '../../slices/notification/notificationSlice';
import styles from './MapLayout.module.scss';

const MapLayout: React.FunctionComponent<IMapLayoutProps> = ({
  children,
}: IMapLayoutProps) => {
  const dispatch = useAppDispatch();
  const notificationText = useSelector(selectText);

  return (
    <>
      <Header />
      <div className={styles.wrapper}>
        {children}
        <Notificatation
          isShow={!!notificationText}
          type="info"
          text={notificationText}
          onClose={() => dispatch(clearText())}
        />
      </div>
    </>
  );
};

export default MapLayout;
