// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import {
  //faCog,
  faEye,
  faEyeSlash,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';

import { useAppDispatch } from '../../app/hooks';
import type { ITrackModel } from '../../interfaces/models.interfaces';
import { update } from '../../slices/tracks/tracksSlice';

interface ITracksListItemProps {
  track: ITrackModel;
  onRemove: any;
  onEdit: any;
}

const TracksListItem = (props: ITracksListItemProps) => {
  const { track, onRemove } = props;
  const dispatch = useAppDispatch();

  return (
    <div className="list-item">
      <div className="list-item-header">
        <Button
          variant="primary"
          className="btn-icon btn-tool me-2"
          onClick={() =>
            dispatch(update({ id: track.id, selected: !track.selected }))
          }
        >
          <FontAwesomeIcon icon={track.selected ? faEye : faEyeSlash} />
        </Button>
        <span className="list-item-name">{track.name}</span>
        {/*<Button*/}
        {/*  variant="primary"*/}
        {/*  className="btn-icon btn-tool ms-auto"*/}
        {/*  onClick={onEdit}*/}
        {/*>*/}
        {/*  <FontAwesomeIcon icon={faCog} />*/}
        {/*</Button>*/}
        <Button
          variant="danger"
          className="btn-icon btn-tool ms-2 ms-auto"
          onClick={onRemove}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
        <div className="list-item-corner" />
      </div>
    </div>
  );
};

export default TracksListItem;
