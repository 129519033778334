import 'leaflet-draw';

import L from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm, reset } from 'redux-form';

import { useAppDispatch } from '../../app/hooks';
import type { IDeviceModel } from '../../interfaces/models.interfaces';
import { create } from '../../slices/geozones/geozonesSlice';
import { setText } from '../../slices/notification/notificationSlice';
import ColorInput from '../color_input/ColorInput';
import Input from '../input/Input';
import Radiobox from '../radiobox/Radiobox';
import Selectbox from '../selectbox/Selectbox';

interface ITracksFormProps {
  isDrawMode: boolean;
  initialValues: any;
  devices: IDeviceModel[];
  handleUpdateCancel: any;
  handleSubmitUpdate: any;
  className?: string;
  refMap: any;
  canCreate: boolean;
}

const drawShapeOptions = (color: any) => ({
  color,
  weight: 1,
  fillOpacity: 0.6,
  opacity: 0.6,
});

const closeDrawingState = (setIsDrawMode: any) => {
  setIsDrawMode(false);
};

const geozoneDataParams = (type: any, e: any) => {
  const layer = e.layer;
  if (type === 'circle') {
    return {
      radius: layer.options.radius,
      center: { lat: layer._latlng.lat, lon: layer._latlng.lng },
    };
  } else if (type === 'polygon') {
    return {
      coordinates: layer._latlngs[0].map((item: any) => [item.lat, item.lng]),
    };
  }
};

const handleStartDrawing = (type: string, setIsDrawMode: any) => {
  const event = document.createEvent('HTMLEvents');
  event.initEvent('click', true, true);
  if (type === 'circle') {
    document
      .getElementsByClassName('leaflet-draw-draw-circle')[0]
      .dispatchEvent(event);
    setIsDrawMode(true);
  } else if (type === 'polygon') {
    document
      .getElementsByClassName('leaflet-draw-draw-polygon')[0]
      .dispatchEvent(event);
    setIsDrawMode(true);
  }
};

const clickToStopDrawing = () => {
  const event = document.createEvent('HTMLEvents');
  event.initEvent('click', true, true);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  document.querySelector('[title="Cancel drawing"]').dispatchEvent(event);
};

const handleOnStopDraw = (e: any) => {
  e.preventDefault();
  clickToStopDrawing();
};

let GeozonesForm: any = (props: any) => {
  const { t } = useTranslation();
  const {
    className,
    devices,
    handleSubmit,
    refMap,
    name,
    color,
    type,
    deviceGeozones,
    initialValues,
    handleUpdateCancel,
    handleSubmitUpdate,
    canCreate,
  } = props;
  const isFormDisabled = !name || !color || !type;
  const [drawControl, setDrawControl] = useState<any>(null);
  const [isDrawMode, setIsDrawMode] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const devicesOptions = devices.map((device: IDeviceModel) => ({
    value: device.id,
    label: device.name,
  }));

  const currentState = () => ({
    drawControl,
    isDrawMode,
    name,
    type,
    color,
    deviceGeozones,
  });

  const currentStateRef = useRef(currentState);

  useEffect(() => {
    currentStateRef.current = currentState;
  });

  // fetch devices
  // useEffect(() => {
  //   void dispatch(fetchDevices());
  // }, []);

  useEffect(() => {
    if (refMap.current) {
      let currentStateValuesFunc = () => currentStateRef.current();
      let currentStateValues = currentStateValuesFunc();
      const editableLayers = new L.FeatureGroup();
      refMap.current.addLayer(editableLayers);

      const drawControlObject = new L.Control.Draw({
        position: 'topleft',
        draw: {
          polyline: false,
          rectangle: false,
          polygon: {
            shapeOptions: drawShapeOptions(currentStateValues.color),
          },
          circle: {
            shapeOptions: drawShapeOptions(currentStateValues.color),
          },
        },
        edit: {
          featureGroup: new L.FeatureGroup(),
          edit: false,
        },
        // remove: false,
      });
      refMap.current.addControl(drawControlObject);

      refMap.current.on('draw:created', (e: any) => {
        // eslint-disable-next-line unicorn/consistent-function-scoping
        currentStateValuesFunc = () => currentStateRef.current();
        currentStateValues = currentStateValuesFunc();
        editableLayers.addLayer(e.layer);
        const params = {
          name: currentStateValues.name,
          color: currentStateValues.color,
          type: currentStateValues.type,
          deviceGeozones: currentStateValues.deviceGeozones,
          data: geozoneDataParams(currentStateValues.type, e),
        };
        void dispatch(create(params)).then((data: any) => {
          closeDrawingState(setIsDrawMode);

          if (!data.error) {
            dispatch(reset('geozone'));
            dispatch(setText(t('shared.notification.created')));
          }
        });
        editableLayers.removeLayer(e.layer);
      });

      refMap.current.on('draw:drawstop', () => {
        closeDrawingState(setIsDrawMode);
      });

      setDrawControl(drawControlObject);
      // initDrawTool(refMap.current, state, setState, formValues, dispatch);
    }
  }, [refMap.current]);

  useEffect(() => {
    if (drawControl) {
      const currentStateValuesFunc = () => currentStateRef.current();
      const currentStateValues = currentStateValuesFunc();
      drawControl.setDrawingOptions({
        polygon: {
          shapeOptions: drawShapeOptions(currentStateValues.color),
        },
        circle: {
          shapeOptions: drawShapeOptions(currentStateValues.color),
        },
      });
      if (isDrawMode) {
        clickToStopDrawing();
      }
    }
    // setState({ ...state, formValues });
  }, [name, color, type, deviceGeozones]);

  const handleSubmitForm = initialValues.id
    ? handleSubmitUpdate
    : handleStartDrawing.bind(undefined, type, setIsDrawMode);

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)} className={className}>
      <Form.Group as={Row} className="mt-4">
        <Input
          component="input"
          name="name"
          type="text"
          className="form-control"
          placeholder={t('geozonesForm.name')}
          id="name"
        />
      </Form.Group>
      <p className="mb-1">
        <b>{t('geozonesForm.devices')}</b>
      </p>
      <Form.Group as={Row}>
        <Selectbox
          isMulti
          id="deviceGeozones"
          options={devicesOptions}
          name="deviceGeozones"
        />
      </Form.Group>
      {!initialValues.id && (
        <>
          <p>
            <b>{t('geozonesForm.type')}</b>
          </p>
          <Form.Group as={Row} className="mb-3">
            <div className="col-sm-5">
              <Radiobox
                name="type"
                value="polygon"
                checked={type === 'polygon'}
                label={t('geozonesForm.polygon')}
              />
            </div>
            <div className="col-sm-7">
              <Radiobox
                name="type"
                value="circle"
                checked={type === 'circle'}
                label={t('geozonesForm.circle')}
              />
            </div>
          </Form.Group>
        </>
      )}
      <Form.Group as={Row} className="mb-4">
        <label htmlFor="thickness" className="col-sm-5 col-form-label">
          {t('geozonesForm.color')}:
        </label>
        <div className="col-sm-7">
          <ColorInput name="color" color={color} />
        </div>
      </Form.Group>
      <Form.Group className="text-end">
        {initialValues.id && (
          <>
            <Button type="submit" variant="primary" disabled={isFormDisabled}>
              {t('shared.form.button.save')}
            </Button>
            {canCreate && (
              <Button
                type="button"
                variant="warning"
                className="ms-2"
                onClick={handleUpdateCancel}
              >
                {t('geozonesForm.new')}
              </Button>
            )}
          </>
        )}
        {isDrawMode && !initialValues.id && (
          <Button type="button" variant="warning" onClick={handleOnStopDraw}>
            {t('shared.form.button.cancel')}
          </Button>
        )}
        {!isDrawMode && !initialValues.id && (
          <Button type="submit" variant="primary" disabled={isFormDisabled}>
            {t('geozonesForm.draw')}
          </Button>
        )}
      </Form.Group>
    </form>
  );
};

GeozonesForm = reduxForm<Record<string, boolean>, ITracksFormProps>({
  form: 'geozone',
  enableReinitialize: true,
})(GeozonesForm);

const selector = formValueSelector('geozone');
GeozonesForm = connect((state: any) => {
  const { name, type, color, deviceGeozones } = selector(
    state,
    'name',
    'type',
    'color',
    'deviceGeozones',
  );
  return { name, type, color, deviceGeozones };
})(GeozonesForm);

export default GeozonesForm;
