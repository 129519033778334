import { faChartBar, faList, faMap } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';
import { Button } from 'react-bootstrap';

interface IMessagesTriggerProps {
  active: string;
  handleChangeType: any;
  position: any;
}

const MessagesTrigger = (props: IMessagesTriggerProps) => {
  const { active, position, handleChangeType } = props;
  const commonClassName = 'btn-icon btn-messages ms-2';
  const chartClassName = cn(commonClassName, {
    active: active === 'chart',
  });
  const mapClassName = cn(commonClassName, {
    active: active === 'map',
  });
  const tableClassName = cn(commonClassName, {
    active: active === 'table',
  });

  return (
    <div className="messages-trigger">
      <Button
        className={chartClassName}
        onClick={handleChangeType.bind(undefined, 'chart', position)}
      >
        <FontAwesomeIcon icon={faChartBar} />
      </Button>
      <Button
        className={mapClassName}
        onClick={handleChangeType.bind(undefined, 'map', position)}
      >
        <FontAwesomeIcon icon={faMap} />
      </Button>
      <Button
        className={tableClassName}
        onClick={handleChangeType.bind(undefined, 'table', position)}
      >
        <FontAwesomeIcon icon={faList} />
      </Button>
    </div>
  );
};

export default MessagesTrigger;
