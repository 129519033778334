import type { AxiosResponse } from 'axios';

import { destroy, get, patch, post } from '../../helpers/axios';

export async function fetchRequest(props: any): Promise<AxiosResponse<any>> {
  let query = '?active=true';
  if (props.type) {
    query += `&type=${props.type}`;
  }
  return get(`devices${query}`);
}

export async function fetchMonitoringRequest(): Promise<AxiosResponse<any>> {
  return get('devices/monitoring');
}

export async function createRequest(
  formData: any,
): Promise<AxiosResponse<any>> {
  let createdFormData = formData;
  if (
    createdFormData.deviceGeozones &&
    createdFormData.deviceGeozones.length > 0
  ) {
    createdFormData = {
      ...formData,
      deviceGeozones: formData.deviceGeozones.map((item: any) => item.value),
    };
  }

  return post('devices', { device: createdFormData });
}

export async function updateRequest(
  formData: any,
): Promise<AxiosResponse<any>> {
  let updatedFormData = formData;
  if (
    updatedFormData.deviceGeozones &&
    updatedFormData.deviceGeozones.length > 0
  ) {
    updatedFormData = {
      ...formData,
      deviceGeozones: formData.deviceGeozones.map((item: any) => item.value),
    };
  }

  return patch(`devices/${formData.id}`, {
    device: updatedFormData,
  });
}

export async function destroyRequest(id: number): Promise<AxiosResponse<any>> {
  return destroy(`devices/${id}`);
}
