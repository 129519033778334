import cn from 'classnames';
import React from 'react';

import Dot from '../dot/Dot';
const BaseStationOnline = ({ className }: any) => {
  const classNames = cn('text-secondary', className);
  return <Dot className={classNames} />;
};

export default BaseStationOnline;
