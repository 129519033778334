import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';

import { useAppDispatch } from '../../app/hooks';
import type { IDeviceModel } from '../../interfaces/models.interfaces';
import {
  fetch as fetchDevices,
  selectAll as selectAllDevices,
} from '../../slices/devices/devicesSlice';
import DatePeriodButtons from '../date_period_buttons/DatePeriodButtons';
import DateTimeInput from '../date_time_input/DateTimeInput';
import Selectbox from '../selectbox/Selectbox';

interface IMessagesFormProps {
  onSubmit: any;
  initialValues: any;
  className?: string;
}

const onTodayDateRangeHandle = (dispatch: any) => {
  dispatch(change('message', 'fromTime', moment().startOf('day').toDate()));
  dispatch(change('message', 'toTime', moment().toDate()));
};

const onYesterdayDateRangeHandle = (dispatch: any) => {
  dispatch(
    change(
      'message',
      'fromTime',
      moment().subtract(1, 'day').startOf('day').toDate(),
    ),
  );
  dispatch(
    change(
      'message',
      'toTime',
      moment().subtract(1, 'day').endOf('day').toDate(),
    ),
  );
};

const onLast24DateRangeHandle = (dispatch: any) => {
  dispatch(
    change('message', 'fromTime', moment().subtract(24, 'hours').toDate()),
  );
  dispatch(change('message', 'toTime', moment().toDate()));
};

const onLast3DaysDateRangeHandle = (dispatch: any) => {
  dispatch(
    change('message', 'fromTime', moment().subtract(3, 'days').toDate()),
  );
  dispatch(change('message', 'toTime', moment().toDate()));
};

const onLastWeekDateRangeHandle = (dispatch: any) => {
  dispatch(
    change('message', 'fromTime', moment().subtract(1, 'week').toDate()),
  );
  dispatch(change('message', 'toTime', moment().toDate()));
};

// eslint-disable-next-line max-len
let MessagesForm: any = (props: any) => {
  const { t } = useTranslation();
  const { className, handleSubmit, onSubmit, initialValues, deviceId } = props;
  const dispatch = useAppDispatch();
  const devices = useSelector(selectAllDevices);
  const [startDate, setStartDate] = useState(initialValues.fromTime);
  const [endDate, setEndDate] = useState(initialValues.toTime);
  const devicesOptions = devices.map((device: IDeviceModel) => ({
    label: device.name,
    value: device.id,
  }));

  // fetch devices
  useEffect(() => {
    void dispatch(fetchDevices({ type: 'tracker' }));
  }, []);

  // set device in the form
  useEffect(() => {
    if (deviceId && deviceId.value) {
      dispatch(
        change(
          'message',
          'device',
          devices.find((device: IDeviceModel) => device.id === deviceId.value),
        ),
      );
    }
  }, [deviceId]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className}>
      <Form.Group as={Row}>
        <Selectbox options={devicesOptions} name="deviceId" />
      </Form.Group>
      <Form.Group as={Row}>
        <Field
          name="fromTime"
          type="text"
          className="form-control"
          placeholderText={t('messagesForm.fromTime')}
          id="fromTime"
          component={DateTimeInput}
          selectsStart
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          setDate={setStartDate}
        />
      </Form.Group>
      <Form.Group as={Row}>
        <Field
          name="toTime"
          type="text"
          className="form-control"
          placeholderText={t('messagesForm.fromTime')}
          id="toTime"
          component={DateTimeInput}
          selectsEnd
          selected={endDate}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          setDate={setEndDate}
        />
      </Form.Group>
      <DatePeriodButtons
        onTodayDateRangeHandle={onTodayDateRangeHandle.bind(
          undefined,
          dispatch,
        )}
        onYesterdayDateRangeHandle={onYesterdayDateRangeHandle.bind(
          undefined,
          dispatch,
        )}
        onLast24DateRangeHandle={onLast24DateRangeHandle.bind(
          undefined,
          dispatch,
        )}
        onLast3DaysDateRangeHandle={onLast3DaysDateRangeHandle.bind(
          undefined,
          dispatch,
        )}
        onLastWeekDateRangeHandle={onLastWeekDateRangeHandle.bind(
          undefined,
          dispatch,
        )}
      />
      <Form.Group className="text-end">
        <Button type="submit" variant="primary" disabled={!deviceId}>
          {t('shared.form.button.apply')}
        </Button>
      </Form.Group>
    </form>
  );
};

MessagesForm = reduxForm<Record<string, boolean>, IMessagesFormProps>({
  form: 'message',
  enableReinitialize: true,
})(MessagesForm);

const selector = formValueSelector('message');
MessagesForm = connect((state: any) => {
  const deviceId = selector(state, 'deviceId');
  return { deviceId };
})(MessagesForm);

export default MessagesForm;
