import moment from 'moment';
import { Resizable } from 're-resizable';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { CircleMarker, Popup } from 'react-leaflet';
import { useSelector } from 'react-redux';

// import { reset } from 'redux-form';
import { useAppDispatch } from '../../app/hooks';
import type {
  IDataPointModel,
  IGeneralComponentProps,
  ITrackModel,
} from '../../interfaces/interfaces';
import {
  findAndMerge as findAndMergeDeviceData,
  select as selectDeviceData,
} from '../../slices/deviceData/deviceDataSlice';
import { setText } from '../../slices/notification/notificationSlice';
import { settingsSelector } from '../../slices/settings/settingsSlice';
import {
  create,
  destroy,
  destroyAll,
  selectAll as selectAllTracks,
  updateAll,
} from '../../slices/tracks/tracksSlice';
import { setBounds } from '../../utils/device';
import { onResizeStop } from '../../utils/resizable';
import ArrowheadsPolyline from '../arrow_heads_polyline/ArrowHeadsPolyline';
import { Map } from '../map/Map';
import MonitoringPopover from '../monitoring_popover/MonitoringPopover';
import TracksListItem from '../tracks_list_item/TracksListItem';
import styles from './Tracks.module.scss';
import TracksForm from './TracksForm';

const onSubmit = (t: any, formData: any, dispatch: any): void => {
  dispatch(create(formData)).then((data: any) => {
    if (
      data.error ||
      !data.payload ||
      !data.payload.dataPoints ||
      data.payload.dataPoints.length === 0
    ) {
      dispatch(setText(t('shared.notification.noData')));
    }
  });
};

const onRemove = (track: ITrackModel, dispatch: any): void => {
  dispatch(destroy(track));
};

const onEdit = (track: ITrackModel, dispatch: any): void => {
  dispatch(destroy(track));
};

const onShowAll = (tracks: ITrackModel[], dispatch: any) => {
  const selectedTracks = [];
  for (const track of tracks) {
    selectedTracks.push({ id: track.id, selected: true });
  }

  dispatch(updateAll(selectedTracks));
};

const onDestroyAll = (dispatch: any) => {
  dispatch(destroyAll());
};

const Tracks: React.FC<IGeneralComponentProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // refs
  const refMap = useRef<HTMLInputElement>(null);

  // states
  const [hoveredDeviceDataId, setHoveredDeviceDataId] = useState<number | null>(
    null,
  );

  // selectors
  const { leftColumnSize }: any = useSelector(settingsSelector);
  const tracks = useSelector(selectAllTracks);
  const selectedTracks = tracks.filter((track: ITrackModel) => track.selected);
  const hoveredDeviceData = useSelector(selectDeviceData(hoveredDeviceDataId));

  // effects
  useEffect(() => {
    if (refMap.current) {
      const dataPoints = tracks.flatMap(
        (track: ITrackModel) => track.dataPoints,
      );
      if (dataPoints.length > 0) {
        setBounds(dataPoints, refMap.current);
      }
    }
  }, [tracks]);

  useEffect(() => {
    if (hoveredDeviceDataId) {
      void dispatch(findAndMergeDeviceData(hoveredDeviceDataId));
    }
  }, [hoveredDeviceDataId]);

  const onPopupClose = useCallback(() => {
    setHoveredDeviceDataId(null);
  }, []);

  return (
    <div className="resizable-wrapper">
      <Resizable
        enable={{
          top: false,
          right: true,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        defaultSize={leftColumnSize}
        onResizeStop={onResizeStop.bind(
          undefined,
          dispatch,
          leftColumnSize,
          refMap.current,
        )}
        maxWidth="50%"
        minWidth="280"
        className={`${styles.leftColumn} left-column`}
      >
        <Scrollbars autoHide>
          <div className={styles.form}>
            <TracksForm
              onSubmit={onSubmit.bind(undefined, t)}
              initialValues={{
                lineThickness: 1,
                color: '#fd397a',
                fromTime: moment().subtract(1, 'day').toDate(),
                toTime: moment().toDate(),
                selected: true,
                showedPoints: true,
              }}
            />
          </div>
          {tracks.length > 0 && (
            <div className={styles.tracksList}>
              <div className="d-flex justify-content-between mb-2">
                <Button
                  variant="success"
                  onClick={onShowAll.bind(undefined, tracks, dispatch)}
                >
                  {t('tracks.showAll')}
                </Button>
                <Button
                  variant="danger"
                  onClick={onDestroyAll.bind(undefined, dispatch)}
                >
                  {t('tracks.clearAll')}
                </Button>
              </div>
              {tracks.map((track: ITrackModel, index: number) => (
                <TracksListItem
                  key={index}
                  track={track}
                  onRemove={onRemove.bind(undefined, track, dispatch)}
                  onEdit={onEdit.bind(undefined, track, dispatch)}
                />
              ))}
            </div>
          )}
        </Scrollbars>
      </Resizable>
      <div className="right-column">
        <Map ref={refMap} onPopupClose={onPopupClose}>
          <>
            {selectedTracks.length > 0 &&
              selectedTracks.map((track: ITrackModel) => (
                <>
                  <ArrowheadsPolyline
                    arrowheads={{
                      fill: true,
                      frequency: '500px',
                      size: '15px',
                      fillColor: 'white',
                    }}
                    positions={track.dataPoints.map(
                      (point: IDataPointModel) => [point.x, point.y],
                    )}
                    color={track.color}
                    weight={track.lineThickness.value}
                  />

                  {hoveredDeviceData && (
                    <Popup
                      position={[
                        hoveredDeviceData.data.position.lat,
                        hoveredDeviceData.data.position.lon,
                      ]}
                      closeButton={false}
                    >
                      <MonitoringPopover
                        name={track.device.name}
                        number={track.device.number}
                        geozones={track.device.geozones}
                        eventsCount={
                          track.device.events ? track.device.events.length : 0
                        }
                        deviceData={hoveredDeviceData}
                        t={t}
                      />
                    </Popup>
                  )}
                  {track.dataPoints.length > 0 &&
                    track.showedPoints &&
                    track.dataPoints.map(
                      (trackPoint: IDataPointModel, index: number) => {
                        const nextItem = track.dataPoints[index + 1];
                        const polyLineCoords = [[trackPoint.x, trackPoint.y]];
                        if (nextItem) {
                          polyLineCoords.push([nextItem.x, nextItem.y]);
                        }

                        return (
                          <div key={index}>
                            <CircleMarker
                              center={[trackPoint.x, trackPoint.y]}
                              fillColor={track.color}
                              fillOpacity={1}
                              eventHandlers={{
                                click: () => {
                                  setHoveredDeviceDataId(trackPoint.id);
                                },
                                mouseover: (e) => {
                                  e.target.setStyle({ radius: 5 });
                                },
                                mouseout: (e) => {
                                  e.target.setStyle({ radius: 3 });
                                },
                              }}
                              color={track.color}
                              radius={3}
                            />
                          </div>
                        );
                      },
                    )}
                </>
              ))}
          </>
        </Map>
      </div>
    </div>
  );
};

export default Tracks;
