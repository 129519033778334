// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React, { useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';

import { useAppDispatch } from '../../app/hooks';
import { isCan } from '../../helpers/permission';
import {
  baseStationsSelector,
  destroy as destroyBaseStation,
} from '../../slices/baseStations/baseStationsSlice';
import { settingsSelector } from '../../slices/settings/settingsSlice';
import { errorFor } from '../../utils/formError';
import Checkbox from '../checkbox/Checkbox';
import Confirmation from '../confirmation/Confirmation';
import Input from '../input/Input';

interface IBaseStationFormProps {
  id: number | null;
  initialValues: Record<string, any>;
  onSubmit: any;
  handleSubmit?: any;
  closeHandle: any;
}

let BaseStation: any = (props: IBaseStationFormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id, onSubmit, closeHandle, handleSubmit } = props;
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const { errors } = useSelector(baseStationsSelector);
  const { currentUser } = useSelector(settingsSelector);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Row}>
          <label htmlFor="number" className="col-sm-2 col-form-label">
            {t('models.baseStation.number')}:
          </label>
          <div className="col-sm-4">
            <Input
              component="input"
              name="number"
              type="number"
              className="form-control-sm"
              id="number"
              readOnly={!!id}
              error={errorFor(errors, 'baseStation.number')}
            />
          </div>
        </Form.Group>

        <Form.Group as={Row}>
          <label htmlFor="name" className="col-sm-2 col-form-label">
            {t('models.baseStation.name')}:
          </label>
          <div className="col-sm-4">
            <Input
              component="input"
              name="name"
              type="text"
              className="form-control-sm"
              id="name"
              error={errorFor(errors, 'baseStation.name')}
            />
          </div>
        </Form.Group>

        {/*<Form.Group as={Row}>*/}
        {/*  <label htmlFor="name" className="col-sm-2 col-form-label">*/}
        {/*    Тип:*/}
        {/*  </label>*/}
        {/*  <div className="col-sm-4">*/}
        {/*    <Selectbox options={typesOptions} size="sm" name="type" />*/}
        {/*  </div>*/}
        {/*</Form.Group>*/}

        <h2 className="mt-5">{t('baseStationForm.coordinates')}</h2>

        <Form.Group as={Row}>
          <label htmlFor="lat" className="col-sm-2 col-form-label">
            {t('models.baseStation.lat')}:
          </label>
          <div className="col-sm-4">
            <Input
              component="input"
              name="lat"
              type="number"
              className="form-control-sm"
              id="lat"
              error={errorFor(errors, 'baseStation.lat')}
            />
          </div>
        </Form.Group>

        <Form.Group as={Row}>
          <label htmlFor="lon" className="col-sm-2 col-form-label">
            {t('models.baseStation.lon')}:
          </label>
          <div className="col-sm-4">
            <Input
              component="input"
              name="lon"
              type="number"
              className="form-control-sm"
              id="lon"
              error={errorFor(errors, 'baseStation.lon')}
            />
          </div>
        </Form.Group>

        <h2 className="mt-5">{t('baseStationForm.internetConnection')}</h2>

        <Form.Group as={Row}>
          <label htmlFor="ip" className="col-sm-2 col-form-label">
            {t('models.baseStation.ip')}:
          </label>
          <div className="col-sm-4">
            <Input
              component="input"
              name="ip"
              type="text"
              className="form-control-sm"
              id="ip"
              error={errorFor(errors, 'baseStation.ip')}
            />
          </div>
        </Form.Group>

        <Checkbox
          className="mb-4"
          name="gsmModem"
          label={t('models.baseStation.gsmModem')}
        />

        <Form.Group as={Row}>
          <label htmlFor="iccid" className="col-sm-2 col-form-label">
            {t('models.baseStation.iccid')}:
          </label>
          <div className="col-sm-4">
            <Input
              component="input"
              name="iccid"
              type="text"
              className="form-control-sm"
              id="iccid"
              error={errorFor(errors, 'baseStation.iccid')}
            />
          </div>
        </Form.Group>

        <Form.Group as={Row}>
          <label htmlFor="imei" className="col-sm-2 col-form-label">
            {t('models.baseStation.imei')}:
          </label>
          <div className="col-sm-4">
            <Input
              component="input"
              name="imei"
              type="text"
              className="form-control-sm"
              id="imei"
              error={errorFor(errors, 'baseStation.imei')}
            />
          </div>
        </Form.Group>

        <Checkbox
          className="mb-4"
          name="satelliteModem"
          label={t('models.baseStation.satelliteModem')}
        />

        <Checkbox
          className="mb-4"
          name="ethernetWifi"
          label={t('models.baseStation.ethernetWifi')}
        />

        <Button size="sm" variant="success" type="submit" className="me-3">
          {t('shared.form.button.save')}
        </Button>
        <Button size="sm" variant="warning" onClick={closeHandle}>
          {t('shared.form.button.close')}
        </Button>
        {isCan('destroy', 'BaseStation', currentUser) && (
          <a
            className="text-danger ms-4"
            href="#"
            onClick={() => setIsShowConfirmModal(true)}
          >
            {t('shared.remove')}
          </a>
        )}
      </form>
      {!!id && (
        <Confirmation
          isShow={isShowConfirmModal}
          onConfirm={async () => {
            try {
              await dispatch(destroyBaseStation(id));
              setIsShowConfirmModal(false);
              closeHandle();
            } catch {
              // nothing to do
            }
          }}
          onHide={() => {
            setIsShowConfirmModal(false);
          }}
        />
      )}
    </>
  );
};

BaseStation = reduxForm<Record<string, boolean>, IBaseStationFormProps>({
  form: 'baseStation',
  enableReinitialize: true,
})(BaseStation);

const selector = formValueSelector('baseStation');
BaseStation = connect((state: any) => {
  const id = selector(state, 'id');
  return { id };
})(BaseStation);

export default BaseStation;
