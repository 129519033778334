import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import React, { useCallback } from 'react';
import {
  Button,
  Nav,
  Navbar,
  NavDropdown,
  NavItem,
  NavLink,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import store from 'store';

import i18n from '../../i18n';
import { destroyAuthTokens } from '../../slices/settings/settingsSlice';
import styles from './Header.module.scss';

const MENU = [
  {
    key: 'header.menu.monitoring',
    link: '/',
  },
  {
    key: 'header.menu.tracks',
    link: '/tracks',
  },
  {
    key: 'header.menu.geozones',
    link: '/geozones',
  },
  {
    key: 'header.menu.messages',
    link: '/messages',
  },
  {
    key: 'header.menu.events',
    link: '/events',
  },
  {
    key: 'header.menu.employees',
    link: '/employees',
  },
];

const changeLanguage = (lng: string) => {
  void i18n.changeLanguage(lng);
  store.set('locale', lng);
};

export function Header() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const locale = store.get('locale') || 'ru';

  const logout = useCallback(() => {
    dispatch(destroyAuthTokens());
    history.push('/login');
  }, []);

  return (
    <div className={styles.header}>
      <Navbar>
        <Nav>
          {MENU.map((item) => (
            <NavItem key={item.key} className="me-4">
              <Button
                href={item.link}
                className={cn(styles.button, {
                  [styles.active]: window.location.pathname === item.link,
                })}
              >
                {t(item.key)}
              </Button>
            </NavItem>
          ))}
        </Nav>
        <Nav className="ms-auto">
          <NavDropdown
            className={`${styles.currentLang} me-4`}
            title={locale}
            id="header-lang"
          >
            <NavDropdown.Item
              className={cn(styles.langDropdown, { active: locale === 'en' })}
              onClick={() => changeLanguage('en')}
            >
              en
            </NavDropdown.Item>
            <NavDropdown.Item
              className={cn(styles.langDropdown, { active: locale === 'ru' })}
              onClick={() => changeLanguage('ru')}
            >
              ru
            </NavDropdown.Item>
          </NavDropdown>
          <NavItem className="me-4">
            <NavLink href="/settings/general">
              <FontAwesomeIcon icon={faUser} />
            </NavLink>
          </NavItem>
          <NavItem className="me-4">
            <NavLink onClick={logout.bind(undefined, history)}>
              <FontAwesomeIcon icon={faSignOutAlt} />
            </NavLink>
          </NavItem>
        </Nav>
      </Navbar>
    </div>
  );
}
