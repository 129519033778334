import cn from 'classnames';
import React from 'react';

import type { IDeviceDataModel } from '../../interfaces/interfaces';
import { isOnline } from '../../utils/device';
import Dot from '../dot/Dot';

interface IDeviceOnlineProps {
  deviceData: IDeviceDataModel | null;
  className?: string;
}

const DeviceOnline = ({ deviceData, className }: IDeviceOnlineProps) => {
  const classNames = cn(
    isOnline(deviceData ? deviceData.createdAt : null)
      ? 'text-success'
      : 'text-secondary',
    className,
  );
  return <Dot className={classNames} />;
};

export default DeviceOnline;
