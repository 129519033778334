import React from 'react';

import type { IAuthLayoutProps } from '../../interfaces/interfaces';
import styles from './AuthLayout.module.scss';

const AuthLayout: React.FunctionComponent<IAuthLayoutProps> = ({
  children,
}: IAuthLayoutProps) => (
  <div className={styles.authLayoutWrapper}>{children}</div>
);

export default AuthLayout;
