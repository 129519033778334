import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { isCan } from '../../helpers/permission';
import { settingsSelector } from '../../slices/settings/settingsSlice';

interface ISettingsMenuProps {
  active: 'general' | 'users';
}

const SettingsMenu = ({ active }: ISettingsMenuProps) => {
  const { t } = useTranslation();
  const { currentUser } = useSelector(settingsSelector);

  const [isCanRead, setIsCanRead] = useState<boolean>(false);

  useEffect(() => {
    setIsCanRead(isCan('read', 'User', currentUser));
  }, [currentUser]);

  return (
    <ListGroup className="w-100" defaultActiveKey={`/settings/${active}`}>
      <ListGroup.Item action href="/settings/general">
        {t('settings.general.title')}
      </ListGroup.Item>
      {isCanRead && (
        <ListGroup.Item action href="/settings/users">
          {t('settings.members.title')}
        </ListGroup.Item>
      )}
    </ListGroup>
  );
};

export default SettingsMenu;
