import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Alert } from 'react-bootstrap';

import styles from './Notification.module.scss';

interface INotificationProps {
  type: 'success' | 'info' | 'danger' | 'warning';
  isShow: boolean;
  text: string | null;
  onClose: any;
}

const Notification = (props: INotificationProps) => {
  const { isShow, type, onClose, text } = props;

  useEffect(() => {
    if (isShow) {
      setTimeout(() => {
        onClose();
      }, 2000);
    }
  }, [isShow]);

  return (
    <Alert show={isShow} variant={type} className={styles.notification}>
      <div>
        <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
        {text}
      </div>
    </Alert>
  );
};

export default Notification;
