import React from 'react';
import { Accordion } from 'react-bootstrap';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';

import type { IDeviceModel } from '../../interfaces/interfaces';
import MonitoringActiveListItem from '../monitoring_active_list_item/MonitoringActiveListItem';

interface IMonitoringActiveDevicesFormProps {
  devices: IDeviceModel[];
  onSubmit: any;
  openModelFormHandle: any;
  openedModelId: number | null;
  initialValues: Record<string, boolean>;
  className: string;
  canUpdate: boolean;
}

const MonitoringForm: React.FC<
  InjectedFormProps<
    Record<string, boolean>,
    IMonitoringActiveDevicesFormProps
  > &
    IMonitoringActiveDevicesFormProps
> = ({
  devices,
  onSubmit,
  initialValues,
  className,
  openModelFormHandle,
  openedModelId,
  canUpdate,
}: IMonitoringActiveDevicesFormProps) => (
  <form onSubmit={onSubmit} className={className}>
    <Accordion defaultActiveKey="1">
      {devices.length > 0 &&
        devices.map((device: IDeviceModel) => (
          <MonitoringActiveListItem
            isChecked={initialValues[`deviceId${device.id}`]}
            openModelFormHandle={openModelFormHandle}
            key={device.id}
            device={device}
            openedDeviceId={openedModelId}
            canUpdate={canUpdate}
          />
        ))}
    </Accordion>
  </form>
);

export default reduxForm<
  Record<string, boolean>,
  IMonitoringActiveDevicesFormProps
>({
  form: 'monitoringDevices',
  enableReinitialize: true,
})(MonitoringForm);
