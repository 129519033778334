import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../app/store';
import type {
  IAuthLoginAction,
  IAuthRegistrationForm,
  ICurrentUserState,
  IGetCurrentUserResponse,
  ILoginResponse,
} from '../../interfaces/interfaces';
import { formattedErrors } from '../../utils/formError';
import { updateAuthTokens, updateCurrentUser } from '../settings/settingsSlice';
import {
  createRequest,
  fetchRequest,
  loginRequest,
  resetPasswordRequest,
  sendResetPasswordRequest,
  updateRequest,
} from './currentUserAPI';

export const login = createAsyncThunk(
  'auth/login',
  async ({ username, password }: IAuthLoginAction, thunkAPI) => {
    try {
      const response = await loginRequest(username, password);

      if (response.status === 200) {
        const data: ILoginResponse = await response.data;
        thunkAPI.dispatch(updateAuthTokens(data));
        return data;
      } else {
        return thunkAPI.rejectWithValue('Извините, произошла ошибка');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue([
        {
          field: 'password',
          message: error.response.data.message,
        },
      ]);
    }
  },
);

export const create = createAsyncThunk(
  'auth/create',
  async (formData: IAuthRegistrationForm, thunkAPI) => {
    if (
      !formData.password ||
      !formData.confirmationPassword ||
      formData.password !== formData.confirmationPassword
    ) {
      return thunkAPI.rejectWithValue([
        { field: 'password', message: 'Неверный пароль' },
      ]);
    }

    try {
      const response = await createRequest(formData);
      const data: any = await response.data;

      if (response.status === 200) {
        thunkAPI.dispatch(updateAuthTokens(data));
        return data;
      } else {
        return thunkAPI.rejectWithValue('Извините, произошла ошибка');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errors);
    }
  },
);

export const fetch = createAsyncThunk(
  'auth/getCurrentUser',
  async (_, thunkAPI) => {
    try {
      const response = await fetchRequest();

      if (response.status === 200) {
        const data: IGetCurrentUserResponse = await response.data;
        thunkAPI.dispatch(updateCurrentUser(data.user));
        return data;
      }

      return thunkAPI.rejectWithValue('Извините, произошла ошибка');
    } catch {
      return thunkAPI.rejectWithValue('Извините, произошла ошибка');
    }
  },
);

export const update = createAsyncThunk(
  'currentUser/update',
  async (formData: any, thunkAPI) => {
    try {
      if (
        (formData.password || formData.confirmationPassword) &&
        formData.password !== formData.confirmationPassword
      ) {
        return thunkAPI.rejectWithValue([
          { field: 'user.password', message: 'Неверный пароль' },
        ]);
      }

      const response = await updateRequest(formData);

      if (response.status === 200) {
        const data: any = await response.data;
        thunkAPI.dispatch(updateCurrentUser(data.user));
        return data.user;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errors);
    }
  },
);

export const sendResetPassword = createAsyncThunk(
  'auth/sendResetPassword',
  async (email: string, thunkAPI) => {
    try {
      const response = await sendResetPasswordRequest(email);
      return await response.data;
    } catch {
      return thunkAPI.rejectWithValue('Извините, произошла ошибка');
    }
  },
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (props: any, thunkAPI) => {
    if (props.password !== props.confirmationPassword || !props.password) {
      return thunkAPI.rejectWithValue([
        { field: 'password', message: 'Неверный пароль' },
      ]);
    }

    try {
      const response = await resetPasswordRequest(props.password, props.token);
      return await response.data;
    } catch (error) {
      return error.response.status === 404
        ? thunkAPI.rejectWithValue([
            { field: 'password', message: 'Неверный токен' },
          ])
        : thunkAPI.rejectWithValue([
            { field: 'password', message: 'Неверный пароль' },
          ]);
    }
  },
);

const initialState: ICurrentUserState = {
  errors: [],
};

export const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.errors = [];
      })
      .addCase(login.fulfilled, (state) => {
        state.errors = [];
      })
      .addCase(login.rejected, (state, { payload }: any) => {
        state.errors = formattedErrors(payload);
      })
      .addCase(create.pending, (state) => {
        state.errors = [];
      })
      .addCase(create.fulfilled, (state) => {
        state.errors = [];
      })
      .addCase(create.rejected, (state, { payload }: any) => {
        state.errors = formattedErrors(payload);
      })
      .addCase(fetch.pending, (state) => {
        state.errors = [];
      })
      .addCase(fetch.fulfilled, (state) => {
        state.errors = [];
      })
      .addCase(fetch.rejected, (state, { payload }: any) => {
        state.errors = [payload];
      })
      .addCase(update.pending, (state) => {
        state.errors = [];
      })
      .addCase(update.fulfilled, (state) => {
        state.errors = [];
      })
      .addCase(update.rejected, (state, { payload }: any) => {
        state.errors = formattedErrors(payload);
      })
      .addCase(resetPassword.pending, (state) => {
        state.errors = [];
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.errors = [];
      })
      .addCase(resetPassword.rejected, (state, { payload }: any) => {
        state.errors = formattedErrors(payload);
      });
  },
});

export default currentUserSlice.reducer;
export const currentUserSelector = (state: RootState) => state.currentUser;
