import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';

import { useAppDispatch } from '../../app/hooks';
import type { IDeviceModel } from '../../interfaces/interfaces';
import { update } from '../../slices/devices/devicesSlice';
import { setText } from '../../slices/notification/notificationSlice';

interface IMonitoringUnactiveListItem {
  device: IDeviceModel;
}

const MonitoringUnactiveListItem = (props: IMonitoringUnactiveListItem) => {
  const { device } = props;
  const dispatch = useAppDispatch();

  const activate = useCallback(async () => {
    await dispatch(update({ id: device.id, active: true }));
    dispatch(setText('Запись удачно обновлена'));
  }, []);

  return (
    <div className="list-item">
      <div className="list-item-header">
        <span className="list-item-name">{device.name}</span>
        <Button
          variant="success"
          className="btn-icon btn-tool ms-auto"
          onClick={activate}
        >
          <FontAwesomeIcon icon={faPowerOff} />
        </Button>
        <div className="list-item-corner" />
      </div>
    </div>
  );
};

export default MonitoringUnactiveListItem;
