const DEFAULT_ACTIVE_TOP_BLOCK = 'map';
const DEFAULT_ACTIVE_BOTTOM_BLOCK = 'table';

export const chartConfigDefaultValues = {
  battery: {
    active: true,
    color: '#fd397a',
    lineThickness: 3,
  },
  temperature: {
    active: false,
    color: '#5578eb',
    lineThickness: 1,
  },
  packageId: {
    active: false,
    color: '#0abb87',
    lineThickness: 1,
  },
  freq: {
    active: false,
    color: '#ffb822',
    lineThickness: 1,
  },
  rssi: {
    active: false,
    color: '#282a3c',
    lineThickness: 1,
  },
  snr: {
    active: false,
    color: '#fd397a',
    lineThickness: 1,
  },
  sc: {
    active: false,
    color: '#5578eb',
    lineThickness: 1,
  },
  snr_min: {
    active: false,
    color: '#0abb87',
    lineThickness: 1,
  },
  snr_max: {
    active: false,
    color: '#ffb822',
    lineThickness: 1,
  },
  activity_low: {
    active: false,
    color: '#282a3c',
    lineThickness: 1,
  },
  activity_high: {
    active: false,
    color: '#fd397a',
    lineThickness: 1,
  },
  activity_max: {
    active: false,
    color: '#5578eb',
    lineThickness: 1,
  },
  activity_avg: {
    active: false,
    color: '#0abb87',
    lineThickness: 1,
  },
};

export function coordsAsString(data: any): string | null {
  const lat = data.x;
  const lon = data.y;

  return lat && lon ? `${lat}/ ${lon}` : null;
}

export const messagesActiveBlockDefault = {
  top: DEFAULT_ACTIVE_TOP_BLOCK,
  bottom: DEFAULT_ACTIVE_BOTTOM_BLOCK,
};
