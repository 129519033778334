import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import moment from 'moment';

import type { RootState } from '../../app/store';
import type {
  IDataPointsResponseData,
  ITrackModel,
} from '../../interfaces/interfaces';
import { defaultTimeFormat } from '../../utils/date';
import { dataWithCoordinates } from '../../utils/track';
import { fetchDataPointsRequest } from '../deviceData/deviceDataApi';

function getId(deviceId: any, fromTime: Date, toTime: Date) {
  const momentFromTime = moment(fromTime);
  const momentToTime = moment(toTime);
  return `${deviceId.value}_${momentFromTime.unix()}_${momentToTime.unix()}`;
}

function getName(
  name: string | undefined,
  deviceId: any,
  fromTime: Date,
  toTime: Date,
) {
  const formattedFromTime = defaultTimeFormat(fromTime);
  const formattedToTime = defaultTimeFormat(toTime);
  return (
    name || `${deviceId.value} (${formattedFromTime} - ${formattedToTime})`
  );
}

export const create = createAsyncThunk(
  'tracks/create',
  async (formData: any, thunkAPI) => {
    try {
      const response = await fetchDataPointsRequest({
        deviceId: formData.deviceId,
        fromTime: formData.fromTime,
        toTime: formData.toTime,
      });
      const data: IDataPointsResponseData = await response.data;
      const id = getId(formData.deviceId, formData.fromTime, formData.toTime);
      const name = getName(
        formData.name,
        formData.deviceId,
        formData.fromTime,
        formData.toTime,
      );

      if (data.dataPoints && data.dataPoints.length > 0) {
        return {
          ...formData,
          name,
          id,
          dataPoints: dataWithCoordinates(data.dataPoints),
        };
      }

      return { ...formData, name, id, dataPoints: [] };
    } catch {
      return thunkAPI.rejectWithValue('Sorry. something wrong');
    }
  },
);

// eslint-disable-next-line @typescript-eslint/unbound-method
const {
  getInitialState,
  getSelectors,
  addOne,
  removeAll,
  removeOne,
  updateMany,
  updateOne,
} = createEntityAdapter<ITrackModel>();

export const tracksSlice = createSlice({
  name: 'tracks',
  initialState: getInitialState({
    errors: [],
  }),
  reducers: {
    destroy: (state: any, { payload }) => {
      removeOne(state, payload.id);
    },
    destroyAll: (state: any) => {
      removeAll(state);
    },
    update: (state: any, { payload }) => {
      updateOne(state, { id: payload.id, changes: payload });
    },
    updateAll: (state: any, { payload }) => {
      updateMany(
        state,
        payload.map((item: any) => ({
          id: item.id,
          changes: item,
        })),
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(create.fulfilled, (state: any, { payload }: any) => {
      if (payload.dataPoints && payload.dataPoints.length > 0) {
        addOne(state, payload);
      }
    });
  },
});

export const { destroy, destroyAll, update, updateAll } = tracksSlice.actions;
export const { selectAll } = getSelectors<RootState>(
  (state: RootState) => state.tracks,
);

export default tracksSlice.reducer;
