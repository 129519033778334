import { get } from 'lodash';
import { Resizable } from 're-resizable';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Popup } from 'react-leaflet';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../app/hooks';
import type {
  IBaseStationModel,
  IDeviceModel,
  IGeneralComponentProps,
} from '../../interfaces/interfaces';
import {
  create as createBaseStation,
  fetch as fetchBaseStations,
  selectAll as selectAllBaseStations,
  update as updateBaseStation,
} from '../../slices/baseStations/baseStationsSlice';
import {
  create as createDevice,
  monitoring,
  selectAll as selectAllDevices,
  update as updateDevice,
} from '../../slices/devices/devicesSlice';
import { setText } from '../../slices/notification/notificationSlice';
import { settingsSelector } from '../../slices/settings/settingsSlice';
import {
  coordinatesFromDeviceData,
  coordsAsArray,
  setBounds,
} from '../../utils/device';
import { onResizeStop } from '../../utils/resizable';
import BaseStation from '../base_station/BaseStation';
import CustomMarker from '../custom_marker/CustomMarker';
import Device from '../device/Device';
import { Map } from '../map/Map';
import MonitoringActiveDevices from '../monitoring_active_devices/MonitoringActiveDevices';
import MonitoringBaseStations from '../monitoring_base_stations/MonitoringBaseStations';
import MonitoringPopover from '../monitoring_popover/MonitoringPopover';
import MonitoringUnactiveDevices from '../monitoring_unactive_devices/MonitoringUnactiveDevices';
import Websockets from '../websockets/Websockets';
import styles from './Monitoring.module.scss';

const Monitoring: React.FC<IGeneralComponentProps> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  //states
  const [activeDevices, setActiveDevices] = useState<IDeviceModel[]>([]);
  const [unactiveDevices, setUnactiveDevices] = useState<IDeviceModel[]>([]);
  const [openedModel, setOpenedModel] = useState(null);
  const [openedModelType, setOpenedModelType] = useState<string | null>(null);
  const refMap = useRef<HTMLInputElement>(null);
  const [activeTab, setActiveTab] = useState<
    'active' | 'unactive' | 'basestations'
  >('active');
  const [checkedActiveDevicesIds, setCheckedActiveDevicesIds] = useState<
    number[]
  >([]);
  const [checkedBaseStationsIds, setCheckedBaseStationsIds] = useState<
    number[]
  >([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState<number | null>(null);
  const [isReadyToOnloadDevicesFocus, setIsReadyToOnloadDevicesFocus] =
    useState(false);

  // selectors
  const { leftColumnSize }: any = useSelector(settingsSelector);
  const devices = useSelector(selectAllDevices);
  const baseStations = useSelector(selectAllBaseStations);

  // callbacks
  const openModelHandle = useCallback(
    (
      type: 'device' | 'baseStation',
      model: IDeviceModel | IBaseStationModel | null,
    ) => {
      setOpenedModelType(type);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setOpenedModel(model);
    },
    [],
  );

  const closeHandle = useCallback(() => {
    setOpenedModel(null);
    setOpenedModelType(null);
  }, []);

  const onModelSubmit = useCallback(
    async (formData: any) => {
      let submitFunction =
        openedModelType === 'device' ? createDevice : createBaseStation;
      if (formData.id) {
        submitFunction =
          openedModelType === 'device' ? updateDevice : updateBaseStation;
      }

      const data: any = await dispatch(submitFunction({ ...formData }));
      if (!data.error) {
        closeHandle();
        if (formData.id) {
          dispatch(setText(t('shared.notification.updated')));
        } else {
          dispatch(setText(t('shared.notification.created')));
        }
      }
    },
    [openedModelType],
  );

  const focusToActiveDevices = useCallback(() => {
    if (refMap.current) {
      const devicesForCoords =
        checkedActiveDevicesIds.length > 0
          ? activeDevices.filter((device: IDeviceModel) =>
              checkedActiveDevicesIds.includes(device.id),
            )
          : activeDevices;

      const deviceData = devicesForCoords
        .filter((device: IDeviceModel) => device.deviceData.length)
        .map((device: IDeviceModel) => device.deviceData[0]);

      if (deviceData.length > 0) {
        setBounds(coordinatesFromDeviceData(deviceData), refMap.current);
      }
    }
  }, [activeDevices, checkedActiveDevicesIds]);

  const focusToBaseStations = useCallback(() => {
    if (refMap.current) {
      const baseStationsForCoords =
        checkedBaseStationsIds.length > 0
          ? baseStations.filter((baseStation: IBaseStationModel) =>
              checkedBaseStationsIds.includes(baseStation.id),
            )
          : baseStations;

      if (baseStationsForCoords.length > 0) {
        setBounds(
          baseStationsForCoords.map((baseStation: IBaseStationModel) => ({
            x: baseStation.lat,
            y: baseStation.lon,
          })),
          refMap.current,
        );
      }
    }
  }, [baseStations, checkedBaseStationsIds]);

  const onDeviceDataMessageReceive = useCallback(
    (deviceDataArray) => {
      if (
        deviceDataArray.length > 0 &&
        checkedActiveDevicesIds.length === 1 &&
        checkedActiveDevicesIds[0] === deviceDataArray[0].deviceId
      ) {
        setBounds(coordinatesFromDeviceData(deviceDataArray), refMap.current);
      }
    },
    [checkedActiveDevicesIds, refMap],
  );

  const handleChangeTab = useCallback(
    (name: 'active' | 'unactive' | 'basestations') => {
      setActiveTab(name);

      if (name === 'active' || name === 'unactive') {
        focusToActiveDevices();
      } else {
        focusToBaseStations();
      }
    },
    [
      activeDevices,
      baseStations,
      checkedActiveDevicesIds,
      checkedBaseStationsIds,
    ],
  );

  // effects
  useEffect(() => {
    void dispatch(monitoring()).then(() => {
      setIsReadyToOnloadDevicesFocus(true);
    });
    void dispatch(fetchBaseStations());
  }, []);

  useEffect(() => {
    focusToActiveDevices();
  }, [isReadyToOnloadDevicesFocus, checkedActiveDevicesIds]);

  useEffect(() => {
    focusToBaseStations();
  }, [checkedBaseStationsIds]);

  // set active and unactive devices
  useEffect(() => {
    setActiveDevices(devices.filter((device: IDeviceModel) => device.active));
    setUnactiveDevices(
      devices.filter((device: IDeviceModel) => !device.active),
    );
  }, [devices]);

  const OpenedModelTypeComponent = (
    openedModelType === 'device'
      ? Device
      : openedModelType === 'baseStation'
      ? BaseStation
      : undefined
  ) as React.ElementType;

  return (
    <Websockets onDeviceDataMessageReceive={onDeviceDataMessageReceive}>
      <div className="resizable-wrapper">
        <Resizable
          enable={{
            top: false,
            right: true,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          defaultSize={leftColumnSize}
          onResizeStop={onResizeStop.bind(
            undefined,
            leftColumnSize,
            refMap.current,
          )}
          maxWidth="50%"
          minWidth="280"
          className={`${styles.leftColumn} left-column`}
        >
          <div className={styles.leftColumnContainer}>
            <Tab.Container defaultActiveKey="active">
              <Nav variant="pills" className="justify-content-between">
                <Nav.Item>
                  <Nav.Link
                    eventKey="active"
                    onClick={() => handleChangeTab('active')}
                  >
                    {t('monitoring.menu.active')}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="unactive"
                    onClick={() => handleChangeTab('unactive')}
                  >
                    {t('monitoring.menu.unactive')}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="basestations"
                    onClick={() => handleChangeTab('basestations')}
                  >
                    {t('monitoring.menu.baseStations')}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className={styles.tabContent}>
                <Tab.Pane eventKey="active" className="h-100">
                  <MonitoringActiveDevices
                    openModelFormHandle={openModelHandle}
                    openedModelId={
                      openedModelType === 'device'
                        ? get(openedModel, 'id', null)
                        : null
                    }
                    devices={activeDevices}
                    checkedActiveDevicesIds={checkedActiveDevicesIds}
                    setCheckedActiveDevicesIds={setCheckedActiveDevicesIds}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="unactive" className="h-100">
                  <MonitoringUnactiveDevices devices={unactiveDevices} />
                </Tab.Pane>
                <Tab.Pane eventKey="basestations" className="h-100">
                  <MonitoringBaseStations
                    openModelFormHandle={openModelHandle}
                    openedModelId={
                      openedModelType === 'baseStation'
                        ? get(openedModel, 'id', null)
                        : null
                    }
                    baseStations={baseStations}
                    checkedBaseStationsIds={checkedBaseStationsIds}
                    setCheckedBaseStationsIds={setCheckedBaseStationsIds}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Resizable>
        <div className="right-column">
          <Map ref={refMap}>
            <>
              {activeTab !== 'basestations' &&
                activeDevices.length > 0 &&
                activeDevices.map((device: IDeviceModel) => {
                  const coords = coordsAsArray(device);
                  if (coords) {
                    return (
                      <CustomMarker
                        type="device"
                        isChecked={checkedActiveDevicesIds.includes(device.id)}
                        isSelected={selectedDeviceId === device.id}
                        position={[coords[0], coords[1]]}
                      >
                        <Popup
                          eventHandlers={{
                            add: () => {
                              setSelectedDeviceId(device.id);
                            },
                            remove: () => {
                              setSelectedDeviceId(null);
                            },
                          }}
                          closeButton={false}
                        >
                          {MonitoringPopover({
                            name: device.name,
                            number: device.number,
                            geozones: device.geozones,
                            eventsCount: device.events
                              ? device.events.length
                              : 0,
                            deviceData: device.deviceData[0],
                            t,
                          })}
                        </Popup>
                      </CustomMarker>
                    );
                  }
                })}
              {activeTab === 'basestations' &&
                baseStations.length > 0 &&
                baseStations.map((baseStation: IBaseStationModel) => {
                  const coords = [baseStation.lat, baseStation.lon];
                  if (coords) {
                    return (
                      <CustomMarker
                        type="baseStation"
                        isChecked={checkedBaseStationsIds.includes(
                          baseStation.id,
                        )}
                        position={[coords[0], coords[1]]}
                      />
                    );
                  }
                })}
            </>
          </Map>
          {openedModelType && (
            <OpenedModelTypeComponent
              closeHandle={closeHandle}
              onSubmit={onModelSubmit}
              openedModel={openedModel}
            />
          )}
        </div>
      </div>
    </Websockets>
  );
};

export default Monitoring;
