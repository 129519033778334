import axios from 'axios';

import { getAuthToken } from '../utils/localStorage';

function getHeaders(isAuthorized?: boolean) {
  const result: Record<string, string> = {
    'Content-Type': 'application/json',
  };

  if (isAuthorized === undefined || isAuthorized === true) {
    result.Authorization = `Bearer ${getAuthToken()}`;
  }

  return result;
}

async function postPatchRequest(
  path: string,
  data: any,
  type: 'post' | 'patch',
  isAuthorized?: boolean,
) {
  const response = await axios({
    method: type,
    url: `${process.env.REACT_APP_API_URL}/${path}`,
    data,
    headers: getHeaders(isAuthorized),
  });

  return response;
}

export async function get(path: string, isAuthorized?: boolean) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/${path}`, {
    headers: getHeaders(isAuthorized),
  });
  return response;
}

export async function post(path: string, data: any, isAuthorized?: boolean) {
  const response = await postPatchRequest(path, data, 'post', isAuthorized);
  return response;
}

export async function patch(path: string, data: any, isAuthorized?: boolean) {
  const response = await postPatchRequest(path, data, 'patch', isAuthorized);
  return response;
}

export async function destroy(path: string, isAuthorized?: boolean) {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/${path}`,
    {
      headers: getHeaders(isAuthorized),
    },
  );

  return response;
}
