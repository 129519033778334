// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Accordion, Button, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Checkbox from '../checkbox/Checkbox';
import ColorInput from '../color_input/ColorInput';
import Selectbox from '../selectbox/Selectbox';

interface IMessagesChartConfigItemProps {
  initialValues: Record<string, any>;
  namePrefix: string;
  index: number;
}

const MessagesChartConfigItem = (props: IMessagesChartConfigItemProps) => {
  const { namePrefix, initialValues, index } = props;
  const { t } = useTranslation();
  const lineThicknessOptions = [
    { value: 0, label: 'Без линии' },
    { value: 1, label: '1px' },
    { value: 2, label: '2px' },
    { value: 3, label: '3px' },
    { value: 4, label: '4px' },
    { value: 5, label: '5px' },
  ];

  return (
    <div className="list-item">
      <div className="list-item-header">
        <Checkbox
          name={`${namePrefix}[active]`}
          label={t(`messagesChartConfig.chartConfig.${namePrefix}`)}
          className="d-inline-block align-middle"
        />
        <Accordion.Toggle
          className="btn-icon btn-tool btn-primary ms-auto"
          as={Button}
          variant="button"
          eventKey={index.toString()}
        >
          <FontAwesomeIcon icon={faAngleDown} />
        </Accordion.Toggle>
      </div>
      <Accordion.Collapse
        className="list-item-body"
        eventKey={index.toString()}
      >
        <div>
          <Form.Group as={Row}>
            <label htmlFor="thickness" className="col-sm-5 col-form-label">
              {t('shared.form.lineThickness')}:
            </label>
            <div className="col-sm-7">
              <Selectbox
                id="lineThickness"
                options={lineThicknessOptions}
                name={`${namePrefix}[lineThickness]`}
              />
            </div>
          </Form.Group>
          <Form.Group as={Row} className="mb-4">
            <label htmlFor="thickness" className="col-sm-5 col-form-label">
              {t('shared.form.lineColor')}:
            </label>
            <div className="col-sm-7">
              <ColorInput
                name={`${namePrefix}[color]`}
                color={initialValues.color}
              />
            </div>
          </Form.Group>
        </div>
      </Accordion.Collapse>
    </div>
  );
};

export default MessagesChartConfigItem;
