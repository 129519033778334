import {
  faChevronDown,
  faCog,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import React from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { submit } from 'redux-form';

import { useAppDispatch } from '../../app/hooks';
import type { IDeviceModel } from '../../interfaces/interfaces';
import { coordsAsString, getFullTime, getGeozones } from '../../utils/device';
import Checkbox from '../checkbox/Checkbox';
import DeviceGeotag from '../device_geotag/DeviceGeotag';
import DeviceOnline from '../device_online/DeviceOnline';
import PropertyItem from '../property_item/PropertyItem';

interface IMonitoringListItem {
  device: IDeviceModel;
  isChecked: boolean;
  openedDeviceId: number | null;
  openModelFormHandle: any;
  canUpdate: boolean;
}

const MonitoringActiveListItem = (props: IMonitoringListItem) => {
  const { t } = useTranslation();
  const { device, isChecked, openModelFormHandle, openedDeviceId, canUpdate } =
    props;
  const dispatch = useAppDispatch();
  const className = cn('list-item', {
    active: isChecked,
    opened: openedDeviceId === device.id,
  });

  return (
    <div className={className}>
      <div className="list-item-header">
        <Checkbox
          name={`deviceId${device.id}`}
          className="me-2"
          label={device.name}
          onChange={() =>
            setTimeout(() => {
              dispatch(submit('monitoringDevices'));
            }, 10)
          }
        />
        <DeviceOnline className="me-2" deviceData={device.deviceData[0]} />
        <DeviceGeotag className="me-2" deviceData={device.deviceData[0]} />
        {device.events.length > 0 && (
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="text-warning me-2"
          />
        )}
        <span className="me-auto"></span>
        {canUpdate && (
          <Button
            variant="primary"
            className="btn-icon btn-tool"
            onClick={openModelFormHandle.bind(undefined, 'device', device)}
          >
            <FontAwesomeIcon icon={faCog} />
          </Button>
        )}
        <Accordion.Toggle
          className="btn-icon btn-tool ms-2"
          as={Button}
          variant="primary"
          eventKey={device.id.toString()}
        >
          <FontAwesomeIcon icon={faChevronDown} />
        </Accordion.Toggle>
        <div className="list-item-corner" />
      </div>
      <Accordion.Collapse
        className="list-item-body"
        eventKey={device.id.toString()}
      >
        <>
          <PropertyItem
            title={`${t('models.device.number')}:`}
            value={device.number}
          />
          <PropertyItem
            title={`${t('models.deviceData.coordinates')}:`}
            value={coordsAsString(device.deviceData[0])}
          />
          <PropertyItem
            title={`${t('shared.models.createdAt')}:`}
            value={getFullTime(device.deviceData[0])}
          />
          <PropertyItem
            title={`${t('models.device.geozones')}:`}
            value={getGeozones(device.geozones, t)}
          />
        </>
      </Accordion.Collapse>
    </div>
  );
};

export default MonitoringActiveListItem;
