import 'moment/locale/ru';
import 'moment/locale/en-gb';

import moment from 'moment';
import { Resizable } from 're-resizable';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import store from 'store';

// import { useAppDispatch } from '../../app/hooks';
// import { getId } from '../../helpers/tracks';
import type {
  IDataPointModel,
  //IDataPointModel,
  IGeneralComponentProps,
} from '../../interfaces/interfaces';
//import { IDataPointsResponseData } from '../../interfaces/interfaces';
import {
  //fetch,
  fetchDataPoints,
  //fetchDataPointsCount,
  //resetAllStore,
  selectAllDataPoints,
  submittedFormSelector,
} from '../../slices/messages/messagesSlice';
import { setText } from '../../slices/notification/notificationSlice';
import { settingsSelector } from '../../slices/settings/settingsSlice';
// import { timestamp } from '../../utils/date';
import { setBounds } from '../../utils/device';
import {
  chartConfigDefaultValues,
  messagesActiveBlockDefault,
} from '../../utils/messages';
import { onResizeStop } from '../../utils/resizable';
import { dataWithCoordinates } from '../../utils/track';
import MessagesChart from '../messages_chart/MessagesChart';
import MessagesChartConfig from '../messages_chart_config/MessagesChartConfig';
import MessagesMap from '../messages_map/MessagesMap';
import MessagesTable from '../messages_table/MessagesTable';
import styles from './Messages.module.scss';
import MessagesForm from './MessagesForm';

moment().locale(store.get('locale'));

const DEFAULT_TOP_SIDE_HEIGHT = 400;

const onSubmit = async (
  t: any,
  setIsReadyToSubmitFocus: any,
  // setSubmittedForm: any,
  formData: any,
  dispatch: any,
) => {
  const data = await dispatch(fetchDataPoints({ ...formData }));
  // const fetchParams = { ...formData, page: 1 };
  // await dispatch(fetch(1));
  setIsReadyToSubmitFocus(true);
  // setSubmittedForm({ ...formData });
  if (data.errors || !data.payload || data.payload.length === 0) {
    dispatch(setText(t('shared.notification.noData')));
  }
};

const Messages: React.FC<IGeneralComponentProps> = () => {
  //const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // refs
  const refMap = useRef(null);
  const refVertResizable = useRef(null);

  // states
  const [activeBlock, setActiveBlock] = useState<Record<string, string>>(
    messagesActiveBlockDefault,
  );
  const [isEnabledChartConfig, setIsEnabledChartConfig] =
    useState<boolean>(false);
  const [isReadyToSubmitFocus, setIsReadyToSubmitFocus] = useState(false);
  const [chartConfig, setChartConfig] = useState<Record<string, any>>(
    chartConfigDefaultValues,
  );
  const [topSideHeight] = useState(DEFAULT_TOP_SIDE_HEIGHT);
  const [page, setPage] = useState<number>(1);
  const [selectedDataPointId, setSelectedDataPointId] = useState<number | null>(
    null,
  );
  // const [submittedForm, setSubmittedForm] = useState<any>(null);

  // selectors
  const { leftColumnSize }: any = useSelector(settingsSelector);
  const dataPoints = useSelector(selectAllDataPoints);
  // TODO: include dataPointsWithCoordinates to cache hook
  const dataPointsWithCoordinates = dataWithCoordinates(dataPoints);
  const messagesForm: any = useSelector(submittedFormSelector);

  // callbacks
  const handleChangeType = useCallback(
    (type: 'map' | 'chart' | 'table', position: 'top' | 'bottom') => {
      const oppositePosition = position === 'top' ? 'bottom' : 'top';

      if (type === activeBlock[oppositePosition]) {
        const oldPositionType = activeBlock[position];
        activeBlock[oppositePosition] = oldPositionType;
      }

      activeBlock[position] = type;
      setActiveBlock({ ...activeBlock });

      if (type === 'chart') {
        const windowHeight = window.innerHeight;
        const topMenuHeight = 64;
        const height = (windowHeight - topMenuHeight) / 2;

        if (refVertResizable.current) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          refVertResizable.current.updateSize({ height });
        }
      } else if (activeBlock[oppositePosition] === 'chart') {
        const messagesTopElement = document.getElementsByClassName(
          'messages-top',
        )[0] as HTMLElement;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
        let height = messagesTopElement.offsetHeight;

        if (oppositePosition === 'bottom') {
          const messagesBottomElement = document.getElementsByClassName(
            'messages-bottom',
          )[0] as HTMLElement;
          height = messagesBottomElement.offsetHeight;
        }
      }
    },
    [dataPoints],
  );

  const onMessagesResizeStop = useCallback(() => {
    if (
      (activeBlock.top === 'map' || activeBlock.bottom === 'map') &&
      refMap &&
      refMap.current
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      refMap.current.invalidateSize();
    }

    if (activeBlock.top === 'chart' || activeBlock.bottom === 'chart') {
      const messagesTopElement = document.getElementsByClassName(
        'messages-top',
      )[0] as HTMLElement;
      let height = messagesTopElement.offsetHeight;

      if (activeBlock.bottom === 'chart') {
        const messagesBottomElement = document.getElementsByClassName(
          'messages-bottom',
        )[0] as HTMLElement;
        height = messagesBottomElement.offsetHeight;
      }

      const messagesLineElement = document.getElementsByClassName(
        'messages-line',
      )[0] as HTMLElement;
      messagesLineElement.style.height = `${height - 55}px`;
    }
  }, []);

  const onMarkerClick = useCallback(
    (id: number) => {
      const index = dataPoints.findIndex(
        (item: IDataPointModel) => item.id === id,
      );

      if (index >= 0) {
        const newPage = Math.floor(index / 30);
        setPage(newPage);
        setSelectedDataPointId(id);

        const tr = document.getElementsByClassName(
          `messages-table-tr-${id}`,
        )[0];
        if (tr) {
          tr.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
          });
        }
      }
    },
    [dataPoints, messagesForm],
  );

  // const onMoveEnd = useCallback(
  //   (event: any) => {
  //     if (submittedForm) {
  //       const newZoom = event.target.getZoom();
  //       void dispatch(
  //         fetchDataPoints({
  //           data: [
  //             getId(
  //               submittedForm.deviceId,
  //               timestamp(submittedForm.fromTime),
  //               timestamp(submittedForm.toTime),
  //             ),
  //           ],
  //           x1: event.target.getBounds().getSouthWest().lat,
  //           y1: event.target.getBounds().getSouthWest().lng,
  //           x2: event.target.getBounds().getNorthEast().lat,
  //           y2: event.target.getBounds().getNorthEast().lng,
  //           zoom: newZoom,
  //         }),
  //       );
  //     }
  //   },
  //   [dataPoints, submittedForm],
  // );

  // effects
  useEffect(() => {
    if (isReadyToSubmitFocus) {
      setBounds(dataPoints, refMap.current);
      setIsReadyToSubmitFocus(false);
    }
  }, [dataPoints, isReadyToSubmitFocus]);

  return (
    <div className="resizable-wrapper">
      <Resizable
        enable={{
          top: false,
          right: true,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        defaultSize={leftColumnSize}
        onResizeStop={onResizeStop.bind(
          undefined,
          leftColumnSize,
          refMap.current,
        )}
        maxWidth="50%"
        minWidth="280"
        className={`${styles.leftColumn} left-column`}
      >
        <Scrollbars autoHide>
          <div className={styles.form}>
            <MessagesForm
              onSubmit={onSubmit.bind(
                undefined,
                t,
                setIsReadyToSubmitFocus,
                //setSubmittedForm,
              )}
              initialValues={{
                fromTime: moment().subtract(1, 'day').toDate(),
                toTime: moment().toDate(),
              }}
            />
          </div>
          {!!isEnabledChartConfig && (
            <div className={styles.messagesChartConfig}>
              <MessagesChartConfig
                onSubmit={(formData: any) => setChartConfig(formData)}
                initialValues={chartConfig}
              />
            </div>
          )}
        </Scrollbars>
      </Resizable>
      <div className={`right-column ${styles.rightColumn}`}>
        <Resizable
          ref={refVertResizable}
          className={`messages-top ${styles.messagesTop}`}
          defaultSize={{
            width: '100%',
            height: `${topSideHeight}px`,
          }}
          handleWrapperClass="resizable-scroll"
          onResizeStop={onMessagesResizeStop}
          enable={{
            top: false,
            right: false,
            bottom: true,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          minHeight="100"
        >
          {activeBlock.top === 'chart' && (
            <MessagesChart
              active={activeBlock.top}
              className="messages-chart-top"
              isEnabledChartConfig={isEnabledChartConfig}
              chartConfig={chartConfig}
              handleChangeType={handleChangeType}
              handleToggleConfig={() =>
                setIsEnabledChartConfig(!isEnabledChartConfig)
              }
              position="top"
            />
          )}
          {activeBlock.top === 'table' && (
            <MessagesTable
              className="messages-table-top"
              active={activeBlock.top}
              handleChangeType={handleChangeType}
              page={page}
              dataPointSize={dataPoints.length}
              setPage={setPage}
              selectedDataPointId={selectedDataPointId}
              position="top"
            />
          )}
          {activeBlock.top === 'map' && (
            <MessagesMap
              ref={refMap}
              className="messages-map"
              active={activeBlock.top}
              handleChangeType={handleChangeType}
              height="100%"
              position="top"
              onMarkerClick={onMarkerClick}
              dataPoints={dataPointsWithCoordinates}
              // onMoveEnd={onMoveEnd}
            />
          )}
        </Resizable>
        {/* BOTTOM */}
        <div className={`messages-bottom ${styles.messagesBottom}`}>
          {activeBlock.bottom === 'chart' && (
            <MessagesChart
              className="messages-chart-bottom"
              isEnabledChartConfig={isEnabledChartConfig}
              chartConfig={chartConfig}
              active={activeBlock.bottom}
              handleChangeType={handleChangeType}
              handleToggleConfig={() =>
                setIsEnabledChartConfig(!isEnabledChartConfig)
              }
              position="bottom"
            />
          )}
          {activeBlock.bottom === 'table' && (
            <MessagesTable
              className="messages-table-top"
              active={activeBlock.bottom}
              handleChangeType={handleChangeType}
              page={page}
              dataPointSize={dataPoints.length}
              setPage={setPage}
              selectedDataPointId={selectedDataPointId}
              position="bottom"
            />
          )}
          {activeBlock.bottom === 'map' && (
            <MessagesMap
              ref={refMap}
              className="messages-map"
              active={activeBlock.bottom}
              handleChangeType={handleChangeType}
              height="100%"
              position="bottom"
              onMarkerClick={onMarkerClick}
              dataPoints={dataPointsWithCoordinates}
              // onMoveEnd={onMoveEnd}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Messages;
