import React from 'react';
import { Accordion } from 'react-bootstrap';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';

import type { IGeozoneModel } from '../../interfaces/interfaces';
import GeozonesListItem from '../geozones_list_item/GeozonesListItem';

interface IGeozonesListFormProps {
  geozones: IGeozoneModel[];
  onSubmit: any;
  openedModel: IGeozoneModel | null;
  handleSelect: any;
  handleRemove: any;
  canUpdate: boolean;
  canDestroy: boolean;
}

const GeozonesListForm: React.FC<
  InjectedFormProps<Record<string, boolean>, IGeozonesListFormProps> &
    IGeozonesListFormProps
> = ({
  geozones,
  onSubmit,
  openedModel,
  handleRemove,
  handleSelect,
  canUpdate,
  canDestroy,
}: IGeozonesListFormProps) => (
  <form onSubmit={onSubmit}>
    <Accordion defaultActiveKey="1">
      {geozones.length > 0 &&
        geozones.map((geozone: IGeozoneModel) => (
          <GeozonesListItem
            isSelected={openedModel === geozone}
            key={geozone.id}
            geozone={geozone}
            handleRemove={handleRemove}
            handleSelect={handleSelect}
            canUpdate={canUpdate}
            canDestroy={canDestroy}
          />
        ))}
    </Accordion>
  </form>
);

export default reduxForm<Record<string, boolean>, IGeozonesListFormProps>({
  form: 'geozonesList',
  enableReinitialize: true,
})(GeozonesListForm);
