import type { Action, ThunkAction } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';

import counterReducer from '../components/counter/counterSlice';
import baseStationsReducer from '../slices/baseStations/baseStationsSlice';
import currentUserReducer from '../slices/currentUser/currentUserSlice';
import deviceDataReducer from '../slices/deviceData/deviceDataSlice';
import devicesReducer from '../slices/devices/devicesSlice';
import eventsReducer from '../slices/events/eventsSlice';
import eventTypesReducer from '../slices/eventTypes/eventTypesSlice';
import geozonesReducer from '../slices/geozones/geozonesSlice';
import messagesReducer from '../slices/messages/messagesSlice';
import notificationReducer from '../slices/notification/notificationSlice';
import settingsReducer from '../slices/settings/settingsSlice';
import tracksReducer from '../slices/tracks/tracksSlice';
import usersReducer from '../slices/users/usersSlice';

export const store = configureStore({
  reducer: {
    form: formReducer,
    counter: counterReducer,
    currentUser: currentUserReducer,
    devices: devicesReducer,
    baseStations: baseStationsReducer,
    geozones: geozonesReducer,
    eventTypes: eventTypesReducer,
    events: eventsReducer,
    tracks: tracksReducer,
    deviceData: deviceDataReducer,
    users: usersReducer,
    notification: notificationReducer,
    settings: settingsReducer,
    messages: messagesReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
