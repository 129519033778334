import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';

import { useAppDispatch } from '../../app/hooks';
import type { IDeviceModel } from '../../interfaces/models.interfaces';
import {
  fetch as fetchDevices,
  selectAll as selectAllDevices,
} from '../../slices/devices/devicesSlice';
import Checkbox from '../checkbox/Checkbox';
import ColorInput from '../color_input/ColorInput';
import DatePeriodButtons from '../date_period_buttons/DatePeriodButtons';
import DateTimeInput from '../date_time_input/DateTimeInput';
import Input from '../input/Input';
import Selectbox from '../selectbox/Selectbox';

interface ITracksFormProps {
  onSubmit: any;
  initialValues: any;
  className?: string;
}

const onTodayDateRangeHandle = (dispatch: any) => {
  dispatch(change('track', 'fromTime', moment().startOf('day').toDate()));
  dispatch(change('track', 'toTime', moment().toDate()));
};

const onYesterdayDateRangeHandle = (dispatch: any) => {
  dispatch(
    change(
      'track',
      'fromTime',
      moment().subtract(1, 'day').startOf('day').toDate(),
    ),
  );
  dispatch(
    change(
      'track',
      'toTime',
      moment().subtract(1, 'day').endOf('day').toDate(),
    ),
  );
};

const onLast24DateRangeHandle = (dispatch: any) => {
  dispatch(
    change('track', 'fromTime', moment().subtract(24, 'hours').toDate()),
  );
  dispatch(change('track', 'toTime', moment().toDate()));
};

const onLast3DaysDateRangeHandle = (dispatch: any) => {
  dispatch(change('track', 'fromTime', moment().subtract(3, 'days').toDate()));
  dispatch(change('track', 'toTime', moment().toDate()));
};

const onLastWeekDateRangeHandle = (dispatch: any) => {
  dispatch(change('track', 'fromTime', moment().subtract(1, 'week').toDate()));
  dispatch(change('track', 'toTime', moment().toDate()));
};

// eslint-disable-next-line max-len
let TracksForm: any = (props: any) => {
  const { t } = useTranslation();
  const { className, handleSubmit, onSubmit, initialValues, color, deviceId } =
    props;
  const dispatch = useAppDispatch();
  const devices = useSelector(selectAllDevices);
  const lineThicknessOptions = [
    { value: 0, label: 'Без линии' },
    { value: 1, label: '1px' },
    { value: 2, label: '2px' },
    { value: 3, label: '3px' },
    { value: 4, label: '4px' },
    { value: 5, label: '5px' },
  ];
  const [startDate, setStartDate] = useState(initialValues.fromTime);
  const [endDate, setEndDate] = useState(initialValues.toTime);
  const devicesOptions = devices.map((device: IDeviceModel) => ({
    label: device.name,
    value: device.id,
  }));

  // fetch devices
  useEffect(() => {
    void dispatch(fetchDevices({ type: 'tracker' }));
  }, []);

  // set device in the form
  useEffect(() => {
    if (deviceId && deviceId.value) {
      dispatch(
        change(
          'track',
          'device',
          devices.find((device: IDeviceModel) => device.id === deviceId.value),
        ),
      );
    }
  }, [deviceId]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className}>
      <Form.Group as={Row}>
        <Selectbox options={devicesOptions} name="deviceId" />
      </Form.Group>
      <Form.Group as={Row}>
        <Input
          component="input"
          name="name"
          type="text"
          className="form-control"
          placeholder={t('tracksForm.name')}
          id="name"
        />
      </Form.Group>
      <p className="mt-3">
        <b>{t('tracksForm.selectDateTime')}</b>
      </p>
      <Form.Group as={Row}>
        <Field
          name="fromTime"
          type="text"
          className="form-control"
          placeholderText={t('tracksForm.fromTime')}
          id="fromTime"
          component={DateTimeInput}
          selectsStart
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          setDate={setStartDate}
        />
      </Form.Group>
      <Form.Group as={Row}>
        <Field
          name="toTime"
          type="text"
          className="form-control"
          placeholderText={t('tracksForm.toTime')}
          id="toTime"
          component={DateTimeInput}
          selectsEnd
          selected={endDate}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          setDate={setEndDate}
        />
      </Form.Group>
      <DatePeriodButtons
        onTodayDateRangeHandle={onTodayDateRangeHandle.bind(
          undefined,
          dispatch,
        )}
        onYesterdayDateRangeHandle={onYesterdayDateRangeHandle.bind(
          undefined,
          dispatch,
        )}
        onLast24DateRangeHandle={onLast24DateRangeHandle.bind(
          undefined,
          dispatch,
        )}
        onLast3DaysDateRangeHandle={onLast3DaysDateRangeHandle.bind(
          undefined,
          dispatch,
        )}
        onLastWeekDateRangeHandle={onLastWeekDateRangeHandle.bind(
          undefined,
          dispatch,
        )}
      />
      <p className="mt-3">
        <b>{t('tracksForm.params')}</b>
      </p>
      <Form.Group as={Row}>
        <label htmlFor="lineThickness" className="col-sm-5 col-form-label">
          {t('tracksForm.lineThickness')}:
        </label>
        <div className="col-sm-7">
          <Selectbox
            id="lineThickness"
            options={lineThicknessOptions}
            name="lineThickness"
          />
        </div>
      </Form.Group>
      <Form.Group as={Row} className="mb-4">
        <label htmlFor="thickness" className="col-sm-5 col-form-label">
          {t('tracksForm.lineColor')}:
        </label>
        <div className="col-sm-7">
          <ColorInput name="color" color={color} />
        </div>
      </Form.Group>
      <Checkbox
        className="mb-4"
        name="showedPoints"
        label={t('tracksForm.showPoints')}
      />
      <Form.Group className="text-end">
        <Button type="submit" variant="primary" disabled={!deviceId}>
          {t('shared.form.button.apply')}
        </Button>
      </Form.Group>
    </form>
  );
};

TracksForm = reduxForm<Record<string, boolean>, ITracksFormProps>({
  form: 'track',
  enableReinitialize: true,
})(TracksForm);

const selector = formValueSelector('track');
TracksForm = connect((state: any) => {
  // can select values individually
  const { color, deviceId } = selector(state, 'color', 'deviceId');
  return {
    color,
    deviceId,
  };
})(TracksForm);

export default TracksForm;
