import L from 'leaflet';
import React, { useCallback, useEffect } from 'react';
import {
  LayersControl,
  MapContainer,
  TileLayer,
  ZoomControl,
} from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';

import {
  settingsSelector,
  //updateMapCoordinatesAndZoom,
  updateMapTileName,
} from '../../slices/settings/settingsSlice';
import { saveMapCoordinates, saveMapZoom } from '../../utils/localStorage';
// import {fetchDataPoints} from "../../slices/messages/messagesSlice";

interface IMapProps {
  ref: any;
  children: React.ReactElement;
  zoomControlPosition?: 'topright' | 'topleft' | 'bottomleft' | 'bottomright';
  onPopupClose?: any;
  onMoveEnd?: any;
}

// eslint-disable-next-line react/display-name
export const Map: React.FunctionComponent<IMapProps> = React.forwardRef(
  (props: IMapProps, ref: any) => {
    const { children, onPopupClose, onMoveEnd } = props;
    const dispatch = useDispatch();

    // states
    const [map, setMap] = React.useState();

    // selectors
    const { mapCoordinates, mapZoom, mapTileName } =
      useSelector(settingsSelector);

    let { zoomControlPosition } = props;
    if (!zoomControlPosition) {
      zoomControlPosition = 'topright';
    }

    // callbacks
    const defaultHandleMoveEnd = useCallback(
      (event) => {
        const newCoordinates = [
          event.target.getCenter().lat,
          event.target.getCenter().lng,
        ];
        const newZoom = event.target.getZoom();
        saveMapCoordinates(newCoordinates);
        saveMapZoom(newZoom);
      },
      [mapZoom, mapCoordinates],
    );

    const handleChangeBaseLayer = useCallback((e: any) => {
      dispatch(updateMapTileName(e.name));
    }, []);

    const handleMoveEnd = useCallback(
      (event) => {
        defaultHandleMoveEnd(event);
        if (onMoveEnd) {
          onMoveEnd(event);
        }
      },
      [map, onMoveEnd],
    );

    // effects
    useEffect(() => {
      ref.current = map;
      if (!map) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      map.on('moveend', handleMoveEnd);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      map.on('baselayerchange', handleChangeBaseLayer);
      if (onPopupClose) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        map.on('popupclose', onPopupClose);
      }
      return () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        map.off('moveend', handleMoveEnd);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        map.off('baselayerchange', handleChangeBaseLayer);
        if (onPopupClose) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          map.off('popupclose', onPopupClose);
        }
      };
    }, [map, onMoveEnd]);

    return (
      <>
        <MapContainer
          tap={false}
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          whenCreated={setMap}
          preferCanvas={true}
          renderer={L.canvas()}
          zoomControl={false}
          center={[mapCoordinates[0], mapCoordinates[1]]}
          zoom={mapZoom}
        >
          <ZoomControl position={zoomControlPosition} />
          <LayersControl position="bottomleft">
            <LayersControl.BaseLayer
              checked={mapTileName === 'OpenStreetMap.Mapnik'}
              name="OpenStreetMap.Mapnik"
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer
              checked={mapTileName === 'Google.Gybr'}
              name="Google.Gybr"
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
              />
            </LayersControl.BaseLayer>
          </LayersControl>
          {children}
        </MapContainer>
      </>
    );
  },
);
