import { faCog, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { submit } from 'redux-form';

import { useAppDispatch } from '../../app/hooks';
import type { IGeozoneModel } from '../../interfaces/models.interfaces';
import { setText } from '../../slices/notification/notificationSlice';
import Checkbox from '../checkbox/Checkbox';
import Confirmation from '../confirmation/Confirmation';

interface IGeozonesListItemProps {
  geozone: IGeozoneModel;
  handleSelect: any;
  handleRemove: any;
  isSelected: boolean;
  canUpdate: boolean;
  canDestroy: boolean;
}

const GeozonesListItem = (props: IGeozonesListItemProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const {
    geozone,
    isSelected,
    handleSelect,
    handleRemove,
    canUpdate,
    canDestroy,
  } = props;
  const classNames = cn('list-item', { active: isSelected });

  return (
    <div className={classNames}>
      <div className="list-item-header">
        <Checkbox
          name={`geozoneId${geozone.id}`}
          className="me-2"
          onChange={() =>
            setTimeout(() => {
              dispatch(submit('geozonesList'));
            }, 10)
          }
        />
        <span className="list-item-name me-auto">{geozone.name}</span>
        {canUpdate && (
          <Button
            variant="primary"
            className="btn-icon btn-tool"
            onClick={handleSelect.bind(undefined, geozone)}
          >
            <FontAwesomeIcon icon={faCog} />
          </Button>
        )}
        {canDestroy && (
          <Button
            variant="danger"
            className="btn-icon btn-tool ms-2"
            onClick={() => setIsShowConfirmModal(true)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        )}
        <div className="list-item-corner" />
        <Confirmation
          isShow={isShowConfirmModal}
          onConfirm={async () => {
            try {
              await handleRemove(geozone, dispatch);
              dispatch(setText(t('shared.notification.removed')));
              setIsShowConfirmModal(false);
            } catch {
              // nothing to do
            }
          }}
          onHide={() => {
            setIsShowConfirmModal(false);
          }}
        />
      </div>
    </div>
  );
};

export default GeozonesListItem;
