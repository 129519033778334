import type { AxiosResponse } from 'axios';

import { get, patch, post } from '../../helpers/axios';
import type { IEventModel } from '../../interfaces/models.interfaces';

export async function fetchRequest(props: any): Promise<AxiosResponse<any>> {
  let query = '';
  if (props.page !== undefined) {
    query = `?page=${props.page}&limit=${props.limit || 30}`;
  }
  return get(
    `devices/${props.deviceId}/eventTypes/${props.eventTypeId}/events${query}`,
  );
}

export async function updateRequest(
  event: IEventModel,
): Promise<AxiosResponse<any>> {
  return patch(`events/${event.id}`, {
    event,
  });
}

export async function readByDeviceIdAndEventTypeIdRequest(
  props: any,
): Promise<AxiosResponse<any>> {
  return post(
    `devices/${props.deviceId}/eventTypes/${props.eventTypeId}/events/read`,
    {},
  );
}
