import React, { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  currentUserSelector,
  update,
} from '../../slices/currentUser/currentUserSlice';
import { setText } from '../../slices/notification/notificationSlice';
import { settingsSelector } from '../../slices/settings/settingsSlice';
import SettingsMenu from '../settings_menu/SettingsMenu';
import UserForm from '../user_form/UserForm';
import styles from './SettingsGeneral.module.scss';

const SettingsGeneral = () => {
  const { t } = useTranslation();
  const { currentUser } = useSelector(settingsSelector);
  const { errors } = useSelector(currentUserSelector);
  let initialValues = {};

  if (currentUser) {
    initialValues = {
      name: currentUser.name,
      email: currentUser.email,
      password: currentUser.password,
    };
  }

  const handleSubmit = useCallback(async (formData: any, dispatch: any) => {
    const result = await dispatch(update(formData));
    if (!result.errors) {
      dispatch(setText(t('shared.notification.updated')));
    }
  }, []);

  return (
    <div className="resizable-wrapper">
      <div className={`${styles.leftColumn} left-column`}>
        <SettingsMenu active="general" />
      </div>
      <div className={`${styles.rightColumn} right-column`}>
        <Scrollbars autoHide>
          <h1>{t('settings.general.title')}</h1>
          <Row>
            <Col sm={8}>
              <UserForm
                initialValues={initialValues}
                onSubmit={handleSubmit}
                errors={errors}
                isShowPermission={false}
              />
            </Col>
          </Row>
        </Scrollbars>
      </div>
    </div>
  );
};

export default SettingsGeneral;
