import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import type { RootState } from '../../app/store';
import type {
  IDataPointsResponseData,
  IDeviceDataModel,
  // IDeviceDataResponseData,
} from '../../interfaces/interfaces';
// import { dataWithCoordinates } from '../../utils/track';
import {
  fetchAsFileRequest,
  fetchDataPointsRequest,
  // fetchRequest,
} from '../deviceData/deviceDataApi';

export const fetchDataPoints = createAsyncThunk(
  'messages/fetchPoints',
  async (data: any, thunkAPI) => {
    try {
      const params = {
        deviceId: data.deviceId,
        fromTime: data.fromTime,
        toTime: data.toTime,
      };
      const response = await fetchDataPointsRequest(params);
      const responseData: IDataPointsResponseData = await response.data;
      return {
        form: params,
        dataPoints: responseData.dataPoints,
      };
    } catch {
      return thunkAPI.rejectWithValue('Sorry. something wrong');
    }
  },
);

// export const fetch = createAsyncThunk(
//   'messages/fetch',
//   async (data: any, thunkAPI) => {
//     try {
//       const response = await fetchRequest({
//         deviceId: data.deviceId,
//         fromTime: data.fromTime,
//         toTime: data.toTime,
//         page: data.page,
//       });
//       const responseData: IDeviceDataResponseData = await response.data;
//       return {
//         form: {
//           deviceId: data.deviceId,
//           fromTime: data.fromTime,
//           toTime: data.toTime,
//         },
//         data: responseData.deviceData,
//       };
//     } catch {
//       return thunkAPI.rejectWithValue('Sorry. something wrong');
//     }
//   },
// );

export const fetchAsFile = createAsyncThunk(
  'messages/fetchAsFile',
  async (data: any, thunkAPI) => {
    try {
      const response = await fetchAsFileRequest({
        deviceId: data.deviceId,
        fromTime: data.fromTime,
        toTime: data.toTime,
      });
      const responseData: any = await response.data;
      const blob = new Blob([responseData], { type: 'text/csv;charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      const contentDispHeader = response.headers['content-disposition'];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const filename = /filename="(.+)"/.exec(contentDispHeader)[1];
      a.download = filename;
      document.body.append(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch {
      return thunkAPI.rejectWithValue('Sorry. something wrong');
    }
  },
);

// eslint-disable-next-line @typescript-eslint/unbound-method
const { getInitialState, getSelectors, setAll } =
  createEntityAdapter<IDeviceDataModel>();

export const messagesSlice = createSlice({
  name: 'deviceData',
  initialState: getInitialState({
    dataPoints: [],
    submittedForm: {},
    errors: [],
  }),
  reducers: {
    resetAllStore: (state: any) => {
      setAll(state, []);
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(fetch.fulfilled, (state: any, { payload }: any) => {
      //   // add many device data
      //   setAll(state, payload.data);
      //   // set messages form
      //   state.submittedForm = payload.form;
      // })
      .addCase(fetchDataPoints.fulfilled, (state: any, { payload }: any) => {
        state.submittedForm = payload.form;
        state.dataPoints = payload.dataPoints;
      });
  },
});

export const { resetAllStore } = messagesSlice.actions;
export const submittedFormSelector = (state: RootState) =>
  state.messages.submittedForm;
export const selectAllDataPoints = (state: RootState) =>
  state.messages.dataPoints;

export const { selectAll } = getSelectors((state: RootState) => state.messages);

export const selectRange = (firstIndex: number, secondIndex: number) =>
  createSelector(
    (state: RootState) => state,
    (state: any) => selectAll(state).slice(firstIndex, secondIndex),
  );

export default messagesSlice.reducer;
