import L from 'leaflet';

import checkedBaseIcon from './checkedBaseStation.svg';
import checkedCowIcon from './checkedCow.svg';
import defaultBaseIcon from './defaultBaseStation.svg';
import defaultCowIcon from './defaultCow.svg';
import lightBaseIcon from './lightBaseStation.svg';
import lightCowIcon from './lightCow.svg';
import selectedCowIcon from './selectedCow.svg';

export const DefaultCowMarker = new L.Icon({
  iconUrl: defaultCowIcon,
  iconAnchor: undefined,
  popupAnchor: [0, 0],
  shadowUrl: undefined,
  shadowSize: undefined,
  shadowAnchor: undefined,
  iconSize: new L.Point(42, 42),
});

export const LightCowMarker = new L.Icon({
  iconUrl: lightCowIcon,
  iconAnchor: undefined,
  popupAnchor: [0, 0],
  shadowUrl: undefined,
  shadowSize: undefined,
  shadowAnchor: undefined,
  iconSize: new L.Point(42, 42),
});

export const CheckedCowMarker = new L.Icon({
  iconUrl: checkedCowIcon,
  iconAnchor: undefined,
  popupAnchor: [0, 0],
  shadowUrl: undefined,
  shadowSize: undefined,
  shadowAnchor: undefined,
  iconSize: new L.Point(42, 42),
});

export const SelectedCowMarker = new L.Icon({
  iconUrl: selectedCowIcon,
  iconAnchor: undefined,
  popupAnchor: [0, 0],
  shadowUrl: undefined,
  shadowSize: undefined,
  shadowAnchor: undefined,
  iconSize: new L.Point(42, 42),
});

export const DefaultBaseStationMarker = new L.Icon({
  iconUrl: defaultBaseIcon,
  iconAnchor: undefined,
  popupAnchor: [0, 0],
  shadowUrl: undefined,
  shadowSize: undefined,
  shadowAnchor: undefined,
  iconSize: new L.Point(84, 42),
});

export const LightBaseStationMarker = new L.Icon({
  iconUrl: lightBaseIcon,
  iconAnchor: undefined,
  popupAnchor: [0, 0],
  shadowUrl: undefined,
  shadowSize: undefined,
  shadowAnchor: undefined,
  iconSize: new L.Point(84, 42),
});

export const CheckedBaseStationMarker = new L.Icon({
  iconUrl: checkedBaseIcon,
  iconAnchor: undefined,
  popupAnchor: [0, 0],
  shadowUrl: undefined,
  shadowSize: undefined,
  shadowAnchor: undefined,
  iconSize: new L.Point(84, 42),
});
