import React, { useCallback } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reduxForm } from 'redux-form';

import { useAppDispatch } from '../../../app/hooks';
import logo from '../../../assets/images/logo.png';
import type { ILoginForm } from '../../../interfaces/interfaces';
import {
  currentUserSelector,
  fetch,
  login,
} from '../../../slices/currentUser/currentUserSlice';
import { errorFor } from '../../../utils/formError';
import Input from '../../input/Input';
import styles from './Login.module.scss';

const Login = (props: any) => {
  const { handleSubmit } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { errors } = useSelector(currentUserSelector);

  const onSubmit = useCallback(async (formData: any) => {
    const loginResult: any = await dispatch(login(formData));
    if (!loginResult.errors) {
      const currentUserResult: any = await dispatch(fetch());

      if (!currentUserResult.errors) {
        history.push('/');
      }
    }
  }, []);

  return (
    <div className="d-flex justify-content-center">
      <div className={styles.login}>
        <img className={styles.logo} src={logo} />
        <h1 className={styles.title}>{t('auth.login.title')}</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            className="form-control-lg"
            component="input"
            type="email"
            name="username"
            error={errorFor(errors, 'username')}
            placeholder={t('auth.login.username')}
          />
          <Input
            className="form-control-lg"
            component="input"
            type="password"
            name="password"
            error={errorFor(errors, 'password')}
            placeholder={t('auth.login.password')}
          />
          <Row className="mt-5">
            <Col className={styles.links}>
              <div>
                <a href="/reset_password" className="text-left">
                  {t('auth.login.forgotPassword')}
                </a>
              </div>
              <div>
                <a href="/registration">{t('auth.login.registration')}</a>
              </div>
            </Col>
            <Col className="text-end">
              <Button type="submit" size="lg" variant="success">
                {t('auth.login.submit')}
              </Button>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
};

export default reduxForm<ILoginForm>({
  form: 'login',
  enableReinitialize: true,
})(Login);
