import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { isCan } from '../../helpers/permission';
import type { IDeviceModel } from '../../interfaces/interfaces';
import { settingsSelector } from '../../slices/settings/settingsSlice';
import { newDeviceTemplate, sortedEntities } from '../../utils/device';
import DevicesSort from '../devices_sort/DevicesSort';
import styles from './MonitoringActiveDevice.module.scss';
import MonitoringActiveDevicesForm from './MonitoringActiveDevicesForm';

interface IMonitoringActiveDevices {
  devices: IDeviceModel[];
  openModelFormHandle: any;
  openedModelId: number | null;
  checkedActiveDevicesIds: number[];
  setCheckedActiveDevicesIds: any;
}

const activeDevicesIds: any = (ids: number[]) => {
  const result: Record<string, any> = {};
  for (const id of ids) {
    result[`deviceId${id}`] = true;
  }
  return result;
};

const onSubmit = (setCheckedActiveDevicesIds: any, formData: any): void => {
  const result = [];
  const keys = Object.keys(formData);
  for (const key of keys) {
    if (formData[key]) {
      result.push(Number.parseInt(key.replace('deviceId', ''), 10));
    }
  }

  setCheckedActiveDevicesIds(result);
};

const MonitoringActiveDevices = (props: IMonitoringActiveDevices) => {
  const { t } = useTranslation();
  const {
    devices,
    openModelFormHandle,
    openedModelId,
    checkedActiveDevicesIds,
    setCheckedActiveDevicesIds,
  } = props;

  const [sortMethod, setSortMethod] = useState<'abc' | 'status'>('abc');
  const [isCanUpdate, setIsCanUpdate] = useState<boolean>(false);
  const [isCanCreate, setIsCanCreate] = useState<boolean>(false);
  const [sortDirection, setSortDirection] = useState<'up' | 'down'>('up');
  const sortedDevices = sortedEntities(devices, sortMethod, sortDirection);
  const { currentUser } = useSelector(settingsSelector);

  useEffect(() => {
    setIsCanUpdate(isCan('update', 'Device', currentUser));
    setIsCanCreate(isCan('create', 'Device', currentUser));
  }, [currentUser]);

  return (
    <>
      {sortedDevices.length > 0 ? (
        <>
          <DevicesSort
            sortMethodsList={['abc', 'status']}
            sortMethod={sortMethod}
            sortDirection={sortDirection}
            setSortMethod={setSortMethod}
            setSortDirection={setSortDirection}
          />
          <div className={styles.devicesList}>
            <Scrollbars autoHide>
              <MonitoringActiveDevicesForm
                className={styles.monitoringDevices}
                initialValues={activeDevicesIds(checkedActiveDevicesIds)}
                onSubmit={onSubmit.bind(undefined, setCheckedActiveDevicesIds)}
                openedModelId={openedModelId}
                openModelFormHandle={openModelFormHandle}
                devices={sortedDevices}
                canUpdate={isCanUpdate}
              />
            </Scrollbars>
          </div>
        </>
      ) : (
        <p className="text-center">{t('monitoringActiveDevices.noDevices')}</p>
      )}
      {isCanCreate && (
        <div className={`${styles.addDevice} container-fluid`}>
          <Button
            size="sm"
            variant="outline-success w-100"
            onClick={openModelFormHandle.bind(
              undefined,
              'device',
              newDeviceTemplate,
            )}
          >
            <FontAwesomeIcon className="me-2" icon={faPlus} />
            {t('monitoringActiveDevices.new')}
          </Button>
        </div>
      )}
    </>
  );
};

export default MonitoringActiveDevices;
