import { faChevronDown, faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import React from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { submit } from 'redux-form';

import { useAppDispatch } from '../../app/hooks';
import type { IBaseStationModel } from '../../interfaces/interfaces';
import BaseStationOnline from '../base_station_online/BaseStationOnline';
import Checkbox from '../checkbox/Checkbox';
import PropertyItem from '../property_item/PropertyItem';

interface IBaseStationListItem {
  baseStation: IBaseStationModel;
  isChecked: boolean;
  openedModelId: number | null;
  openModelFormHandle: any;
  canUpdate: boolean;
}

const MonitoringActiveListItem = (props: IBaseStationListItem) => {
  const { t } = useTranslation();
  const {
    baseStation,
    isChecked,
    openModelFormHandle,
    openedModelId,
    canUpdate,
  } = props;
  const dispatch = useAppDispatch();
  const className = cn('list-item', {
    active: isChecked,
    opened: openedModelId === baseStation.id,
  });

  return (
    <div className={className}>
      <div className="list-item-header">
        <Checkbox
          name={`baseStationId${baseStation.id}`}
          className="me-2"
          label={baseStation.name}
          onChange={() =>
            setTimeout(() => {
              dispatch(submit('monitoringBaseStations'));
            }, 10)
          }
        />
        <BaseStationOnline className="me-2" />
        <span className="me-auto"></span>
        {canUpdate && (
          <Button
            variant="primary"
            className="btn-icon btn-tool ms-auto"
            onClick={openModelFormHandle.bind(
              undefined,
              'baseStation',
              baseStation,
            )}
          >
            <FontAwesomeIcon icon={faCog} />
          </Button>
        )}
        <Accordion.Toggle
          className="btn-icon btn-tool ms-2"
          as={Button}
          variant="primary"
          eventKey={baseStation.id.toString()}
        >
          <FontAwesomeIcon icon={faChevronDown} />
        </Accordion.Toggle>
        <div className="list-item-corner" />
      </div>
      <Accordion.Collapse
        className="list-item-body"
        eventKey={baseStation.id.toString()}
      >
        <>
          <PropertyItem
            title={`${t('models.baseStation.number')}:`}
            value={baseStation.number}
          />
          <PropertyItem
            title={`${t('models.baseStation.name')}:`}
            value={baseStation.name}
          />
          <PropertyItem
            title={`${t('models.baseStation.ip')}:`}
            value={baseStation.ip}
          />
          <PropertyItem
            title={`${t('models.baseStation.coordinates')}:`}
            value={`${baseStation.lat}/${baseStation.lon}`}
          />
        </>
      </Accordion.Collapse>
    </div>
  );
};

export default MonitoringActiveListItem;
