import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';

import type { ILoginForm } from '../../../interfaces/forms.interfaces';
import { currentUserSelector } from '../../../slices/currentUser/currentUserSlice';
import { errorFor } from '../../../utils/formError';
import Input from '../../input/Input';
import styles from './ForgetPassword.module.scss';

let SendForgetPasswordForm: any = (props: any) => {
  const { email, handleSubmit, onSubmit } = props;
  const { t } = useTranslation();
  const { errors } = useSelector(currentUserSelector);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        className="form-control-lg"
        component="input"
        type="email"
        name="email"
        placeholder={t('auth.sendForgetPasswordForm.email')}
        error={errorFor(errors, 'email')}
      />
      <Row className="mt-5">
        <Col className={styles.links}>
          <div>
            <a href="/login" className="text-left">
              {t('auth.sendForgetPasswordForm.signIn')}
            </a>
          </div>
          <div>
            <a href="/registration">
              {t('auth.sendForgetPasswordForm.signUp')}
            </a>
          </div>
        </Col>
        <Col className="text-end">
          <Button type="submit" size="lg" variant="success" disabled={!email}>
            {t('auth.sendForgetPasswordForm.sendEmail')}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

SendForgetPasswordForm = reduxForm<ILoginForm>({
  form: 'sendForgetPassword',
  enableReinitialize: true,
})(SendForgetPasswordForm);

const selector = formValueSelector('sendForgetPassword');
SendForgetPasswordForm = connect((state: any) => {
  const email = selector(state, 'email');
  return { email };
})(SendForgetPasswordForm);

export default SendForgetPasswordForm;
