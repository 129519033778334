import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../app/store';
import type { ISettingsState } from '../../interfaces/interfaces';
import {
  clearAuthTokens,
  getAuthRefreshToken,
  getAuthToken,
  getCurrentUser,
  getLeftColumnSize,
  getMapCoordinates,
  getMapTileName,
  getMapZoom,
  saveAuthTokens,
  saveCurrentUser,
  saveLeftColumnSize,
  saveMapCoordinates,
  saveMapTileName,
  saveMapZoom,
} from '../../utils/localStorage';

const initialState: ISettingsState = {
  leftColumnSize: getLeftColumnSize(),
  mapZoom: getMapZoom(),
  mapCoordinates: getMapCoordinates(),
  mapTileName: getMapTileName(),
  currentUser: getCurrentUser(),
  token: getAuthToken(),
  refreshToken: getAuthRefreshToken(),
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateCurrentUser: (state: any, { payload }) => {
      state.currentUser = payload;
      saveCurrentUser(payload);
    },
    updateLeftColumnSize: (state: any, { payload }) => {
      state.leftColumnSize = payload;
      saveLeftColumnSize(payload);
    },
    updateMapCoordinatesAndZoom: (state: any, { payload }) => {
      state.mapZoom = payload.zoom;
      state.mapCoordinates = payload.coordinates;
      saveMapCoordinates(payload.coordinates);
      saveMapZoom(payload.zoom);
    },
    updateMapTileName: (state: any, { payload }) => {
      state.mapTileName = payload;
      saveMapTileName(payload);
    },
    updateAuthTokens: (state: any, { payload }) => {
      state.token = payload.token;
      state.refreshToken = payload.refreshToken;
      saveAuthTokens(payload);
    },
    destroyAuthTokens: (state: any) => {
      state.token = null;
      state.refreshToken = null;
      clearAuthTokens();
    },
  },
});

export const {
  destroyAuthTokens,
  updateAuthTokens,
  updateCurrentUser,
  updateLeftColumnSize,
  updateMapCoordinatesAndZoom,
  updateMapTileName,
} = settingsSlice.actions;
export default settingsSlice.reducer;
export const settingsSelector = (state: RootState) => state.settings;
