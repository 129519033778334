import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../app/hooks';
import type { IEventModel } from '../../interfaces/models.interfaces';
import { updateStore as updateDeviceStore } from '../../slices/devices/devicesSlice';
import {
  fetch,
  fetchAndMerge,
  readByDeviceIdAndEventTypeId,
  update,
} from '../../slices/events/eventsSlice';
import { readAll as readAllEvents } from '../../slices/eventTypes/eventTypesSlice';
import { withSecondsTimeFormat } from '../../utils/date';

const handleReadEvent = (eventId: number, dispatch: any) => {
  dispatch(update({ id: eventId, read: true }));
};

const EventsList = (props: any) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const limit = 30;
  const [page, setPage] = useState<number>(1);
  const {
    events,
    handleCloseEventsModal,
    selectedEventType,
    selectedDeviceId,
    canUpdate,
  } = props;

  useEffect(() => {
    if (selectedEventType) {
      setPage(page + 1);
      void dispatch(
        fetch({
          deviceId: selectedDeviceId,
          eventTypeId: selectedEventType.id,
          page,
          limit,
        }),
      );
    }
  }, [selectedEventType]);

  const handleLoadMore = useCallback(() => {
    setPage(page + 1);
    void dispatch(
      fetchAndMerge({
        deviceId: selectedDeviceId,
        eventTypeId: selectedEventType.id,
        page,
        limit,
      }),
    );
  }, [page]);

  return (
    <Modal size="lg" show={!!selectedEventType} onHide={handleCloseEventsModal}>
      <Modal.Header>
        <Modal.Title as="h2" className="float-start">
          {selectedEventType ? selectedEventType.name : null}
        </Modal.Title>
        {selectedEventType &&
          !!selectedEventType.unreadEventsCount &&
          canUpdate && (
            <Button
              className="float-end"
              variant="primary"
              onClick={() =>
                dispatch(
                  readByDeviceIdAndEventTypeId({
                    deviceId: selectedDeviceId,
                    eventTypeId: selectedEventType.id,
                  }),
                ).then((data: any) => {
                  if (!data.errors) {
                    dispatch(readAllEvents(selectedEventType));
                    dispatch(
                      updateDeviceStore({
                        id: selectedDeviceId,
                        events: [],
                      }),
                    );
                  }
                })
              }
            >
              {t('eventsList.readAll')}
            </Button>
          )}
      </Modal.Header>
      <Modal.Body>
        <Table size="sm">
          <thead>
            <th>{t('shared.models.createdAt')}</th>
            <th>{t('eventsList.notification')}</th>
            <th></th>
          </thead>
          <tbody>
            {events.length > 0 &&
              events.map((event: IEventModel) => (
                <tr key={event.id}>
                  <td className="align-middle">
                    {withSecondsTimeFormat(event.createdAt)}
                    {!event.read && (
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="text-warning ms-2"
                      />
                    )}
                  </td>
                  <td>
                    {!!event.notifications &&
                      get(event.notifications, 'system.status') && (
                        <p className="mb-0">sentSystemMessage</p>
                      )}
                    {!!event.notifications &&
                      get(event.notifications, 'email.status') && (
                        <p className="mb-0">sentEmailMessage</p>
                      )}
                  </td>
                  <td className="text-end">
                    {!event.read && canUpdate && (
                      <Button
                        variant="primary"
                        onClick={handleReadEvent.bind(
                          undefined,
                          event.id,
                          dispatch,
                        )}
                      >
                        {t('eventsList.read')}
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            {events.length === 0 && (
              <tr>
                <td colSpan={3} className="text-center">
                  {t('shared.empty')}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {events.length > 0 && (
          <div className="text-center">
            <Button variant="primary" onClick={handleLoadMore}>
              {t('shared.more')}
            </Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default EventsList;
