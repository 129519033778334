// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../app/hooks';
import type {
  IDeviceModel,
  IGeozoneModel,
} from '../../interfaces/models.interfaces';
import { addOneItem as addOneDeviceDataItem } from '../../slices/deviceData/deviceDataSlice';
import {
  selectAll as selectAllDevices,
  updateStore as updateDeviceStore,
} from '../../slices/devices/devicesSlice';
import { selectAll as selectAllGeozones } from '../../slices/geozones/geozonesSlice';
import { setText } from '../../slices/notification/notificationSlice';
import { settingsSelector } from '../../slices/settings/settingsSlice';

interface IWebsocketsProps {
  children: any;
  onDeviceDataMessageReceive?: any;
}

const Websockets = ({
  children,
  onDeviceDataMessageReceive,
}: IWebsocketsProps) => {
  const { currentUser } = useSelector(settingsSelector);
  const dispatch = useAppDispatch();
  const ws: any = useRef(null);
  const { t } = useTranslation();

  const devices: IDeviceModel[] = useSelector(selectAllDevices);
  const geozones: IGeozoneModel[] = useSelector(selectAllGeozones);
  const onMessage = useCallback(
    (message) => {
      const messageData = JSON.parse(message.data);
      if (messageData) {
        if (messageData.type === 'new_device_data') {
          const device = devices.find(
            (item: IDeviceModel) => item.id === messageData.data.deviceId,
          );
          addOneDeviceDataItem(messageData.data);
          void dispatch(
            updateDeviceStore({
              ...device,
              deviceData: [messageData.data],
            }),
          );

          if (onDeviceDataMessageReceive) {
            onDeviceDataMessageReceive([messageData.data]);
          }
        } else if (messageData.type === 'geozone_escape_event') {
          const device: IDeviceModel | undefined = devices.find(
            (item: IDeviceModel) => item.id === messageData.event.deviceId,
          );
          if (device) {
            const geozone: IGeozoneModel | undefined = device.geozones.find(
              (item: IGeozoneModel) =>
                item.id === messageData.event.data.geozoneId,
            );

            if (geozone) {
              dispatch(
                setText(
                  t('geozones.alert', {
                    deviceName: device.name,
                    geozoneName: geozone.name,
                  }),
                ),
              );
            }
          }
        }
      }
    },
    [devices, geozones, onDeviceDataMessageReceive],
  );

  const setupConnection = useCallback(() => {
    if (!currentUser) {
      return;
    }

    ws.current = new WebSocket(
      process.env.REACT_WEBSOCKETS_URL || 'wss://api.orbita.one/ws',
    );
    ws.current.addEventListener('open', () => {
      ws.current.send(
        JSON.stringify({ event: 'subscribe', userId: currentUser.id }),
      );
    });
    ws.current.addEventListener('close', () => {
      setupConnection();
    });

    // eslint-disable-next-line unicorn/prefer-add-event-listener
    ws.current.onmessage = (message: any) => {
      onMessage(message);
    };
  }, []);

  useEffect(() => {
    if (currentUser && ws.current) {
      // eslint-disable-next-line unicorn/prefer-add-event-listener
      ws.current.onmessage = (message: any) => {
        onMessage(message);
      };
    }
  }, [devices, geozones, onDeviceDataMessageReceive]);

  useEffect(() => {
    setupConnection();
  }, []);

  return <>{children}</>;
};

export default Websockets;
