import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../app/hooks';
import { defaultRules, isCan } from '../../helpers/permission';
import type { IUserModel } from '../../interfaces/models.interfaces';
import { setText } from '../../slices/notification/notificationSlice';
import { settingsSelector } from '../../slices/settings/settingsSlice';
import {
  create,
  destroy,
  fetch,
  selectAll,
  update,
  usersSelector,
} from '../../slices/users/usersSlice';
import SettingsMenu from '../settings_menu/SettingsMenu';
import SettingsUsersListItem from '../settings_users_list_item/SettingsUsersListItem';
import UserForm from '../user_form/UserForm';
import styles from './SettingsUsers.module.scss';

const SettingsUsers = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<Record<string, any>>({
    permission: { rules: defaultRules },
  });
  const [isCanCreate, setIsCanCreate] = useState<boolean>(false);
  const [isCanUpdate, setIsCanUpdate] = useState<boolean>(false);
  const [isCanDestroy, setIsCanDestroy] = useState<boolean>(false);

  const users: IUserModel[] = useSelector(selectAll);
  const { errors } = useSelector(usersSelector);
  const { currentUser } = useSelector(settingsSelector);

  const handleCloseModal = useCallback(() => {
    setInitialValues({
      permission: { rules: defaultRules },
    });
    setIsShowModal(false);
  }, []);

  const handleUpdate = useCallback((formData: any) => {
    void dispatch(update(formData)).then((data: any) => {
      if (!data.error) {
        handleCloseModal();
        dispatch(setText(t('shared.notification.updated')));
      }
    });
  }, []);

  const handleCreate = useCallback((formData: any) => {
    void dispatch(create(formData)).then((data: any) => {
      if (!data.error) {
        handleCloseModal();
        dispatch(setText(t('shared.notification.created')));
      }
    });
  }, []);

  const onDestroy = useCallback((user: IUserModel) => {
    void dispatch(destroy(user.id)).then((data: any) => {
      if (!data.error) {
        dispatch(setText(t('shared.notification.removed')));
      }
    });
  }, []);

  const onEdit = useCallback((user: IUserModel) => {
    setInitialValues({
      id: user.id,
      name: user.name,
      email: user.email,
      password: user.password,
      permission: user.permission,
    });
    setIsShowModal(true);
  }, []);

  useEffect(() => {
    setIsCanCreate(isCan('create', 'User', currentUser));
    setIsCanUpdate(isCan('update', 'User', currentUser));
    setIsCanDestroy(isCan('destroy', 'User', currentUser));
  }, [currentUser]);

  useEffect(() => {
    void dispatch(fetch());
  }, []);

  return (
    <div className="resizable-wrapper">
      <div className={`${styles.leftColumn} left-column`}>
        <SettingsMenu active="users" />
      </div>
      <div className={`${styles.rightColumn} right-column`}>
        <Scrollbars autoHide>
          <Row className="mb-3">
            <Col sm>
              <h1>{t('settings.members.title')}</h1>
            </Col>
            <Col sm className="text-end">
              {isCanCreate && (
                <Button
                  variant="outline-success"
                  onClick={() => setIsShowModal(true)}
                >
                  <FontAwesomeIcon className="me-2" icon={faPlus} />
                  {t('settings.members.new')}
                </Button>
              )}
            </Col>
          </Row>
          <Table>
            <thead>
              <th>{t('models.user.id')}</th>
              <th>{t('models.user.name')}</th>
              <th>{t('models.user.email')}</th>
              <th>{t('models.user.role')}</th>
              <th>{t('shared.models.createdAt')}</th>
              <th></th>
            </thead>
            <tbody>
              {users.length > 0 &&
                users.map((user: IUserModel) => (
                  <SettingsUsersListItem
                    key={user.id}
                    user={user}
                    onEdit={onEdit}
                    onDestroy={onDestroy}
                    canUpdate={isCanUpdate}
                    canDestroy={isCanDestroy}
                  />
                ))}
              {users.length === 0 && (
                <tr>
                  <td colSpan={5} className="text-center">
                    {t('shared.empty')}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <Modal size="lg" show={isShowModal} onHide={handleCloseModal}>
            <Modal.Header>
              <Modal.Title as="h2">{t('settings.members.new')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <UserForm
                initialValues={initialValues}
                onSubmit={initialValues.id ? handleUpdate : handleCreate}
                onCancel={handleCloseModal}
                errors={errors}
                isShowPermission={true}
              />
            </Modal.Body>
          </Modal>
        </Scrollbars>
      </div>
    </div>
  );
};

export default SettingsUsers;
