import axios from 'axios';

import {
  getAuthRefreshToken,
  getAuthToken,
  saveAuthTokens,
} from '../utils/localStorage';

// for multiple requests
let isRefreshing = false;
let failedQueue: any = [];

const processQueue = (error: any, token = null) => {
  for (const prom of failedQueue) {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  }

  failedQueue = [];
};

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    // TODO: find a better way for skip refresh token for needed requests
    if (
      originalRequest.url.includes('api/v1/login') ||
      originalRequest.url.includes('api/v1/registration')
    ) {
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
          })
          .catch((subError) => Promise.reject(subError));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const authToken = getAuthToken();
      const refreshAuthToken = getAuthRefreshToken();
      return new Promise(function (resolve, reject) {
        axios
          .post(`${process.env.REACT_APP_API_URL}/refreshToken`, {
            token: authToken,
            refreshToken: refreshAuthToken,
          })
          .then(({ data }) => {
            saveAuthTokens(data);
            axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
            originalRequest.headers.Authorization = `Bearer ${data.token}`;
            processQueue(null, data.token);
            resolve(axios(originalRequest));
          })
          .catch((subError) => {
            processQueue(subError, null);
            reject(subError);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  },
);
