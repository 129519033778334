import { Resizable } from 're-resizable';
// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../app/hooks';
import { isCan } from '../../helpers/permission';
import type { IEventTypeModel } from '../../interfaces/interfaces';
import {
  fetch as fetchDevices,
  selectAll as selectAllDevices,
  updateStore as updateDeviceStore,
} from '../../slices/devices/devicesSlice';
import {
  readByDeviceIdAndEventTypeId,
  selectAll as selectAllEvents,
} from '../../slices/events/eventsSlice';
import {
  fetch as fetchEventTypes,
  readAll as readAllEvents,
  selectAll as selectAllEventTypes,
} from '../../slices/eventTypes/eventTypesSlice';
import { setText } from '../../slices/notification/notificationSlice';
import { settingsSelector } from '../../slices/settings/settingsSlice';
import { withSecondsTimeFormat } from '../../utils/date';
import { onResizeStop } from '../../utils/resizable';
import EventsDevices from '../events_devices/EventsDevices';
import EventsList from '../events_list/EventsList';
import Websockets from '../websockets/Websockets';
import styles from './Events.module.scss';

const Events = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [selectedDeviceId, setSelectedDeviceId] = useState<number | null>(null);
  const [isCanUpdate, setIsCanUpdate] = useState<boolean>(false);
  const [selectedEventType, setSelectedEventType] =
    useState<IEventTypeModel | null>(null);
  const { leftColumnSize }: any = useSelector(settingsSelector);
  const devices = useSelector(selectAllDevices);
  const deviceEventTypes = useSelector(selectAllEventTypes);
  const deviceEvents = useSelector(selectAllEvents);
  const { currentUser } = useSelector(settingsSelector);

  useEffect(() => {
    setIsCanUpdate(isCan('update', 'Event', currentUser));
  }, [currentUser]);

  useEffect(() => {
    if (selectedDeviceId) {
      void (async () => {
        const data: any = await dispatch(
          fetchEventTypes({ deviceId: selectedDeviceId }),
        );
        if (!data.payload || data.payload.length === 0) {
          dispatch(setText(t('shared.notification.noData')));
        }
      })();
    }
  }, [selectedDeviceId]);

  useEffect(() => {
    void dispatch(fetchDevices({ type: 'tracker' }));
  }, []);

  const handleCloseEventsModal = useCallback(() => {
    setSelectedEventType(null);
  }, []);

  return (
    <Websockets>
      <div className="resizable-wrapper">
        <Resizable
          enable={{
            top: false,
            right: true,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          defaultSize={leftColumnSize}
          onResizeStop={onResizeStop.bind(undefined, leftColumnSize, null)}
          maxWidth="50%"
          minWidth="280"
          className={`${styles.leftColumn} left-column`}
        >
          <Scrollbars autoHide>
            <EventsDevices
              devices={devices}
              setSelectedDeviceId={setSelectedDeviceId}
              initialValues={{
                id: devices.length > 0 ? devices[0].id : undefined,
              }}
            />
          </Scrollbars>
        </Resizable>
        <div className={`${styles.rightColumn} right-column`}>
          <Scrollbars autoHide>
            <Table size="sm">
              <thead>
                <tr>
                  <th>{t('events.name')}</th>
                  <th className="text-end">
                    {t('events.unreadMessagesCount')}
                  </th>
                  <th>{t('events.lastMessageDateAndTime')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {deviceEventTypes.length > 0 &&
                  deviceEventTypes.map((eventType: IEventTypeModel) => (
                    <tr key={eventType.id}>
                      <td className="align-middle">{eventType.name}</td>
                      <td className="align-middle text-end">
                        {eventType.unreadEventsCount}
                      </td>
                      <td className="align-middle">
                        {withSecondsTimeFormat(eventType.lastEventCreatedAt)}
                      </td>
                      <td className="align-middle text-end">
                        {!!eventType.unreadEventsCount && isCanUpdate && (
                          <Button
                            variant="success"
                            className="btn-icon btn-tool"
                            onClick={() =>
                              dispatch(
                                readByDeviceIdAndEventTypeId({
                                  deviceId: selectedDeviceId,
                                  eventTypeId: eventType.id,
                                }),
                              ).then((data: any) => {
                                if (!data.errors) {
                                  dispatch(readAllEvents(eventType));
                                  dispatch(
                                    updateDeviceStore({
                                      id: selectedDeviceId,
                                      events: [],
                                    }),
                                  );
                                }
                              })
                            }
                          >
                            {t('events.readAll')}
                          </Button>
                        )}
                        <Button
                          variant="primary"
                          className="btn-icon btn-tool ms-2"
                          onClick={() => setSelectedEventType(eventType)}
                        >
                          {t('shared.show')}
                        </Button>
                      </td>
                    </tr>
                  ))}
                {deviceEventTypes.length === 0 && (
                  <tr>
                    <td colSpan={4} className="text-center">
                      {t('shared.empty')}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {selectedEventType && (
              <EventsList
                events={deviceEvents}
                selectedEventType={selectedEventType}
                selectedDeviceId={selectedDeviceId}
                handleCloseEventsModal={handleCloseEventsModal}
                canUpdate={isCanUpdate}
              />
            )}
          </Scrollbars>
        </div>
      </div>
    </Websockets>
  );
};

export default Events;
